import { useState } from 'react';
import { FaArrowsAlt, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FaClone } from 'react-icons/fa6';

export const WorkExperienceInformation = ({
  allWorkExperienceData,
  setAllWorkExperienceData,
}) => {
  const [workExperienceInformationData, setWorkExperienceInformationData] =
    useState({
      position: '',
      companyName: '',
      startDate: '',
      endDate: '',
      location: '',
      description: '',
    });

  const [showForm, setShowForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkExperienceInformationData({
      ...workExperienceInformationData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAllWorkExperienceData((prevData) => [
      ...prevData,
      workExperienceInformationData,
    ]);
    setShowForm(false);
    setWorkExperienceInformationData({
      position: '',
      companyName: '',
      startDate: '',
      endDate: '',
      location: '',
      description: '',
    });
  };

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="text-lg font-bold">Work Experience</div>
        <button
          type="button"
          onClick={() => setShowForm(!showForm)}
          className="rounded bg-green-500 px-2 text-white hover:bg-green-600"
        >
          {showForm ? <span className="px-[2px]">-</span> : '+'}
        </button>
      </div>
      {showForm ? (
        <>
          <div>
            <label htmlFor="position">Position</label>
            <input
              type="text"
              name="position"
              placeholder="Position you worked on"
              value={workExperienceInformationData?.position}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={workExperienceInformationData?.companyName}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={workExperienceInformationData?.startDate}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              name="endDate"
              value={workExperienceInformationData?.endDate}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="location">City, Country</label>
            <input
              type="text"
              name="location"
              placeholder="City, Country"
              value={workExperienceInformationData?.location}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              placeholder="Describe your role and achievements"
              value={workExperienceInformationData?.description}
              onChange={handleChange}
              className="mb-4 w-full rounded border p-2"
              required
            />
          </div>
          <button
            className="w-full rounded bg-blue-500 p-2 text-white hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Add Experience
          </button>
        </>
      ) : (
        <div>
          {allWorkExperienceData.map((experienceData, index) => (
            <div
              key={index}
              className="mb-4 flex items-center justify-between rounded border p-4 shadow-md hover:shadow-lg"
            >
              <div className="flex-1">
                <h3 className="font-semibold text-blue-500">
                  {experienceData?.position}, {experienceData?.companyName}
                </h3>
                <p className="text-sm text-gray-600">
                  {`${new Date(experienceData?.startDate).toLocaleString(
                    'default',
                    {
                      month: 'short',
                      year: 'numeric',
                    }
                  )} - ${new Date(experienceData?.endDate).toLocaleString(
                    'default',
                    {
                      month: 'short',
                      year: 'numeric',
                    }
                  )} | ${experienceData?.location}`}
                </p>
              </div>
              <div className="ml-4 flex items-center space-x-4">
                <button
                  className="text-yellow-500 hover:text-yellow-600"
                  title="Edit"
                >
                  <FaEdit />
                </button>
                <button
                  className="text-gray-500 hover:text-gray-600"
                  title="Duplicate"
                >
                  <FaClone />
                </button>
                <button
                  className="text-red-500 hover:text-red-600"
                  title="Delete"
                >
                  <FaTrashAlt />
                </button>
                <button
                  className="cursor-move text-blue-500 hover:text-blue-600"
                  title="Drag"
                >
                  <FaArrowsAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
