import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useCreateAdminProductCategoriesMutation } from '../../../redux/features/adminShope/adminShopeApi';
import InputFile from '../../../components/ui/InputFile';

const validationSchema = Yup.object().shape({
  category_name_english: Yup.string().required(
    'English category name is required'
  ),
  category_name_arabic: Yup.string(),
  sortOrder: Yup.number()
    .required('Sort order is required')
    .min('', 'Sort order must be non-negative'),
});

const CreateAdminProductsCategouries = () => {
  const [createCategory] = useCreateAdminProductCategoriesMutation();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const categoryData = {
      title: {
        en: values.category_name_english,
        ar: values.category_name_arabic,
      },
      sortOrder: values.sortOrder,
      imageUrl: imageUrl,
    };

    try {
      const result = await createCategory(categoryData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        resetForm();
        navigate('/admin/categories');
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('An error occurred while creating the category');
    } finally {
      setSubmitting(false);
    }
  };

  const handleOtherValue = (key, value) => {
    setImageUrl(value);
  };

  return (
    <>
      <Card
        title="Create New Category"
        content={
          <Button
            link="/admin/product-categories"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Categories
          </Button>
        }
        className="space-y-3"
      >
        <Formik
          initialValues={{
            category_name_english: '',
            category_name_arabic: '',
            sortOrder: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="space-y-3">
              {[
                { name: 'english', label: 'ENGLISH' },
                { name: 'arabic', label: 'ARABIC' },
              ].map((lang) => (
                <div key={lang.name}>
                  <Field
                    name={`category_name_${lang.name}`}
                    type="text"
                    placeholder={`Category Name (${lang.label})`}
                    className="w-full rounded border px-2 py-2.5"
                  />
                  {errors[`category_name_${lang.name}`] &&
                    touched[`category_name_${lang.name}`] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[`category_name_${lang.name}`]}
                      </div>
                    )}
                </div>
              ))}

              <Field
                name="sortOrder"
                type="number"
                placeholder="Sort Order"
                className="w-full rounded border p-2"
              />
              {errors.sortOrder && touched.sortOrder && (
                <div className="text-sm text-red-500">{errors.sortOrder}</div>
              )}

              <div>
                Upload Image
                {imageUrl ? (
                  <div className="flex">
                    <div className="relative flex h-[150px] w-[150px] align-middle">
                      <img
                        src={imageUrl}
                        alt="Uploaded Image"
                        className="h-auto w-[150px] rounded-full object-cover align-middle"
                      />
                    </div>
                    <div className="relative inset-0 left-[-9.4rem] flex h-[150px] w-[150px] items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100">
                      <InputFile
                        name="imageUrl"
                        onChange={handleOtherValue}
                        category={true}
                      />
                      <label
                        htmlFor="dropzone-file"
                        className="flex h-[150px] w-[150px] cursor-pointer items-center justify-center"
                      ></label>
                    </div>
                  </div>
                ) : (
                  <InputFile name="imageUrl" onChange={handleOtherValue} />
                )}
              </div>

              <Button
                type="submit"
                className="mt-3 !bg-green-400"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save Category'}
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default CreateAdminProductsCategouries;
