import React, { useEffect, useRef, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import AgentSidebar from './Sidebar/AgentSidebar';
import BusinessSidebar from './Sidebar/BusinessSidebar';
import StoreSidebar from './Sidebar/StoreSidebar';
// import { RiArrowRightDoubleFill } from "react-icons/ri";
// import { RiArrowRightDoubleLine } from "react-icons/ri";
import { FaAnglesRight } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import logoImg from '../images/logo/socalii-black-log.png';
import { useGetPurchasedFeaturesQuery } from '../redux/features/clients/clientsApi';
import AdminSidebar from './Sidebar/AdminSidebar';
import IndividualSidebar from './Sidebar/IndividualSidebar';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const [tinySidebarOpen, setTinySidebarOpen] = useState(true);
  const [pinned, setPinned] = useState(true);
  const { data: purchasedFeaturesData, isLoading } =
    useGetPurchasedFeaturesQuery();

  const { user } = useSelector((state) => state.auth);

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const role = user?.role;

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleClickSidebarBtn = (e) => {
    e.stopPropagation();
    if (pinned) {
      setPinned(!pinned);
      setTinySidebarOpen(!tinySidebarOpen);
    } else {
      setPinned(true);
      setTinySidebarOpen(true);
    }
  };
  // const handleMouseEnter = () => {
  //   if (!pinned) {
  //     setTinySidebarOpen(true);
  //   }
  // };
  // const handleMouseLeave = () => {
  //   if (!pinned) {
  //     setTinySidebarOpen(false);
  //   }
  // };
  return (
    // isSmall ? "w-[0px]" : "w-72"
    // "overflow-y-hidden"
    //   {
    //     isSmall ? "w-[0px]" : "w-64"
    //   }
    <aside
      ref={sidebar}
      className={`$ dark:bg-boxdark absolute left-0 top-0 z-9999 flex
       h-screen ${tinySidebarOpen ? 'w-[265px]' : 'w-[75px]'} flex-col ${
        role === 'store' ? 'bg-[#0e2133]' : 'bg-[#1e1e2d]'
      } duration-300 ease-linear lg:static lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      {/* <DebugView data={purchasedFeaturesData} /> */}
      <div className="relative hidden items-center gap-2 border-b-[1px] border-dashed border-[#393945] px-4 py-5 md:flex lg:py-7">
        <NavLink to="/">
          {/* <h2 className="text-xl text-white hidden">DM XPOSURE</h2> */}
          <img className="ms-3 h-10 object-contain" src={logoImg} alt="Logo" />
        </NavLink>

        <button
          ref={trigger}
          onClick={handleClickSidebarBtn}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="absolute -right-4 top-1/2 hidden -translate-y-1/2 rounded-lg bg-white p-2 hover:text-[#009ef7] dark:bg-[#2A2A3C] lg:block"
        >
          <FaAnglesRight size={15} />
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-hidden overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-0 px-3 py-4">
          {/* <!-- Menu Group --> */}
          {role === 'agent' ? (
            <AgentSidebar
              pathname={pathname}
              sidebarExpanded={sidebarExpanded}
              setSidebarExpanded={setSidebarExpanded}
              tinySidebarOpen={tinySidebarOpen}
            />
          ) : role === 'admin' ? (
            <AdminSidebar
              pathname={pathname}
              sidebarExpanded={sidebarExpanded}
              setSidebarExpanded={setSidebarExpanded}
              tinySidebarOpen={tinySidebarOpen}
            />
          ) : role === 'business' ? (
            <BusinessSidebar
              pathname={pathname}
              sidebarExpanded={sidebarExpanded}
              setSidebarExpanded={setSidebarExpanded}
              tinySidebarOpen={tinySidebarOpen}
              purchasedFeaturesData={purchasedFeaturesData?.data}
            />
          ) : role === 'store' ? (
            <StoreSidebar
              pathname={pathname}
              sidebarExpanded={sidebarExpanded}
              setSidebarExpanded={setSidebarExpanded}
              tinySidebarOpen={tinySidebarOpen}
            />
          ) : role === 'individual' ? (
            <IndividualSidebar
              pathname={pathname}
              sidebarExpanded={sidebarExpanded}
              setSidebarExpanded={setSidebarExpanded}
              tinySidebarOpen={tinySidebarOpen}
              purchasedFeaturesData={purchasedFeaturesData?.data}
            />
          ) : (
            ''
          )}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
