import React from 'react';
import { FaPenToSquare } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { RiShareFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import CustomReactPlayer from '../../../components/ui/CustomReactPlayer';
import { formatRelativeTime } from '../../../utils/formatRelativeTime';
import {
  filterArr,
  getFullNameByLayer,
  hasExistArr,
} from '../../../utils/func';
const PostViewCard = ({
  handleShareOpen,
  handleInterestOpen,
  handleDeletePost,
  data,
  singlePostData,
  isPostView,
  userImage,
}) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  return (
    <div className="space-y-4">
      {(data?.data?.results || singlePostData)?.map((item) => (
        <Card
          key={item}
          contentStyle="w-full"
          content={
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full gap-2">
                <img
                  className="h-[30px] w-[30px] rounded-full"
                  src={item?.user?.bannerBg}
                  alt=""
                />
                <div>
                  <h4
                    className="cursor-pointer text-[13px] font-bold leading-none text-black"
                    onClick={() =>
                      navigate(`/social/profile/${item?.user?._id}`)
                    }
                  >
                    {getFullNameByLayer(item?.user)}
                  </h4>
                  <p className="text-[13px] opacity-80">
                    {formatRelativeTime(item?.createdAt)}
                  </p>
                </div>
              </div>

              {isPostView ? null : (
                <div className="flex items-center justify-end gap-2">
                  <Button
                    className="flex items-center gap-1 border !bg-white !px-2 !py-1 text-[10px] font-normal !text-black"
                    onClick={() => handleShareOpen(item)}
                  >
                    <RiShareFill />
                    <span>Share</span>
                  </Button>
                  {item?.user?._id === user?._id ? (
                    <>
                      {' '}
                      <Button
                        link={`/posts/edit/${item?._id}`}
                        className=" !block border !bg-blue-50 !px-3 !py-2 text-[10px] font-normal !text-blue-500"
                      >
                        <FaPenToSquare size={16} />
                      </Button>
                      <Button
                        className=" border !bg-red-50 !px-3 !py-2 text-[10px] font-normal !text-red-500"
                        onClick={() => handleDeletePost(item?._id)}
                      >
                        <MdDelete size={16} />
                      </Button>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          }
        >
          <div className="mb-1">
            <p className="overflow-hidden text-ellipsis  break-all text-black">
              {item?.content}
            </p>
          </div>
          {hasExistArr(item?.images) &&
            filterArr(item?.images)?.map((image) => {
              return <img src={image} alt="" />;
            })}
          <br />
          {hasExistArr(item?.videos) &&
            filterArr(item?.videos)?.map((video, j) => {
              return <CustomReactPlayer url={video} />;
            })}

          {/* {!isPostView ? null : (
            <div className="flex justify-end">
              <Button
                className="flex w-full items-center gap-3 !bg-lime-500 !text-white"
                onClick={() => handleInterestOpen(item)}
              >
                <FaHandPointer className="text-lg" />
                interested in
              </Button>
            </div>
          )} */}
        </Card>
      ))}
    </div>
  );
};

export default PostViewCard;
