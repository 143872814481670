import React from "react";
import { useTranslation } from "react-i18next";

const Wifi = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between ml-20 px-12 py-6 mr-20 mt-6 bg-gray-100">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/about.png"
                        alt={t('wifiTitle2')}
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-contain"
                    />
                </div>

                <div className="w-full md:w-1/2 md:pl-8">
                    <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">
                        {t('wifiTitle1')} <br />
                        <span className="font-black text-gray-900">{t('wifiTitle2')}</span>
                    </h2>
                    <p className="text-gray-600 leading-relaxed mb-4">
                        {t('wifiDescription')}
                    </p>

                    <button className="bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-gray-700">
                        {t('wifiButton')}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Wifi;
