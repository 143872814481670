import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCvsQuery } from '../../../redux/features/cv/cvApi';
const TemplateSelectionPage = () => {
  const navigate = useNavigate();
  const { data } = useGetCvsQuery();
  const templates = [
    // {
    //   id: 1,
    //   name: 'Template 1',
    //   button: '',
    //   previewImg: '/path/to/template1.png',
    // },
    {
      id: 2,
      name: 'Template 1',
      button: 'Use this template',
      previewImg: '/templete2.png',
    },
    {
      id: 3,
      name: 'Confidence',
      button: 'Use this template',
      previewImg: '/template3.png',
    },
    {
      id: 4,
      name: 'Smooth',
      button: 'Use this template',
      previewImg: '/template4.png',
    },
    {
      id: 5,
      name: 'Reflection',
      button: 'Use this template',
      previewImg: '/tempplate5.png',
    },
    {
      id: 6,
      name: 'Shadow',
      button: 'Use this template',
      previewImg: '/template6.png',
    },
  ];

  return (
    <div className="container mx-auto p-12">
      <>
        <>
          <h1 className="mb-4 text-2xl font-bold">Choose a CV Template</h1>
          <div className=" grid grid-cols-3 gap-4">
            {templates.map((template) => (
              <div
                key={template.id}
                className="h-[550px]  w-[400px] cursor-pointer rounded-lg border bg-gray-200 p-4 hover:shadow-lg"
                onClick={() => navigate(`/cv-create/${template.id}`)}
              >
                <img
                  src={template.previewImg}
                  alt={template.name}
                  className="h-[400px] w-full object-fill"
                />
                <h2 className="mt-4 text-center text-lg font-semibold">
                  {template.name}
                </h2>
                <button className="ml-4 mt-2 h-10 w-80 rounded-lg bg-blue-950 text-center text-lg text-white">
                  {template.button}
                </button>
              </div>
            ))}
          </div>
        </>

        {data?.data.length ? (
          <div>
            <h1 className="mb-4 mt-10 text-2xl font-bold">View cvs</h1>
            <div className=" grid grid-cols-3 gap-1">
              {data?.data?.map((cv) => (
                <div
                  key={cv?._id}
                  className="mt-5 h-[150px]  w-[400px] cursor-pointer rounded-lg border bg-gray-200 p-4 hover:shadow-lg"
                >
                  <h2 className="mt-4 text-center text-lg font-semibold">
                    {cv?.name}
                  </h2>
                  <div className="flex justify-center">
                    {/* <button className="ml-4 mt-2 h-10 w-80 rounded-lg bg-blue-950 text-center text-lg text-white">
                      Edit
                    </button> */}
                    <button
                      onClick={() => navigate(`/cv-view/${cv?._id}`)}
                      className="ml-4 mt-2 h-10 w-80 rounded-lg bg-blue-950 text-center text-lg text-white"
                    >
                      View CV
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default TemplateSelectionPage;
