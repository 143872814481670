import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ImCross } from 'react-icons/im';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import InputGroup from '../../../components/ui/InputField';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import { useConfirmOrderMutation } from '../../../redux/features/products/productsApi';
import { handelSendOrderToEmail } from '../../../utils/func';
const CartPage = ({
  cartItems,
  onAdd,
  onRemove,
  onDelete,
  setCart,
  storeData,
}) => {
  const token = localStorage.getItem('token_link_dashboard');
  const [searchParams] = useSearchParams();
  const paramsStoreId = searchParams.get('storeId');
  const { data: userData, refetch } = useGetMyProfileQuery(undefined, {
    skip: !token,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [confirmOrder, { isLoading }] = useConfirmOrderMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    orderLists: [],
    buyerEmail: userData?.data?.email || '',
    buyerPhoneNumber: `${userData?.data?.phone?.dialCode || ''} ${
      userData?.data?.phone?.fullNumber || ''
    }`,
    buyerAdress: `${userData?.data?.location.country || ''}, ${
      userData?.data?.location.city || ''
    } `,

    supplierUserId: paramsStoreId,
  });
  const guestEmail = localStorage.getItem('guestEmail');
  useEffect(() => {
    if (token && token !== 'undefined' && token !== null) {
      localStorage.setItem('buyerEmail', userData?.data?.email || '');
      refetch();
    }
    const buyerEmail = localStorage.getItem('buyerEmail');
    setValues({
      orderLists: [],
      buyerEmail: userData?.data?.email || buyerEmail || guestEmail || '',
      buyerPhoneNumber: `${userData?.data?.phone?.dialCode || ''} ${
        userData?.data?.phone?.fullNumber || ''
      }`,
      buyerAdress: `${userData?.data?.location.country || ''}, ${
        userData?.data?.location.city || ''
      } `,

      supplierUserId: paramsStoreId,
    });
  }, [userData, paramsStoreId]);

  // const itemPrice = cartItems.reduce(
  //   (total, item) => total + item.quantity * item.price,
  //   0
  // );
  // const taxPrice = itemPrice * 0.16;
  // const totalPrice = itemPrice + taxPrice;

  const itemPrice = cartItems.reduce(
    (total, item) => total + item.quantity * item.price,
    0
  );
  const totalPrice = itemPrice;

  const productsNames = values.orderLists
    .map((item) => item.itemName)
    .join(', ');

  const orderData = {
    order_id: Math.floor(10000000 + Math.random() * 90000000), // genrate 8 digit random tracking id
    customer_name: '',
    customer_email: values?.buyerEmail,
    order_details: `Thanks for your order , you have ordered ${productsNames} `,
  };
  const handleConfirmOrder = async () => {
    const currentOrderLists = cartItems.map((item) => ({
      itemId: item?._id,
      itemImage: item?.coverImgUrl,
      itemName: item?.title?.en,
      itemPrice: item?.price,
      itemQuantity: item?.quantity,
    }));

    if (currentOrderLists.length === 0) {
      toast.error('Products list in cart is empty');
      return;
    }

    try {
      const payload = {
        ...values,
        orderLists: currentOrderLists,
        trackingOrderId: orderData.order_id,
      };
      if (!payload?.buyerEmail) {
        toast.error('Please Enter Your Email');
        return;
      }
      if (
        !values?.buyerPhoneNumber ||
        values?.buyerPhoneNumber.length < 8 ||
        !payload?.buyerPhoneNumber
      ) {
        toast.error('Please Enter Your valid Phone Number');
        return;
      }
      if (!payload?.buyerAdress) {
        toast.error('Please Enter Your Address');
        return;
      }
      const result = await confirmOrder(payload);

      if (result?.data?.success) {
        setIsModalOpen(false);
        await handelSendOrderToEmail(orderData);
        toast.success(
          'Order Confirmed Successfully, Our team will contact you soon, You can check your order in your email'
        );
        localStorage.removeItem('cart');
        localStorage.setItem('buyerEmail', result?.data?.data?.buyerEmail);
        setCart([]);
        navigate(-1);
      } else if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCheckout = () => {
    // token ? handleConfirmOrder() :
    setIsModalOpen(true);
  };
  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  // useEffect(() => {
  //   values.orderLists = cartItems.map((item) => ({
  //     itemId: item?._id,
  //     itemImage: item?.coverImgUrl,
  //     itemName: item?.title?.en,
  //     itemPrice: item?.price,
  //     itemQuantity: item?.quantity,
  //   }));
  // }, [cartItems]);
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      orderLists: cartItems.map((item) => ({
        itemId: item?._id,
        itemImage: item?.coverImgUrl,
        itemName: item?.title?.en,
        itemPrice: item?.price,
        itemQuantity: item?.quantity,
      })),
    }));
  }, [cartItems]);

  const buyerEmail = localStorage.getItem('buyerEmail');
  return (
    <div className="mx-auto max-w-4xl p-6">
      <h1 className="mb-6 text-center text-3xl font-bold">My Cart</h1>
      {cartItems.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full rounded-lg border border-gray-200 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  #
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Image
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Name
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Price
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Quantity
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Total
                </th>
                <th className="border px-4 py-2 font-semibold text-gray-700">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={item._id} className="hover:bg-gray-50">
                  <td className="border px-4 py-2 text-center">{index + 1}</td>
                  <td className="border px-4 py-2 text-center">
                    <img
                      src={item.coverImgUrl}
                      alt={item.name}
                      className="mx-auto h-24 w-24 object-cover"
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {item.title?.en}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {item.price}{' '}
                    {item?.store?.store_contact_information?.currency || 'OMR'}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() => onRemove(item)}
                        className="border px-2 py-1 text-lg font-semibold"
                      >
                        -
                      </button>
                      <span className="mx-3">{item?.quantity}</span>
                      <button
                        onClick={() => onAdd(item)}
                        className="border px-2 py-1 text-lg font-semibold"
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {(item?.quantity * item?.price).toFixed(2)}{' '}
                    {item?.store?.store_contact_information?.currency || 'OMR'}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <button
                      onClick={() => onDelete(item)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <ImCross />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-6 space-y-2">
            <p className="text-xl font-bold">
              Total Price: {totalPrice.toFixed(2)}{' '}
              {cartItems[0]?.store?.store_contact_information?.currency ||
                'OMR'}
            </p>
          </div>
          <button
            className="mt-4 rounded bg-blue-600 px-6 py-2 text-white hover:bg-blue-700"
            onClick={handleCheckout}
          >
            Confirm Order
          </button>
        </div>
      ) : (
        <p className="text-center text-gray-700">Your cart is empty.</p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-[470px] rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-lg font-semibold">Enter Your Details</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Email:</label>
              <InputGroup
                placeholder="Please Enter Your Valid Email"
                name="buyerEmail"
                value={values?.buyerEmail}
                onChange={onChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone:</label>
              <InputGroup
                placeholder="Please Enter Your WhatsApp Number"
                name="buyerPhoneNumber"
                value={values?.buyerPhoneNumber}
                onChange={onChange}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Address:</label>
              <InputGroup
                placeholder="Please Enter Your Address"
                name="buyerAdress"
                value={values?.buyerAdress}
                onChange={onChange}
              />
            </div>
            <div className="flex justify-center space-x-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmOrder}
                className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
              >
                {isLoading ? 'Loading...' : 'Confirm Order'}
              </button>
            </div>
            {(!token || token === 'undefined' || token === null) && (
              <div className="mt-5 flex flex-col items-center justify-center">
                <h1 className="my-2">OR</h1>
                {buyerEmail && (
                  <Link
                    to={'/login-as-guest/?redirect=cart'}
                    className="text-blue-500"
                  >
                    Login as guest
                  </Link>
                )}

                <Link
                  to={'/register/?redirect=cart'}
                  className="text-blue-500 "
                >
                  Register
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
