import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import noImg from '../../../images/shared/no-image.jpg';
import {
  useAcceptFriendRequestMutation,
  useGetMyFriendRequestsQuery,
} from '../../../redux/features/friends/friendsApi';
import { getFullName } from '../../../utils/func';
import Loading from '../../Loading';
import Pagination from '../../ui/Pagination';

const FriendRequest = ({ isSingleView = false, limit = 10 }) => {
  const [searchText, setSearchText] = useState('');
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: limit,
  });

  const { data: myFriendRequestData } = useGetMyFriendRequestsQuery({
    ...paginate,
    q: searchText,
  });

  const [acceptRequest, { isLoading: isAcceptLoading }] =
    useAcceptFriendRequestMutation();
  // const [deleteRequest, { isLoading: isDeleteLoading }] =
  //   useDeleteFriendRequestMutation();

  const handleAcceptRequest = async (id) => {
    try {
      const result = await acceptRequest(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  // const handleDeleteRequest = async (id) => {
  //   const submitData = {
  //     requestId: id,
  //   };

  //   try {
  //     const result = await deleteRequest(submitData);
  //     if (result?.data?.success) {
  //       toast.success(result?.data?.message);
  //     } else {
  //       toast.error(result?.error?.data?.message);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.detail);
  //   }
  // };

  return (
    <div className="rounded-lg bg-white p-4 text-black shadow-md dark:bg-dark dark:text-gray-300">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">Friend Requests</h2>
        {isSingleView ? null : (
          <Link
            to={'/friends/all/got-requests'}
            className="text-blue-600 hover:underline"
          >
            See all
          </Link>
        )}
      </div>
      {/* <div className="mb-4 max-w-md p-3 ps-0 shadow-current">
        <SearchBar onSearch={(e) => setSearchText(e)} debounceTime={1500} />
      </div> */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
        {myFriendRequestData?.data?.map((person, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg border border-gray-200 bg-white text-black dark:bg-dark dark:text-gray-300"
          >
            {/* to={`/social/profile/${person?.friend?._id}`} */}
            <Link>
              <img
                src={person?.avatar || noImg}
                alt={getFullName(person)}
                className="h-32 w-full object-cover"
              />
            </Link>
            <div className="p-4">
              {/* to={`/social/profile/${person?.friend?._id}`} */}
              <Link>
                <h3 className="mb-1 cursor-pointer text-lg font-semibold">
                  {`${person?.firstName}  ${person?.lastName}`}
                </h3>
              </Link>
              {/* <p className="text-gray-600 text-sm mb-3">{person.mutualFriends} mutual friend{person.mutualFriends !== 1 && 's'}</p> */}
              <button
                className="mb-2 flex w-full items-center justify-center rounded bg-blue-100 px-2 py-1 text-blue-600"
                onClick={() => handleAcceptRequest(person?._id)}
              >
                {isAcceptLoading ? (
                  <Loading variant="button" height="20px" />
                ) : (
                  'Confirm'
                )}
              </button>
              {/* <button
                className="flex w-full items-center justify-center rounded bg-gray-100 px-2 py-1 text-gray-600"
                onClick={() => handleDeleteRequest(person?.requestId)}
              >
                {isDeleteLoading ? (
                  <Loading variant="button" height="20px" />
                ) : (
                  'Delete'
                )}
              </button> */}
            </div>
          </div>
        ))}
      </div>
      <br />
      {myFriendRequestData?.data?.paginate?.totalCount > 10 && (
        <Pagination
          pagination={myFriendRequestData?.data?.paginate}
          onPaginate={setPaginate}
        />
      )}
    </div>
  );
};

export default FriendRequest;
