import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillTikTok, AiOutlineGlobal } from 'react-icons/ai';
import { BiOutline } from 'react-icons/bi';
import { BsFillThreadsFill } from 'react-icons/bs';
import {
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaEye,
  FaFacebook,
  FaFax,
  FaInstagramSquare,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
  FaPen,
  FaSnapchatSquare,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import {
  FaBusinessTime,
  FaGripLines,
  FaTelegram,
  FaUserLarge,
} from 'react-icons/fa6';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoIosCloseCircle, IoIosLink } from 'react-icons/io';
import { LuKeyRound, LuWarehouse } from 'react-icons/lu';
import { MdClose } from 'react-icons/md';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import { countryPhoneOptions } from '../../../data/data';
// import GeocodingExample from '../../../GeocodingExample';
import { useLocation } from 'react-router-dom';
import PreviewImg from '../../../components/ui/PreviewImg';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import { useGetBusinessTypesQuery } from '../../../redux/features/businessTypes/businessTypes';
import {
  useEditCardMutation,
  useGetCardQrQuery,
} from '../../../redux/features/cards/cardsApi';
import { useEditUserMutation } from '../../../redux/features/users/usersApi';
import { capitalizeFirstLetter } from '../../../utils/dataFormater';
import {
  extractCoordinates,
  isValidGoogleMapsUrl,
} from '../../../utils/helper';
import { isValidEmail } from '../../../utils/validater';

const ClassicView = ({
  card,
  variant,
  viewMode,
  paramsUserId,
  paramsIsChildBranch,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [inputSkill, setInputSkill] = useState('');
  const { data: profileData } = useGetMyProfileQuery();

  const [isConfirmedRemoved, setIsConfirmRemoved] = useState(false);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const [qrImages, setQrImages] = useState({
    qrImage: '',
    qrOfflineImage: '',
  });

  const [editCard, { isLoading: editLoading }] = useEditCardMutation();
  const [editUser, { isLoading: editUserLoading }] = useEditUserMutation();

  const basePath = pathname.split('/').slice(0, 3).join('/') + '/';

  const cardId =
    basePath === '/card/edit/'
      ? card?.data?.card?.branchNumber
      : card?.data?.card?.branchNumber;
  const {
    data: qrRegenerateData,
    refetch,
    isFetching: isFetchingQr,
  } = useGetCardQrQuery(cardId, {
    skip: !cardId,
  });
  //   console.log(isFetching);
  // console.log(qrRegenerateData?.data);
  // const { qrImage, qrOfflineImage } = card?.data?.user?.card || {};
  //    || card?.data || {};

  useEffect(() => {
    setQrImages({
      qrImage: qrRegenerateData?.data?.qrImage,
      qrOfflineImage: qrRegenerateData?.data?.qrOfflineImage,
    });
  }, [qrRegenerateData]);

  const [values, setValues] = useState();
  console.log(values);
  useEffect(() => {
    setValues(
      {
        ...card?.data,
        businessName: card?.data?.card?.businessName,
        businessType: card?.data?.card?.businessType,
        slogan: card?.data?.card?.slogan,
        phone: {
          dialCode: card?.data?.phone?.dialCode
            ? card?.data?.phone?.dialCode
            : profileData?.data?.phone?.dialCode || '',
          fullNumber: card?.data?.phone?.fullNumber || '',
        },
        whatsapp: {
          dialCode: card?.data?.whatsapp?.dialCode
            ? card?.data?.whatsapp?.dialCode
            : profileData?.data?.phone?.dialCode || '',
          fullNumber: card?.data?.whatsapp?.fullNumber || '',
        },
      } || {}
    );
  }, [profileData, card]);

  const [additionalNumber, setAdditionalNumber] = useState(
    card?.data?.user?.additionalPhones || []
  );
  const [marketplaceInfos, setMarketplaceInfos] = useState(
    card?.data?.user?.marketplaceStores || []
  );
  const [bankInfos, setBankInfos] = useState(
    card?.data?.user?.bankAccounts || []
  );

  const [extraSocialLinks, setExtraSocialLinks] = useState(
    card?.data?.user?.additionalSocialLinks || []
  );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  // additional phones, bank and marketplace store functionality
  const handleNewSection = (type) => {
    const newValue = {
      id: Math.ceil(Math.random() * 1000000),
    };

    if (type === 'phone') {
      setAdditionalNumber([...additionalNumber, newValue]);
    }
    if (type === 'bank') {
      setBankInfos([...bankInfos, newValue]);
    }
    if (type === 'marketplace') {
      setMarketplaceInfos([...marketplaceInfos, newValue]);
    }
    if (type === 'socialLinks') {
      setExtraSocialLinks([...extraSocialLinks, newValue]);
    }
  };

  const handleRemove = (id, type) => {
    const updateNumbers = additionalNumber?.filter((data) => data?.id !== id);
    const updateBankInfo = bankInfos?.filter((data) => data?.id !== id);
    const updateMarketplaceInfo = marketplaceInfos?.filter(
      (data) => data?.id !== id
    );
    const updateSocialLinks = extraSocialLinks?.filter(
      (data) => data?.id !== id
    );

    if (type === 'phone') {
      setAdditionalNumber(updateNumbers);
    }
    if (type === 'bank') {
      setBankInfos(updateBankInfo);
    }
    if (type === 'marketplace') {
      setMarketplaceInfos(updateMarketplaceInfo);
    }
    if (type === 'socialLinks') {
      setExtraSocialLinks(updateSocialLinks);
    }
  };

  // additional phone, bank and marketplace functionality
  // const handlePhoneChange = (key, value, subKey) => {
  //   const updateValue = additionalNumber?.map((item) => {
  //     if (item.id === subKey) {
  //       return {
  //         ...item,
  //         [key]: value,
  //       };
  //     }
  //     return item;
  //   });

  //   setAdditionalNumber(updateValue);
  // };
  // const handleBankAccount = (key, value, subKey) => {
  //   const updateValue = bankInfos?.map((item) => {
  //     if (item.id === subKey) {
  //       return {
  //         ...item,
  //         [key]: value,
  //       };
  //     }
  //     return item;
  //   });

  //   setBankInfos(updateValue);
  // };
  const handleMarketplace = (key, value, subKey) => {
    const updateValue = marketplaceInfos?.map((item) => {
      if (item.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setMarketplaceInfos(updateValue);
  };

  const handleSocialLinks = (key, value, subKey) => {
    const updateValue = extraSocialLinks?.map((item) => {
      if (item?.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setExtraSocialLinks(updateValue);
  };

  // SKILL FUNCTIONALITY
  useEffect(() => {
    if (isConfirmedRemoved) {
      setTimeout(() => {
        setIsConfirmRemoved(false);
      }, 3000);
    }
  }, [isConfirmedRemoved]);
  const onSkillChange = (key, value) => {
    setInputSkill(value);
  };

  const handleAddSkill = () => {
    const newSkills = [...values?.skills, inputSkill];
    setInputSkill('');
    setValues((prev) => {
      return { ...prev, skills: newSkills };
    });
  };

  const handleRemoveSkill = (value) => {
    const updateSkills = values?.skills?.filter((skill) => skill !== value);
    setValues((prev) => {
      return { ...prev, skills: updateSkills };
    });
  };

  // SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values?.whatsapp?.fullNumber || values?.whatsapp?.fullNumber == '') {
      toast.error('Please enter whatsapp number');
      return;
    }

    if (values?.address && !isValidGoogleMapsUrl(values?.address)) {
      toast.error(
        `Please enter a valid address like:
    https://maps.app.goo.gl/ognCdxwrrj6ZgCeA8`
      );
      return;
    }

    try {
      if (values?.address && isValidGoogleMapsUrl(values?.address)) {
        var { latitude, longitude } = await extractCoordinates(values.address);
        if (!latitude && !longitude) {
          toast.error(
            'Cannot find coordinates for the address, try another address'
          );
          return;
        }
      }

      const submitData = {
        ...values,
        additionalPhones: additionalNumber,
        bankAccounts: bankInfos,
        branchId: id,
        marketplaceStores: marketplaceInfos,
        additionalSocialLinks: extraSocialLinks,
        // userMode: viewMode,
        location: {
          type: 'Point',
          lat: latitude, // Ensure latitude is a number
          long: longitude, // Ensure longitude is a number
        },
        pointLocation: {
          type: 'Point',
          coordinates: [latitude || 0, longitude || 0], // Ensure coordinates are numbers
        },
      };
      const API =
        variant === 'user'
          ? editUser({ id: paramsUserId, data: submitData })
          : editCard({ id: values?._id, data: submitData });

      const response = await API;
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // setTimeout(() => {
        // variant === 'user' ? navigate('/home') : navigate('/cards');
        if (variant === 'user') {
          // window.location.href = '/home';
          if (redirect) {
            navigate(redirect);
          } else {
            navigate('/home');
          }
        } else {
          navigate('/cards');
        }

        // }, 5000);
        // variant === "user" ? navigate("/admin/users") : navigate("/cards");
      }
      if (response?.error?.status === 400) {
        toast.error(response?.error?.data?.message);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handleReGenerateQR = (e) => {
    e.stopPropagation();
    refetch();
  };

  // useEffect(() => {
  //   const fetchCoordinates = async () => {
  //     if (values?.address?.length > 5) {
  //       try {
  //         const { latitude, longitude } = await getCoordinates(values.address);
  //         if (latitude && longitude) {
  //           setValues((prev) => {
  //             return {
  //               ...prev,
  //               location: {
  //                 type: 'Point',
  //                 lat: latitude,
  //                 long: longitude,
  //               },
  //               pointLocation: {
  //                 type: 'Point',
  //                 coordinates: [longitude, latitude],
  //               },
  //             };
  //           });
  //         } else {
  //           toast.error('Please enter a valid address');
  //         }
  //       } catch (error) {
  //         toast.error(error);
  //       }
  //     }
  //   };

  //   fetchCoordinates();
  // }, [values?.address]);
  const { data } = useGetBusinessTypesQuery();
  function transformBusinessTypeData(data = []) {
    return data?.map((item) => ({
      label: capitalizeFirstLetter(item.businessType.replace(/\d+$/, '')), // Format label
      value: item._id, // Use _id as value
    }));
  }


  const businessTypeData = transformBusinessTypeData(data?.data);
console.log(values?.card?.url
    ?.split('/')
    .slice(3)
    .join('/'))
  return (
    <form
      onSubmit={handleSubmit}
      onChange={(e) => e.preventDefault()}
      className="flex gap-4"
    >
      {/* {JSON.stringify(values)} */}
      {/* {JSON.stringify(card?.data?.card)} */}
      {/* <DebugView data={card.data.role} /> */}

      <div className="w-full max-w-[380px] space-y-6 ">
        <Card
          className="space-y-6 px-6"
          content={
            <div className="space-y-2">
              <h3 className="text-[18px] font-bold text-black dark:text-white">
                {card?.data?.user?.firstName || ''}{' '}
                {card?.data?.user?.lastName || ''}
              </h3>
              {/*<Link*/}
              {/*  to={variant === 'user' ? `/user/view/${id}` : window.location.origin +`${values?.card?.url*/}
              {/*      ?.split('/')*/}
              {/*      .slice(3)*/}
              {/*      .join('/')}`}*/}
              {/*  className="flex h-[30px] w-[50px] items-center justify-center rounded-md bg-lemon-100 text-white"*/}
              {/*>*/}
              {/*  <FaEye />*/}
              {/*</Link>*/}

              <button
                  onClick={() => {
                    const constructedUrl = `${window.location.origin}/${values?.card?.url
                        ?.split('/')
                        .slice(3)
                        .join('/')}`;
                    window.location.href = constructedUrl;
                  }}
                  className="flex h-[30px] w-[50px] items-center justify-center rounded-md bg-lemon-100 text-white"
              >
                <FaEye/>
              </button>
            </div>
          }
        >
          <div className="rounded-lg border pb-2 text-center dark:border-black-300">
            <img className="w-full" src={qrImages.qrImage} alt=""/>
            <h4 className="text-[13px]">Linkincard Profile QR</h4>
            <button
                type="button"
                className="mt-1 rounded-md bg-blue-700 px-4 py-1 text-[12px] text-white"
            >
              Download in better quality
            </button>
          </div>
          <div className="rounded-lg border pb-6 text-center dark:border-black-300">
            <div className="flex justify-end p-1">
              <button
                type="button"
                className=" flex h-[20px] items-center  rounded-lg bg-lemon-100 px-2 text-[12px] text-white "
              >
                new
              </button>
            </div>
            <div className="px-5">
              <img className="w-full" src={qrImages.qrOfflineImage} alt="" />
              <div className=" pt-3">
                <h4 className="text-[13px] leading-none">Offline QR</h4>
                {/* <p className="text-[13px] text-lemon-100">Regenerate</p> */}
                <p
                  className="cursor-pointer py-1 text-[13px] text-lemon-100"
                  onClick={handleReGenerateQR}
                >
                  {isFetchingQr ? 'Regenerating...' : 'Regenerate'}
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-1 rounded-lg border p-5 dark:border-black-300">
            <h4 className="text-[14px] text-danger">
              Before you generate your Offline QR read below.
            </h4>
            <p className="text-[12px] leading-4">
              Offline QR is only used to generate your vcard in qr. So even your
              contacts doesnt have internet connection they can add you as
              contact to their mobile. When you generate your qr it will put
              your contact information in to qr code. The information in offline
              QR is not dynamic information. It means that when your contact
              information change you should generate your QR again. If you plan
              to print your Offline QR please be sure you filled all your
              contact information in your Profile Edit screen.
            </p>
          </div>
        </Card>
        {/* {JSON.stringify(paramsIsChildBranch)} */}
        {values?.card?.isChildBranch !== false ? null : (
          <>
            <Card title="Choose Promo Picture">
              <img src={values?.avatar} alt="" />
            </Card>
            <Card title="Profile Image">
              <img src={values?.bannerBg} alt="" />
            </Card>
          </>
        )}
      </div>
      <div className="w-full space-y-6">
        <Card title="Account Information" className="space-y-3">
          {/* <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your Full Name"
            name="fullName"
            value={values?.fullName}
            onChange={onChange}
          /> */}
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your First Name"
            name="card"
            value={
              values?.card?.isChildBranch
                ? values?.card?.firstName
                : values?.firstName
            }
            onChange={onChange}
            subKey={'firstName'}
          />
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your Last Name"
            name="card"
            value={
              values?.card?.isChildBranch
                ? values?.card?.lastName
                : values?.lastName
            }
            onChange={onChange}
            subKey={'lastName'}
          />

          <InputGroup
            icon={<HiOutlineMailOpen size={14} />}
            placeholder="Please Enter Your Email"
            name="card"
            value={values?.card?.email}
            subKey={'email'}
            disabled={true}
          />

          {values?.card?.isChildBranch !== false ? null : (
            <InputGroup
              icon={<LuKeyRound size={14} />}
              placeholder="Please Enter Your Password"
              type="password"
              name="password"
              value={values?.password}
              onChange={onChange}
            />
          )}

          {values?.card?.isChildBranch !== false ? null : (
            <>
              <Card>
                <PreviewImg src={values?.avatar} isBanner={true} />
                <InputFile
                  fileTypes=".jpg, .png, .jpeg"
                  name="avatar"
                  onChange={onChange}
                  label="Promo Photo"
                  isBanner={true}
                />
              </Card>
              <Card>
                <PreviewImg src={values?.bannerBg} />
                <InputFile
                  name="bannerBg"
                  onChange={onChange}
                  label="Profile Image"
                  fileTypes=".jpg, .png, .jpeg"
                />
              </Card>
            </>
          )}

          {/*<InputGroup*/}
          {/*    icon={<FaGripLines  size={14} />}*/}
          {/*    placeholder="PLease enter your slogan"*/}
          {/*    name="slogan"*/}
          {/*    value={values?.slogan}*/}
          {/*    onChange={onChange}*/}
          {/*/>*/}

          {card?.data?.role === 'business' && (
            <>
              <InputGroup
                icon={<FaBusinessTime size={14} />}
                placeholder="Please enter your business name"
                name="businessName"
                value={values?.businessName}
                onChange={onChange}
              />
              <InputGroup
                icon={<FaGripLines size={14} />}
                placeholder="Please enter your slogan"
                name="slogan"
                value={values?.slogan}
                onChange={onChange}
              />

              <SelectOptions
                placeholder="Business Type"
                name={'businessType'}
                value={values?.businessType}
                onChange={onChange}
                disable={true}
                options={businessTypeData}
                icon={
                  <>
                    <FaBusinessTime size={14} />
                  </>
                }
              />
            </>
          )}

          <InputGroup
            icon={<BiOutline size={14} />}
            placeholder="Please enter your bio"
            name="bio"
            value={values?.bio}
            onChange={onChange}
          />

          <div className="flex items-center gap-2 pb-3">
            {values?.skills?.map((s) => (
              <div key={s}>
                {isConfirmedRemoved ? (
                  <button
                    onClick={() => handleRemoveSkill(s)}
                    className="flex items-center gap-1 rounded-xl bg-red-500 px-2 py-0.5 text-[12px] text-white"
                    type="button"
                  >
                    {s} <IoIosCloseCircle size={14} />
                  </button>
                ) : (
                  <button
                    onClick={() => setIsConfirmRemoved(true)}
                    type="button"
                    className="rounded-xl bg-gray-200 px-3 py-0.5 text-[12px]"
                  >
                    {s}
                  </button>
                )}
              </div>
            ))}
          </div>
        </Card>
        <Card title="Contact Information" className="space-y-3">
          <div className=" flex w-full items-center gap-3">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="phone"
              subKey="dialCode"
              value={values?.phone?.dialCode}
              onChange={onChange}
            />
            <div className="w-full">
              <InputGroup
                icon={<FaMobileAlt />}
                name="phone"
                subKey="fullNumber"
                placeholder="Mobile Number"
                type="number"
                value={values?.phone?.fullNumber}
                onChange={onChange}
                disabled={!values?.phone?.dialCode}
              />
            </div>
          </div>
          <div className=" flex w-full items-center gap-3">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="whatsapp"
              subKey="dialCode"
              value={values?.whatsapp?.dialCode}
              onChange={onChange}
            />
            <div className="w-full">
              <InputGroup
                icon={<FaMobileAlt />}
                name="whatsapp"
                subKey="fullNumber"
                placeholder="Whatsapp Number"
                type="number"
                value={values?.whatsapp?.fullNumber}
                onChange={onChange}
              />
            </div>
          </div>

          <InputGroup
            icon={<FaLocationArrow size={14} />}
            placeholder="Please Enter Your Address"
            name="card"
            subKey={'address'}
            value={values?.card?.address}
            onChange={onChange}
          />
          {/* insetead of adress add longitude and latitude */}
          {/* <div className="flex items-center">
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              placeholder="Latitude"
              name="location"
              subKey="lat"
              value={values?.location?.lat}
              onChange={onChange}
            />

            <InputGroup
              icon={<FaLocationArrow size={14} />}
              placeholder="Longitude"
              name="location"
              subKey="long"
              value={values?.location?.long}
              onChange={onChange}
            />
          </div> */}

          <InputGroup
            icon={<FaFax size={14} />}
            name="faxNumber"
            value={values?.faxNumber}
            onChange={onChange}
            placeholder="Please Enter Your Fax Number"
          />
        </Card>

        {/* <Card title="Job Information" className="space-y-3">
          <InputGroup
            icon={<LuWarehouse size={14} />}
            placeholder="Please Enter Your Company Name"
            name="jobInformation"
            subKey="companyName"
            value={values?.jobInformation?.companyName}
            onChange={onChange}
          />
          <InputGroup
            icon={<LuWarehouse size={14} />}
            placeholder="Position (Job Title)"
            name="jobInformation"
            subKey="position"
            value={values?.jobInformation?.position}
            onChange={onChange}
          />
        </Card> */}
        {/*<Card title="Bank Account | IBAN" className="space-y-6">*/}
        {/*  {bankInfos?.map((data) => (*/}
        {/*    <div key={data?.id} className="space-y-3 rounded border p-2">*/}
        {/*      <div className="flex justify-end">*/}
        {/*        <button*/}
        {/*          onClick={() => handleRemove(data?.id, 'bank')}*/}
        {/*          className="text-red-500"*/}
        {/*          type="button"*/}
        {/*        >*/}
        {/*          <MdClose />*/}
        {/*        </button>*/}
        {/*      </div>*/}
        {/*      <SelectOptions*/}
        {/*        icon={<LuWarehouse size={14} />}*/}
        {/*        placeholder="Select bank..."*/}
        {/*        options={[{ label: 'text', value: 'text' }]}*/}
        {/*        name="bankName"*/}
        {/*        subKey={data?.id}*/}
        {/*        value={data?.bankName}*/}
        {/*        onChange={handleBankAccount}*/}
        {/*      />*/}

        {/*      <InputGroup*/}
        {/*        icon={<LuWarehouse size={14} />}*/}
        {/*        placeholder="Bank Account Name"*/}
        {/*        name="accountName"*/}
        {/*        subKey={data?.id}*/}
        {/*        value={data?.accountName}*/}
        {/*        onChange={handleBankAccount}*/}
        {/*      />*/}
        {/*      <InputGroup*/}
        {/*        icon={<LuWarehouse size={14} />}*/}
        {/*        placeholder="IBAN No"*/}
        {/*        name="accountNo"*/}
        {/*        subKey={data?.id}*/}
        {/*        value={data?.accountNo}*/}
        {/*        onChange={handleBankAccount}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  ))}*/}

        {/*  <Button onClick={() => handleNewSection('bank')}>*/}
        {/*    Add Bank Account*/}
        {/*  </Button>*/}
        {/*</Card>*/}
        {card?.data?.store?.isCardByStoreExist && (
          <Card title="Marketplace Stores" className="space-y-6">
            {marketplaceInfos?.map((data) => (
              <div key={data?.id} className="space-y-3 rounded border p-2">
                <div className="flex justify-end">
                  <button
                    onClick={() => handleRemove(data?.id, 'marketplace')}
                    className="text-red-500"
                    type="button"
                  >
                    <MdClose />
                  </button>
                </div>
                <SelectOptions
                  icon={<LuWarehouse size={14} />}
                  placeholder="Select Marketplace..."
                  options={[{ label: 'text', value: 'text' }]}
                  name="marketplaceName"
                  subKey={data?.id}
                  value={data?.marketplaceName}
                  onChange={handleMarketplace}
                />

                <InputGroup
                  icon={<LuWarehouse size={14} />}
                  placeholder="Marketplace Stores Url"
                  name="marketplaceStore"
                  subKey={data?.id}
                  value={data?.marketplaceStore}
                  onChange={handleMarketplace}
                />
              </div>
            ))}

            <Button onClick={() => handleNewSection('marketplace')}>
              Add Marketplace Store
            </Button>
          </Card>
        )}

        <Card title="Social Media Links" className="space-y-3">
          <InputGroup
            icon={<AiOutlineGlobal size={14} />}
            name="socialMediaLinks"
            subKey="website"
            value={values?.socialMediaLinks?.website}
            onChange={onChange}
            placeholder="Please Enter Your Website Link"
          />
          <InputGroup
            icon={<FaFacebook size={14} />}
            name="socialMediaLinks"
            subKey="facebook"
            value={values?.socialMediaLinks?.facebook}
            onChange={onChange}
            placeholder="Please Enter Your Facebook Page Link"
          />

          <InputGroup
            icon={<FaTwitter size={14} />}
            name="socialMediaLinks"
            subKey="twitter"
            value={values?.socialMediaLinks?.twitter}
            onChange={onChange}
            placeholder="Please Enter Your Twitter Page Link"
          />
          <InputGroup
            icon={<FaYoutube size={14} />}
            name="socialMediaLinks"
            subKey="youtube"
            value={values?.socialMediaLinks?.youtube}
            onChange={onChange}
            placeholder="Please Enter Your Youtube Page Link"
          />

          <InputGroup
            icon={<FaInstagramSquare size={14} />}
            name="socialMediaLinks"
            subKey="instagram"
            value={values?.socialMediaLinks?.instagram}
            onChange={onChange}
            placeholder="Please Enter Your Instagram Page Link"
          />
          <InputGroup
            icon={<FaLinkedin size={14} />}
            name="socialMediaLinks"
            subKey="linkedin"
            value={values?.socialMediaLinks?.linkedin}
            onChange={onChange}
            placeholder="Please Enter Your Linkedin Link"
          />

          <InputGroup
            icon={<FaTelegram size={14} />}
            name="socialMediaLinks"
            subKey="telegram"
            value={values?.socialMediaLinks?.telegram}
            onChange={onChange}
            placeholder="Please Enter Your Telegram Link"
          />
          <InputGroup
            icon={<FaExternalLinkAlt size={14} />}
            name="socialMediaLinks"
            subKey="linkText"
            value={values?.socialMediaLinks?.linkText}
            onChange={onChange}
            placeholder="Please Enter Your link"
          />

          <InputGroup
            icon={<AiFillTikTok size={14} />}
            name="socialMediaLinks"
            subKey="tiktok"
            value={values?.socialMediaLinks?.tiktok}
            onChange={onChange}
            placeholder="Please Enter Your Tiktok Link"
          />

          <InputGroup
            icon={<FaSnapchatSquare size={14} />}
            name="socialMediaLinks"
            subKey="snapchat"
            value={values?.socialMediaLinks?.snapchat}
            onChange={onChange}
            placeholder="Please Enter Your Snapchat Link"
          />

          <InputGroup
            icon={<BsFillThreadsFill size={14} />}
            name="socialMediaLinks"
            subKey="thread"
            value={values?.socialMediaLinks?.thread}
            onChange={onChange}
            placeholder="Please Enter Your Threads Link"
          />

          <InputGroup
            icon={<FaYoutube size={14} />}
            name="socialMediaLinks"
            subKey="videoLink"
            value={values?.socialMediaLinks?.videoLink}
            onChange={onChange}
            placeholder="Please Enter Your Profile Video (Youtube) Link"
          />
          <InputGroup
            icon={<FaUserLarge size={14} />}
            name="socialMediaLinks"
            subKey="resumeLink"
            value={values?.socialMediaLinks?.resumeLink}
            onChange={onChange}
            placeholder="Please Enter Your Resume Link"
          />

          <div className="!mt-6 space-y-5 border-t pt-5 dark:border-gray-800">
            <h2 className="text-[18px] font-bold text-black dark:text-white">
              Additional Social links
            </h2>

            <div className="space-y-6">
              {extraSocialLinks?.map((data) => (
                <div key={data?.id} className="space-y-3 rounded border p-2">
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleRemove(data?.id, 'socialLinks')}
                      className="text-red-500"
                      type="button"
                    >
                      <MdClose />
                    </button>
                  </div>
                  <InputFile
                    label="Icon"
                    name="icon"
                    subKey={data?.id}
                    value={data?.icon}
                    onChange={handleSocialLinks}
                  />
                  <InputGroup
                    icon={<FaExternalLinkSquareAlt size={14} />}
                    placeholder="Social Name"
                    name="name"
                    subKey={data?.id}
                    value={data?.name}
                    onChange={handleSocialLinks}
                  />
                  <InputGroup
                    icon={<IoIosLink size={14} />}
                    placeholder="Link"
                    name="link"
                    subKey={data?.id}
                    value={data?.link}
                    onChange={handleSocialLinks}
                  />
                </div>
              ))}
            </div>

            <Button onClick={() => handleNewSection('socialLinks')}>
              Add Social
            </Button>
          </div>
        </Card>

        {/*<Card title="Bio" className="space-y-3">*/}
        {/*  <TextAreaField*/}
        {/*    name="bio"*/}
        {/*    value={values?.bio}*/}
        {/*    onChange={onChange}*/}
        {/*    placeholder="Please Enter You Bio..."*/}
        {/*  />*/}
        {/*</Card>*/}

        <Button
          isDisabled={editLoading || editUserLoading}
          type="submit"
          className="flex h-auto items-center justify-center"
        >
          {(editLoading || editUserLoading) && (
            <Loading height="0vh" variant="button" />
          )}
          <span> Update</span>
        </Button>
      </div>
    </form>
  );
};

export default ClassicView;
