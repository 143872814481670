import React from 'react';
import AreaChart from '../../../components/charts/AreaChart';
import Loading from '../../../components/Loading';
import Card from '../../../components/ui/Card';
import { useGetUsersAndBranchesCountQuery } from '../../../redux/features/staticAnalytics/staticAnalyticsApi';
import StatCard from './cards/StatCard';

const AdminDashboard = () => {
  const { data: userCounts, isLoading: isUserCountsLoading } =
    useGetUsersAndBranchesCountQuery();
  const { allUsers, allIndividualUsers, allBusinessUsers, businessBranch } =
    userCounts?.data || {};

  const dummyData = [
    {
      name: 'XYZ MOTORS',
      data: [
        [1640995200000, 3200000], // Example date & value
        [1641081600000, 3100000],
        [1641168000000, 3150000],
      ],
    },
  ];

  if (isUserCountsLoading) {
    return <Loading />;
  }
  return (
    <div>
      {/* Main cards */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <StatCard title="Total Users" value={allUsers} />
        <StatCard title="Total Individuals" value={allIndividualUsers} />
        <StatCard title="Total Business" value={allBusinessUsers} />
        <StatCard title="Total Branches" value={businessBranch} />
      </div>
      {/* Main cards */}

      <Card className="mt-10">
        <AreaChart seriesData={dummyData} chartTitle="Total Sales" />
      </Card>
    </div>
  );
};

export default AdminDashboard;
