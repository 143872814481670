import { apiSlice } from '../api/apiSlice';

export const staticsAnalyticApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: () => ({
        url: '/analytics/dashboard',
        method: 'GET',
      }),
      providesTags: ['staticAnalytics'],
    }),

    // get all users and brnches
    getUsersAndBranchesCount: builder.query({
      query: () => ({
        url: '/stats/user-count',
        method: 'GET',
      }),
      providesTags: ['adminStatsUsers'],
    }),
  }),
});

export const { useGetAnalyticsQuery, useGetUsersAndBranchesCountQuery } =
  staticsAnalyticApi;
