import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { FaImage } from 'react-icons/fa6';
import { useUploadMutation } from '../../redux/features/common/commonApi';
import Loading from '../Loading';
import Button from './Button';

const UploadImageWithButton = ({ onChange }) => {
  const imageInputRef = useRef(null);

  const handleImageClick = () => {
    imageInputRef.current.click();
  };

  const [fileUpload, { isLoading }] = useUploadMutation();

  const handleImageFileChange = async (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        let result = await fileUpload(formData);
        if (result?.data?.success) {
          onChange(result?.data?.data?.url);
        }
      } catch (error) {
        toast.error('Error during file upload!');
      }
    } else {
      toast.error('Please upload a valid image file (e.g., .jpg, .png).');
    }
  };

  return (
    <>
      <Button
        className="!bg-black px-5 py-3 !text-gray-200"
        onClick={handleImageClick}
      >
        {!isLoading ? <FaImage /> : <Loading variant="button" height="14px" />}
      </Button>
      <input
        type="file"
        ref={imageInputRef}
        onChange={handleImageFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default UploadImageWithButton;
