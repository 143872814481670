import React from 'react';
import Card from '../../../../components/ui/Card';

const StatCard = ({ title, value }) => {
  return (
    <Card>
      <div className="gp-5 flex flex-col p-10">
        <div className="text-xl font-semibold">{title}</div>
        <div className="text-5xl font-semibold">{value}</div>
      </div>
    </Card>
  );
};

export default StatCard;
