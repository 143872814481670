import React, { useState } from 'react';
import Card from '../../../components/ui/Card';
import RegistrationForm from '../../authentication/RegistrationForm';

const CreateUser = () => {
  const [identifier, setIdentifier] = useState({});
  return (
    <Card>
      <RegistrationForm isAdmin={true} setIdentifier={setIdentifier} />
    </Card>
  );
};

export default CreateUser;
