import { apiSlice } from '../api/apiSlice';

export const cvApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all cvs
    getCvs: builder.query({
      query: () => ({
        url: '/users/get/user/All/CV',
        method: 'GET',
      }),
      providesTags: ['cvs'],
    }),

    // CREATE CV
    createCv: builder.mutation({
      query: (data) => ({
        url: '/users/create/user/CV/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['cvs'],
    }),

    // GET CV BY ID
    getCvById: builder.query({
      query: (id) => ({
        url: `/users/get/user/CV/${id}`,
        method: 'GET',
      }),
      providesTags: ['cvs'],
    }),
  }),
});

export const { useGetCvsQuery, useCreateCvMutation, useGetCvByIdQuery } = cvApi;

// howe to use useCreateCvMutation
// const [createCv, { isLoading }] = useCreateCvMutation();
