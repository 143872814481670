const features = {
  STORE: 'stores',
  POSTS: 'posts',
  CARDS: 'cards',
  CARS: 'cars',
  EMPLOYEE: 'employee',
  CV: 'CV',
  CONTACTS: 'contacts',
  QRCODE: 'QRCode',
};

export default features;
