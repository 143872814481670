import React from 'react';
import FeaturesForm from './FeaturesForm';

const EditFeatures = () => {
  return (
    <div>
      <FeaturesForm />
    </div>
  );
};

export default EditFeatures;
