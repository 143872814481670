import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/ui/Button';
import { usePurchasePlanMutation } from '../../redux/features/subscriptions/subscriptionsApi';
import { capitalizeFirstLetter } from '../../utils/dataFormater';

const PlanComparisonPage = ({ data, subscriptionId, subscribedPlanName }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsView = 'plans';
  // searchParams.get('view');
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [purchasePlan, { isLoading, isSuccess, isError }] =
    usePurchasePlanMutation();
  // function calculateNextDate(daysToAdd) {
  //   const currentDate = new Date(); // Get the current date
  //   const resultDate = new Date(currentDate); // Clone the current date
  //   console.log('dte----------------------------');
  //   console.log(currentDate);
  //   console.log('dte----------------------------');

  //   resultDate.setDate(currentDate + daysToAdd); // Add the days to the cloned date
  //   console.log('<<<<<<<<<<<<<<object>>>>>>>>>>>>>>');
  //   console.log(resultDate.setDate);
  //   return resultDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  // }
  const handelFreePlan = async (planId) => {
    const data = {
      subscriptionId: planId,
      cardType: 'Visa',
      cvv: '123',
      expiryDate: '12/12/2025',
      cardNumber: '4242424242424242',
      cardHolderName: 'John Doe',
      address: '123, Main Street',
    };
    const response = await purchasePlan(data);

    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate('/subscription/history');
    } else {
      toast.error(response?.error?.data?.message);
    }
  };

  function calculateNextDate(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }
  const todayDate = new Date();
  const newDate = calculateNextDate(todayDate, 60);
  function canNavigate(plan, findSubscribedPlan) {
    const planFeatureMap = Object.fromEntries(
      plan.features.map((f) => [f.featureName, f.quantity])
    );

    const subscribedFeatureMap = Object.fromEntries(
      findSubscribedPlan.features.map((f) => [f.featureName, f.quantity])
    );

    // Validate the condition for every feature in plan.features
    return plan.features.every(({ featureName }) => {
      return (
        subscribedFeatureMap[featureName] !== undefined &&
        planFeatureMap[featureName] < subscribedFeatureMap[featureName]
      );
    });
  }
  const findSubscribedPlan = data?.find((plan) => plan?._id === subscriptionId);

  // const handleSubscription = (plan) => {
  //   if (plan?.planName.toLowerCase() === 'free' || plan?.planPrice == 0) {
  //     return handelFreePlan(plan?._id);
  //   } else if (
  //     findSubscribedPlan &&
  //     findSubscribedPlan?.planName.toLowerCase() !== 'free' &&
  //     findSubscribedPlan?.planPrice !== 0
  //   ) {
  //     return toast.error('You are already subscribed to the plan');
  //   }
  //   {
  //     return navigate('/plans/billing', { state: { plan: plan } });
  //   }
  // };
  const handleSubscription = (plan) => {
    // Check if the user is trying to subscribe to a 'free' plan or a plan with price 0
    if (plan?.planName.toLowerCase() === 'free' || plan?.planPrice == 0) {
      return handelFreePlan(plan?._id);
    }

    // Allow user to subscribe to other plans regardless of their existing subscriptions
    // This check is for preventing subscribing to the same plan again, if needed
    if (findSubscribedPlan && findSubscribedPlan?.planName.toLowerCase() !== plan?.planName.toLowerCase()) {
      return navigate('/plans/billing', { state: { plan: plan } });
    }

    // In case no previous subscription or some edge cases
    return navigate('/plans/billing', { state: { plan: plan } });
  };

  const addOnSubscriptions = data?.filter((plan) => plan.isAddsOn === true);
  const regularSubscriptions = data?.filter((plan) => plan.isAddsOn === false);

  return (

      <div className="mt-4 flex flex-wrap justify-center gap-5">
        <div className="w-full">
          <h2 className="text-2xl font-bold text-gray-600 ">Subscriptions</h2>
          <h2 className="text-[12px] mb-4">You can buy only one plan for this user</h2>
          <div className="flex flex-wrap justify-center gap-5 text-gray-600">
            {regularSubscriptions?.map((plan) => (
                <div
                    key={plan._id}
                    className={`w-[400px] flex-none rounded border-2 border-[#1e1e2d] bg-gray-100 hover:bg-gray-200 transition duration-300 
                 ${selectedColumn === plan._id ? 'border-[#334155]' : 'border-[#1e1e2d]'} 
                 hover:border-[#334155] flex flex-col justify-between`}
                    onClick={() => setSelectedColumn(plan._id)}
                >
                  <div
                      className="h-[67px] w-[400px]  mt-8 flex flex-col items-center justify-center text-center space-y-2 ">
                    <p className="text-[18px] capitalize text-white font-semibold border-2 bg-[#1e1e2d]  rounded-md px-2 py-1">
                      {plan.planName}
                    </p>
                    <div className="flex items-center text-[#22C55E] justify-center ">
                      <h3 className="text-[25px] font-semibold">
                        {plan.planPrice}{' '}
                        <span className="text-[24px] font-semibold">{plan.currency}</span>
                      </h3>
                    </div>
                    <p className="text-[15px] text-[#1e1e2d] font-semibold">{plan.duration} Days</p>
                  </div>

                  <div className=" border-b px-2 mt-6 mr-4 ml-4 border-[#1e1e2d]"></div>
                  {/*<div className="w-[400px] flex-grow ">*/}
                  {/*  <ul className="ml-8 mt-6 mr-8 list-none space-y-4 text-[16px]">*/}
                  {/*    {plan.features.map((feature, index) => (*/}
                  {/*        <li key={index} className="flex items-center  border-gray-300 pb-2">*/}
                  {/*          <p className="font-bold">{capitalizeFirstLetter(feature.featureName)} :</p>*/}
                  {/*          <p className="text-md text-gray-600">{feature.quantity}</p>*/}
                  {/*        </li>*/}
                  {/*    ))}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                  <div className="w-[400px] flex-grow">
                    <ul className="ml-8 mt-6 mr-8 list-none space-y-4 text-[16px]">
                      {plan.features.map((feature, index) => (
                          <li key={index} className="flex justify-between items-center pb-2">
                            <p className="font-bold w-2/3 truncate">{capitalizeFirstLetter(feature.featureName)}:</p>
                            <p className="text-md text-gray-600 text-right w-1/3">{feature.quantity}</p>
                          </li>
                      ))}
                    </ul>
                  </div>


                  <button
                      className="ml-2 mt-4 mb-4 h-[40px] w-[95%] bg-[#1e1e2d] rounded text-lg text-white  transition duration-300"
                      onClick={() => handleSubscription(plan)}
                  >
                    {plan._id === subscriptionId ? 'Subscribed Plan' : 'Choose Plan'}
                  </button>
                </div>
            ))}
          </div>
        </div>


        <div className="w-full">
          <h2 className="text-2xl font-bold text-gray-600 mt-4 ">Adds-on Subscriptions</h2>
          <h2 className="text-[12px] mb-4">You can buy multiple Adds-on plan for this user</h2>

          <div className="flex flex-wrap justify-center text-gray-600 gap-5">
            {addOnSubscriptions?.map((plan) => (
                <div
                    key={plan._id}
                    className={`w-[400px] flex-none rounded border-2 border-[#1e1e2d] bg-gray-100 hover:bg-gray-200 transition duration-300 
                 ${selectedColumn === plan._id ? 'border-[#334155]' : 'border-[#1e1e2d]'} 
                 hover:border-[#334155] flex flex-col justify-between`}
                    onClick={() => setSelectedColumn(plan._id)}
                >
                  <div
                      className="h-[67px] w-[400px]  mt-8 flex flex-col items-center justify-center text-center space-y-2 ">
                    <p className="text-[18px] capitalize text-white font-semibold border-2 bg-[#1e1e2d]  rounded-md px-2 py-1">
                      {plan.planName}
                    </p>
                    <div className="flex items-center text-[#22C55E] justify-center ">
                      <h3 className="text-[25px] font-semibold">
                        {plan.planPrice}{' '}
                        <span className="text-[24px] font-semibold">{plan.currency}</span>
                      </h3>
                    </div>
                    <p className="text-[15px] text-[#1e1e2d] font-semibold">{plan.duration} Days</p>
                  </div>

                  <div className=" border-b px-2 mt-6 mr-4 ml-4 border-[#1e1e2d]"></div>
                  {/*<div className="w-[400px] flex-grow ">*/}
                  {/*  <ul className="ml-8 mt-6 mr-8 list-none space-y-4 text-[16px]">*/}
                  {/*    {plan.features.map((feature, index) => (*/}
                  {/*        <li key={index} className="flex items-center  border-gray-300 pb-2">*/}
                  {/*          <p className="font-bold">{capitalizeFirstLetter(feature.featureName)} :</p>*/}
                  {/*          <p className="text-md text-gray-600">{feature.quantity}</p>*/}
                  {/*        </li>*/}
                  {/*    ))}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}

                  <div className="w-[400px] flex-grow">
                    <ul className="ml-8 mt-6 mr-8 list-none space-y-4 text-[16px]">
                      {plan.features.map((feature, index) => (
                          <li key={index} className="flex justify-between items-center border-b border-gray-300 pb-2">
                            <p className="font-bold w-2/3 truncate">{capitalizeFirstLetter(feature.featureName)}:</p>
                            <p className="text-md text-gray-600 text-right w-1/3">{feature.quantity}</p>
                          </li>
                      ))}
                    </ul>
                  </div>


                  <button
                      className="ml-2 mt-4 mb-4 h-[40px] w-[95%] bg-[#1e1e2d] rounded text-lg text-white  transition duration-300"
                      onClick={() => handleSubscription(plan)}
                  >
                    {plan._id === subscriptionId ? 'Subscribed Plan' : 'Choose Plan'}
                  </button>
                </div>
            ))}
          </div>

        </div>
      </div>
  );
};

export default PlanComparisonPage;
