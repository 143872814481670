import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaCopy,
  FaFacebook,
  FaFacebookF,
  FaPhone,
  FaShare,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { FaHeart, FaRegHeart } from 'react-icons/fa6';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import DialogModal from '../../../components/ui/DialogModal';
import {
  useEditStoreFavoriteStatusMutation,
  useGetStoreFavoriteStatusQuery,
  usePostStoreFavoriteStatusMutation,
} from '../../../redux/features/stores/storesApi';

const StoreSection = ({ data, storeId, buyerEmail, isPublic }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const paramsIsChildBranch = searchParams.get('isChildBranch');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const urlToCopy = window.location.href;

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        urlToCopy
      )}`,
    },
    {
      icon: <MdEmail />,
      color: '#EA4335',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(urlToCopy)}`,
    },
    {
      icon: <FaFacebook />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        urlToCopy
      )}`,
    },
  ];

  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);

  const handleCopyLink = () => {
    navigator.clipboard?.writeText(urlToCopy);
    toast.success('Link copied!');
  };

  // apis
  const {
    data: isFavoriteData,
    refetch: refetchFavoriteStatus,
    error: isFavoriteError,
  } = useGetStoreFavoriteStatusQuery(
    {
      email: buyerEmail,
      storeId: storeId,
    },
    { skip: !buyerEmail }
  );

  const [postStoreFavoriteStatus] = usePostStoreFavoriteStatusMutation();

  const [editStoreFavoriteStatus, { isLoading: isLadingUpdate }] =
    useEditStoreFavoriteStatusMutation();
  // editStoreFavoriteStatus({ email: buyerEmail, storeId: storeId, favourite: true });

  const { facebook_url, youtube_url } = data?.social_media_links || {};

  const { fullNumber } = data?.phone || {};
  const { whatsapp_num } = data || {};

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    // check if isFavoriteData.data is empty array the make it false
    if (isFavoriteError?.data?.message === 'No favourite store Exit') {
      setIsFavorite(false);
    } else if (isFavoriteData?.success) {
      setIsFavorite(isFavoriteData?.data?.favourite);
    }
  }, [isFavoriteData, pathname]);

  const toggleFavorite = async () => {
    let postFavoriteStatusResponse;
    const statusPayload = {
      userEmail: buyerEmail,
      storeId: storeId,
      favourite: !isFavorite,
    };
    try {
      if (isFavoriteError?.data?.message === 'No favourite store Exit') {
        postFavoriteStatusResponse = await postStoreFavoriteStatus(
          statusPayload
        );
      } else {
        postFavoriteStatusResponse = await editStoreFavoriteStatus(
          statusPayload
        );
      }

      if (postFavoriteStatusResponse?.data?.success) {
        refetchFavoriteStatus();
      }
    } catch (error) {
      toast.error('Something went wrong unable to add store to favorite');
    }
  };
  const token = localStorage.getItem('token_link_dashboard');
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3
          style={{ color: data?.color }}
          className="text-center text-[28px] capitalize"
        >
          {data?.store_contact_information?.store_name}
        </h3>
        <img
          className="h-[400px] w-full rounded-lg border"
          src={data?.store_cover}
          alt="cover"
        />
        <p className="text-center">{data?.store_description}</p>
        <Button
          className="mx-auto w-44 "
          // style={{ backgroundColor: data?.color }}
          link={`/user/view/${data?.store_contact_information?.branchId}/?userId=${data?.user}&&isChildBranch=${paramsIsChildBranch}`}
        >
          {t('viewCompanyProfile')}
        </Button>
      </div>
      <div className="flex items-center justify-center gap-3">
        {fullNumber && (
          <Link
            to={fullNumber}
            target="_blank"
            style={{ color: data?.color }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <FaPhone size={16} />
          </Link>
        )}

        {whatsapp_num && (
          <Link
            to={whatsapp_num}
            target="_blank"
            style={{ color: 'green' }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <IoLogoWhatsapp size={16} />
          </Link>
        )}
        {facebook_url && (
          <Link
            to={facebook_url}
            target="_blank"
            style={{ color: 'blue' }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <FaFacebookF size={16} />
          </Link>
        )}
        {youtube_url && (
          <Link
            to={youtube_url}
            target="_blank"
            style={{ color: 'red' }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <FaYoutube size={16} />
          </Link>
        )}
        {/* add ha heart button to add the store in favirout */}

        {buyerEmail && (
          <button
            onClick={toggleFavorite}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
          </button>
        )}

        {isPublic && (
          <button
            className="flex h-[40px] w-[40px] items-center justify-center rounded border"
            onClick={onOpenModal}
          >
            <FaShare size={16} color="#ADFF2F" />
          </button>
        )}

        {/* Share Modal */}
        {isOpenModal && (
          <DialogModal onClose={onCloseModal} title="Share Store" width="500">
            <div className="flex flex-col items-center gap-4 py-8">
              {/* Social Media Share Buttons */}
              <div className="flex flex-wrap gap-4">
                {shareButtons.map((button) => (
                  <button
                    key={button.platform}
                    onClick={() => window.open(button.url, '_blank')}
                    className="rounded-full p-2 transition-transform hover:scale-110"
                    style={{ backgroundColor: button.color }}
                  >
                    {React.cloneElement(button.icon, {
                      color: 'white',
                      size: 24,
                    })}
                  </button>
                ))}
              </div>

              {/* Copy Link */}
              <input
                type="text"
                value={urlToCopy}
                readOnly
                className="w-full rounded-md border p-2 text-center"
                style={{ maxWidth: '400px' }}
              />
              <button
                onClick={handleCopyLink}
                className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
              >
                <FaCopy size={16} />
                Copy Link
              </button>
            </div>
            <div className="flex justify-end pt-3">
              <button
                className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                onClick={onCloseModal}
              >
                Close
              </button>
            </div>
          </DialogModal>
        )}
      </div>
    </div>
  );
};

export default StoreSection;
