import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import {  ChevronDown, Settings, LogOut } from 'lucide-react';
import { jwtDecode } from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import { SHOPE_URL } from '../constants/constants';
import turkeyFlag from '../images/logo/turkey.png';
import usFlag from '../images/logo/us.png';
import {
  useGetMyProfileQuery,
  useLogoutMutation,
} from '../redux/features/auth/authApi';

const DropdownUser = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = useState(false);
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const [user, setUser] = useState(null);
  const [searchParams] = useSearchParams();
  const params_logout_admin = searchParams.get('logout_admin');

  // const user = useSelector((state) => state.auth.user);
  const { data: profileData } = useGetMyProfileQuery();
  // close on click outside
  useEffect(() => {
    let token = localStorage.getItem('token_link_dashboard');
    if (token) {
      const decoded = jwtDecode(token);
      setUser(decoded);
      localStorage.setItem('userId', decoded._id);
    }

    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, []);

  const [logoutUser, { isSuccess }] = useLogoutMutation();

  useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem('token_link_dashboard');
      localStorage.removeItem('userId');
      localStorage.removeItem('buyerEmail');
      // navigate('/login');
      window.location.href = '/login';
    }
  }, [isSuccess]);

  const handleLogOut = async () => {
    await logoutUser({ userId: user?._id });
    // logout();
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
      setIsLanguagePopupOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (params_logout_admin) {
      handleLogOut();
      window.location.href = `${SHOPE_URL}`;
    }
  }, [params_logout_admin]);

  return (
    <div className="relative">
      <Link
        ref={trigger}
        // onClick={() => setDropdownOpen(!dropdownOpen)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="flex items-center gap-3"
        to="#"
      >
        {/* <span className="hidden text-right lg:block">
          <span className="block text-sm font-medium text-black dark:text-white">
            Hayatul
          </span>
          <span className="block text-xs">Admin</span>
        </span> */}

        <span className="h-10 w-10 rounded-full md:h-12 md:w-12">
          <img
            className="h-full w-full rounded-full"
            src={user?.avatar}
            // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
            alt="User"
          />
        </span>
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        // onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`dark:border-strokedark dark:bg-boxdark absolute right-0 mt-4 flex w-[368px] flex-col border-0 ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <div className="w-full rounded-lg bg-white p-4 shadow-md">
          <div className="mb-6 flex items-center">
            <div className="mr-3 flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
              {/* <User className="text-gray-500" size={24} /> */}
              <img
                className="h-full w-full rounded-full"
                // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                src={user?.avatar}
                alt="User"
              />
            </div>
            <div>
              <h2 className="font-bold text-black">{user?.fullName}</h2>
              <p className="text-sm text-gray-500">{user?.email}</p>
            </div>
            <span className="ml-auto rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-500">
              Pro
            </span>
          </div>

          <div className="space-y-1">
            <Link
              to={`/users/edit/${user?._id}`}
              className="flex w-full items-center rounded-md p-3 text-gray-700 hover:bg-white"
            >
              My Profile
            </Link>
            <div className="relative">
              <button
                className="flex w-full items-center justify-between rounded-md bg-white p-3 shadow-sm"
                onClick={() => setIsLanguagePopupOpen(!isLanguagePopupOpen)}
              >
                <div className="flex items-center">
                  <span className="text-black">Language</span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1 text-sm text-gray-700">EN</span>
                  <img
                    src={usFlag}
                    alt="US Flag"
                    className="mr-1 h-4 w-4 rounded-full"
                  />
                  {/* <ChevronDown size={16} className="text-gray-500" /> */}
                </div>
              </button>

              {isLanguagePopupOpen && (
                <div className="lg:-top-1/3/4 absolute left-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg md:-left-[105%] lg:-translate-y-1/2">
                  <div className="p-2">
                    <button className="flex w-full items-center rounded p-2 hover:bg-gray-100">
                      <img
                        src={usFlag}
                        alt="US Flag"
                        className="mr-2 h-6 w-6 rounded-full"
                      />
                      <span className="text-blue-500">English</span>
                    </button>
                    <button className="flex w-full items-center rounded p-2 hover:bg-gray-100">
                      <img
                        src={turkeyFlag}
                        alt="Turkish Flag"
                        className="mr-2 h-6 w-6 rounded-full"
                      />
                      <span>Turkish</span>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <Link
              to="/settings"
              className="flex w-full items-center rounded-md p-3 text-gray-700 hover:bg-white"
            >
              {/* <Settings size={18} className="mr-2" /> */}
              Account Settings
            </Link>

            <button
              className="flex w-full items-center rounded-md p-3 text-gray-700 hover:bg-white"
              onClick={handleLogOut}
            >
              {/* <LogOut size={18} className="mr-2" /> */}
              Sign Out
            </button>
          </div>
        </div>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;
