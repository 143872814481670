import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { useGetSubscriptionQuery } from '../../../../redux/features/subscriptions/subscriptionsApi';
import SubscriptionForm from './SubscriptionForm';

const EditSubscriptionPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSubscriptionQuery(id);

  const subscriptionData = {
    _id: data?.data?._id,
    planName: data?.data?.planName,
    subscriptionCategory:
      (data?.data?.isIndividual && data.data.isBusiness && 'both') ||
      (data?.data?.isIndividual && 'individual') ||
      (data?.data?.isBusiness && 'business'),
    quantity: data?.data?.quantity,
    planPrice: data?.data?.planPrice,
    duration: data?.data?.duration,
    planStatus: data?.data?.isTrail ? 'trail' : 'active',
    features: data?.data?.features,
    currency: data?.data?.currency,
    isAddsOn: data?.data?.isAddsOn ? 'yes' : 'no',
  };
  return (
    <>
      {isLoading ? (
        <Loading height="80vh" />
      ) : (
        <SubscriptionForm data={subscriptionData} />
      )}
    </>
  );
};

export default EditSubscriptionPage;
