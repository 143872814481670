import { useState } from 'react';
import { FaArrowsAlt, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FaClone } from 'react-icons/fa6';

export const EducationInformation = ({
  allEducationData,
  setAllEducationData,
}) => {
  const [educationInformationData, setEducationInformationData] = useState({
    fieldOfStudy: '',
    instituteName: '',
    startDate: '',
    endDate: '',
    location: '',
    description: '',
  });

  const [showForm, setShowForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducationInformationData({ ...educationInformationData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAllEducationData((prevData) => [...prevData, educationInformationData]);
    setShowForm(false);
    setEducationInformationData({
      fieldOfStudy: '',
      instituteName: '',
      startDate: '',
      endDate: '',
      location: '',
      description: '',
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="font-bold">Education</div>
        <button
          type="button"
          onClick={() => setShowForm(!showForm)}
          className="rounded bg-green-500 px-2 text-white hover:bg-green-600"
        >
          {showForm ? <span className="px-[2px]">-</span> : '+'}
        </button>
      </div>
      {showForm ? (
        <>
          <div>
            <label htmlFor="fieldOfStudy">Field of Study</label>
            <input
              type="text"
              name="fieldOfStudy"
              placeholder="Your field of study"
              value={educationInformationData?.fieldOfStudy}
              onChange={handleChange}
              className="w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="instituteName">School Name or University</label>
            <input
              type="text"
              name="instituteName"
              placeholder="Name of university or school"
              value={educationInformationData?.instituteName}
              onChange={handleChange}
              className="w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={educationInformationData?.startDate}
              onChange={handleChange}
              className="w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              name="endDate"
              value={educationInformationData?.endDate}
              onChange={handleChange}
              className="w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="location">City, Country</label>
            <input
              type="text"
              name="location"
              placeholder="Where your institute is situated"
              value={educationInformationData?.location}
              onChange={handleChange}
              className="w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              placeholder="Describe your role and achievements"
              value={educationInformationData?.description}
              onChange={handleChange}
              className="mb-4 w-full rounded border p-2"
              required
            />
          </div>
          <button
            className="mt-4 w-full rounded bg-blue-500 p-2 text-white hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Add Education
          </button>
        </>
      ) : (
        <div>
          {allEducationData?.map((educationData, index) => (
            <div
              key={index}
              className="flex items-center justify-between rounded border p-4 shadow-md hover:shadow-lg"
            >
              <div>
                <h3 className="font-semibold text-blue-500">
                  {educationData?.fieldOfStudy}
                </h3>
                <p className="text-gray-600">{educationData?.instituteName}</p>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  className="text-yellow-500 hover:text-yellow-600"
                  title="Edit"
                >
                  <FaEdit />
                </button>
                <button
                  className="text-gray-500 hover:text-gray-600"
                  title="Duplicate"
                >
                  <FaClone />
                </button>
                <button
                  className="text-red-500 hover:text-red-600"
                  title="Delete"
                >
                  <FaTrashAlt />
                </button>
                <button
                  className="cursor-move text-blue-500 hover:text-blue-600"
                  title="Drag"
                >
                  <FaArrowsAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
