import React from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../components/Table';
import Card from '../../components/ui/Card';
import { useGetFeatureUsageQuery } from '../../redux/features/subscriptions/subscriptionsApi';
import { capitalizeFirstLetter } from '../../utils/dataFormater';

const FeaturesOfSingleSubscription = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetFeatureUsageQuery();

  // filetr the object from currentPlanData.data array which hve _id equal to id
  const usageData = Array.isArray(data?.data)
    ? data.data.find((item) => item._id === id)
    : null;

  const usageContent = {
    header: ['Feature Name', 'Quantity', 'Usage', 'Remaining'],

    body: usageData?.features?.map((item) => ({
      rows: [
        { row: capitalizeFirstLetter(item?.featureName) },
        {
          row: item?.quantity,
        },
        {
          row: item?.userUsageCount,
        },
        {
          row: item?.quantity - item?.userUsageCount,
        },
      ],
    })),
  };
  return (
    <Card title="Subscription features">
      {/* <DebugView data={data.data} /> */}
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <Table content={usageContent} pagination={null} />
      )}
    </Card>
  );
};

export default FeaturesOfSingleSubscription;
