import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCvByIdQuery } from '../../../redux/features/cv/cvApi';
import CVTemplate3 from './CVTemplate3';
import CVTemplate from './CVTemplate';
import CVTemplate2 from './CVTemplate2';
import CVTemplate4 from "./CVTemplate4";
import CVTemplate5 from "./CVTemplate5";
import CVTemplate6 from "./CVTemplate6";


const TemplateView = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetCvByIdQuery(id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(
    data?.data?.templateId
  );
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    setCurrentTemplate(data?.data?.templateId);
  }, [data]);

  return (
    <div>
      <button
        type="button"
        className="inline-flex w-36 justify-between rounded-md border border-gray-300 bg-blue-950 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus:outline-none"
        onClick={toggleDropdown}
      >
        Template {currentTemplate}
      </button>
      {isDropdownOpen && (
        <div className="absolute z-10 mt-2 w-36 rounded-md bg-white shadow-lg">
          <ul className="py-1 text-sm text-gray-700">
            <li
                onClick={() => {
                  setCurrentTemplate(2);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                    currentTemplate == 2 ? 'bg-gray-200' : ''
                }`}
            >
              Template 2
            </li>
            <li
                onClick={() => {
                  setCurrentTemplate(3);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                    currentTemplate == 3 ? 'bg-gray-200' : ''
                }`}
            >
              Template 3
            </li>
            <li
                onClick={() => {
                  setCurrentTemplate(4);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                    currentTemplate == 4 ? 'bg-gray-200' : ''
                }`}
            >
              Template 4
            </li>
            <li
                onClick={() => {
                  setCurrentTemplate(5);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                    currentTemplate == 5 ? 'bg-gray-200' : ''
                }`}
            >
              Template 5
            </li>
            <li
                onClick={() => {
                  setCurrentTemplate(6);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${
                    currentTemplate == 6 ? 'bg-gray-200' : ''
                }`}
            >
              Template 6
            </li>
          </ul>
        </div>
      )}
      {currentTemplate == 3 && <CVTemplate2 data={data?.data}/>}
      {/* <CVTemplate data={data?.data} /> */}
      {currentTemplate == 2 && <CVTemplate3 data={data?.data}/>}
      {currentTemplate == 4 && <CVTemplate4 data={data?.data}/>}
      {currentTemplate == 5 && <CVTemplate5 data={data?.data}/>}
      {currentTemplate == 6 && <CVTemplate6 data={data?.data}/>}
    </div>
  );
};

export default TemplateView;
