import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa6';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';

const GuestOrderPage = () => {
  const [orders, setOrders] = useState([]);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedEmail = localStorage.getItem('guestEmail');
    const storedOrders = JSON.parse(localStorage.getItem('orders')) || [];

    if (storedEmail) {
      setEmail(storedEmail);
      const filteredOrders = storedOrders.filter(
        (order) => order.buyerEmail === storedEmail
      );
      setOrders(filteredOrders);
    }
    setIsLoading(false);
  }, []);
  console.log('thius one');
  const content = {
    header: [
      'User Email',
      'User Phone',
      'User Address',
      'Tracking ID',
      'Order Date',
      'Actions',
    ],
    body: orders.map((item) => ({
      rows: [
        { row: item.buyerEmail },
        { row: item.buyerPhoneNumber },
        { row: item.buyerAdress },
        { row: item.trackingOrderId },
        { row: new Date(item.createdAt).toLocaleDateString() },
        {
          row: (
            <Button
              link={`/my-order/${item._id}`}
              className="flex h-[40px] w-[60px] items-center justify-center !bg-blue-100"
            >
              <FaEye />
            </Button>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      <Card title="Orders List">
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <Table content={content} pagination={null} />
        )}
      </Card>
    </div>
  );
};

export default GuestOrderPage;
