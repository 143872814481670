import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CiGlobe } from 'react-icons/ci';
import { FaMobileAlt, FaPen, FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { HiMiniBuildingOffice } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';
import features from '../../../constants/fetures';
import { countryPhoneOptions } from '../../../data/data';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import { useGetCardsQuery } from '../../../redux/features/cards/cardsApi';
import {
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
} from '../../../redux/features/employees/employeesApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../../redux/features/subscriptions/subscriptionsApi';
import { formatBranchData } from '../../../utils/dataFormater';
import { getFeatureId } from '../../../utils/helper';
// import { useGetUserOptionsQuery } from '../../../redux/features/users/usersApi';

const EmployeeForm = ({ data = {} }) => {
  const [createEmployee] = useCreateEmployeeMutation();
  const [editEmployee] = useEditEmployeeMutation();
  const { data: cards } = useGetCardsQuery();
  const { data: profileData } = useGetMyProfileQuery();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();
  // const { data: userOptions, isLoading: optionsLoading } =
  //   useGetUserOptionsQuery();

  const [values, setValues] = useState(data);
  const navigate = useNavigate();
  // const onChange = (key, value) => {
  //   setValues((prev) => ({
  //     ...prev,
  //     [key]:
  //       key === 'phone'
  //         ? {
  //             fullNumber: value,
  //             dialCode: countryPhoneOptions.find(
  //               (item) => item.dialCode === value
  //             )?.dialCode,
  //           }
  //         : value,
  //   }));
  // };

  useEffect(() => {
    if (profileData) {
      setValues((prev) => ({
        ...prev,
        phone: {
          dialCode: data?.phone?.dialCode || profileData?.data?.phone?.dialCode,
          fullNumber: data?.phone?.fullNumber || '',
        },
        whatsapp: {
          dialCode:
            data?.whatsapp?.dialCode || profileData?.data?.phone?.dialCode,
          fullNumber: data?.whatsapp?.fullNumber || '',
        },
      }));
    }
  }, [profileData]);

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const API = data?._id
  //       ? editEmployee({ id: data?._id, data: values })
  //       : createEmployee(values);
  //     const result = await API;
  //     if (result?.data?.success) {
  //       toast.success(result?.data?.message);
  //       navigate('/cards/employees');
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message || error?.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.EMPLOYEE, featuresData?.data)
      );
      if (featureUsageResponse?.error) {
        toast.error(featureUsageResponse?.error?.data?.message);
        setTimeout(() => {
          navigate('/subscription/history');
        }, 1000);
        return;
      }

      const API = data?._id
        ? editEmployee({ id: data?._id, data: values })
        : createEmployee(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/cards/employees');
      } else if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message || 'An error occurred!');
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'An unexpected error occurred!'
      );
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* {JSON.stringify(cards?.data, null, 2)} */}
      <Card
        title={`${data?._id ? 'Edit' : 'Create'} Employee Profile`}
        content={
          <Link
            to="/cards/employees"
            className="rounded-lg bg-blue-600 px-3 py-2 text-[13px] text-white"
          >
            Employees List
          </Link>
        }
        className="space-y-6"
      >
        <div className="space-y-5 px-4 pt-2">
          <Card title="Personal Information" className="space-y-3">
            <div className="space-y-3">
              {values?.bannerBg && (
                <img
                  src={values?.bannerBg}
                  alt=""
                  className="h-[80px] w-[80px] rounded-full border"
                />
              )}
              <InputFile
                name="bannerBg"
                label="Profile Image"
                onChange={onChange}
              />
            </div>

            <div className="space-y-3">
              {values?.imgUrl && (
                <img
                  src={values?.imgUrl}
                  alt=""
                  className="h-[80px] w-[180px] rounded border object-cover"
                />
              )}
              <InputFile
                name="imgUrl"
                label="Banner Image"
                onChange={onChange}
                isBanner
              />
            </div>
            {/* <InputGroup
              icon={<FaPen size={14} />}
              name="name"
              placeholder="Employee Name"
              value={values?.name}
              onChange={onChange}
              isRequired={true}
            />
             */}
            <InputGroup
              icon={<FaPen size={14} />}
              name="firstName"
              placeholder="First Name"
              value={values?.firstName}
              onChange={onChange}
              isRequired={true}
            />

            <InputGroup
              icon={<FaPen size={14} />}
              name="lastName"
              placeholder="Last Name"
              value={values?.lastName}
              onChange={onChange}
              isRequired={true}
            />

            <InputGroup
              name="email"
              type="email"
              icon={<HiOutlineMailOpen size={14} />}
              placeholder="Email"
              value={values?.email}
              onChange={onChange}
              isRequired={true}
            />

            <SelectOptions
              icon={<CiGlobe />}
              placeholder="Select Branch..."
              options={[
                { label: 'All', value: 'all' },
                ...formatBranchData(cards?.data?.docs[0].userBusinessBranches),
              ]}
              name="branchId"
              value={values?.branchId}
              onChange={onChange}
              isRequired={true}
            />
            {/* <InputGroup
              name="phone"
              icon={<FaPhoneAlt size={14} />}
              placeholder="Phone Number"
              value={values?.phone?.fullNumber}
              onChange={onChange}
              isRequired={true}
            /> */}
            <div className=" flex w-full items-center ">
              <SelectOptions
                placeholder="Select Country..."
                options={countryPhoneOptions}
                name="phone"
                subKey="dialCode"
                value={values?.phone?.dialCode}
                onChange={onChange}
                className={`w-[140px]`}
              />
              <div className="w-full">
                <InputGroup
                  name="phone"
                  icon={<FaPhoneAlt size={14} />}
                  placeholder="Phone Number"
                  value={values?.phone?.fullNumber}
                  onChange={onChange}
                  isRequired={true}
                  subKey="fullNumber"
                  type="number"
                  disabled={!values?.phone?.dialCode}
                />
              </div>
            </div>

            <div className=" flex w-full items-center gap-3">
              <SelectOptions
                placeholder="Select Country..."
                options={countryPhoneOptions}
                name="whatsapp"
                subKey="dialCode"
                value={values?.whatsapp?.dialCode}
                onChange={onChange}
              />
              <div className="w-full">
                <InputGroup
                  icon={<FaMobileAlt />}
                  name="whatsapp"
                  subKey="fullNumber"
                  placeholder="Whatsapp Number"
                  type="number"
                  value={values?.whatsapp?.fullNumber}
                  onChange={onChange}
                />
              </div>
            </div>
          </Card>
          <Card title="Job Information" className="space-y-3">
            <InputGroup
              icon={<HiMiniBuildingOffice size={14} />}
              name="job_position"
              placeholder="Position"
              value={values?.job_position}
              onChange={onChange}
            />
          </Card>
          <Card title="Summery" className="space-y-3">
            <TextAreaField
              name="bio"
              placeholder="Please Enter Bio"
              value={values?.bio}
              onChange={onChange}
            />
          </Card>
          {/* {!optionsLoading && (
            <Card title="Select Member" className="space-y-3">
              <SelectGroups
                options={userOptions?.data}
                name="userId"
                value={values?.userId || values?.user?._id}
                placeholder="Select..."
                onChange={onChange}
                isRequired={true}
              />
            </Card>
          )} */}
        </div>
        <Button type="submit" className="bg-lime-400">
          Save Employee
        </Button>
      </Card>
    </form>
  );
};

export default EmployeeForm;
