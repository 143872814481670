import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import InputField from '../../../components/ui/InputField';
import { useCreateBrandMutation } from '../../../redux/features/brands/brandsApi';
import DefaultCardLayout from '../../agents/components/dashboard/DefaultCardLayout';

const CreateBrands = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [createBrand, { isLoading: brandCreateLoading }] =
    useCreateBrandMutation();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };
  const handleSaveCategories = async () => {
    try {
      let result = await createBrand(values);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setValues({});
        navigate('/admin/brands');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <DefaultCardLayout
        title={'Create Brand'}
        action={
          <div className="flex gap-3">
            <button
              className="rounded bg-[#009ef7] px-4 py-2 text-white"
              onClick={() => navigate('/admin/brands')}
            >
              Brand List
            </button>
          </div>
        }
      >
        <div className="gap-16 p-8">
          <div className="space-y-5">
            <InputField
              name={'name'}
              onChange={onChange}
              value={values?.name}
              type="text"
              placeholder={'Brand name'}
              isRequired={true}
            />
            <InputField
              name={'sortOrder'}
              onChange={onChange}
              value={values?.sortOrder}
              type="number"
              placeholder={'Sort order'}
              isRequired={true}
            />

            <Button
              className="mt-3 !bg-green-400"
              onClick={handleSaveCategories}
            >
              {brandCreateLoading ? <Loading height="24px" /> : 'Create'}
            </Button>
          </div>
        </div>
      </DefaultCardLayout>
    </>
  );
};

export default CreateBrands;
