import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
      <footer className="bg-[#1E314B] text-white p-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
          <div className="text-center md:text-left">
            <h2 className="font-bold text-3xl">{t('footerHeading')}</h2>
            <p className="mt-4 font-normal text-lg">{t('footerDescription')}</p>
          </div>

          <div className="text-center md:text-left">
            <h3 className="font-semibold text-2xl">{t('privacyPolicy')}</h3>
            <ul className="mt-4 text-sm space-y-2">
              <li><a href="" className="hover:underline">{t('personalData')}</a></li>
              <li><a href="" className="hover:underline">{t('gdprConsent')}</a></li>
              <li><a href="" className="hover:underline">{t('salesAgreement')}</a></li>
              <li><a href="" className="hover:underline">{t('membershipAgreement')}</a></li>
            </ul>
          </div>

          <div className="text-center md:text-left">
            <h3 className="font-semibold text-2xl">{t('links')}</h3>
            <ul className="mt-4 text-sm space-y-2">
              <li><a href="" className="hover:underline">{t('portfolio')}</a></li>
              <li><a href="" className="hover:underline">{t('products')}</a></li>
              <li><a href="" className="hover:underline">{t('faqs')}</a></li>
            </ul>
          </div>

          <div className="text-center md:text-left">
            <h3 className="font-semibold text-2xl">{t('contactUs')}</h3>
            <ul className="mt-4 text-sm">
              <li>+90 156738 378378</li>
            </ul>
          </div>
        </div>

        <div className="text-center mt-12">
          <p>{t('footerCopyright')}</p>
        </div>
      </footer>
  );
};

export default Footer;
