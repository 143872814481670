import React from 'react';
import LanguageSelector from "../../../../LanguageSelector";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  return (
      <nav className="bg-gray-100 shadow-md mt-4 border-b-2">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0">
              <h1 className="text-3xl font-bold text-green-600">Socalii</h1>
            </div>
            <div className="flex items-center ml-auto">

              <a
                  href="#login"
                  className="bg-green-500 text-black px-4 py-3 mr-4 rounded-md text-sm font-medium hover:bg-green-600"
              >
                {t('login.userLoginHeading')}
              </a>
            </div>


            <LanguageSelector />
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
