import React from 'react';
import FriendRequest from './friendsPage/FriendRequest';
import FriendYouHaveRequested from './friendsPage/FriendYouHaveRequested';
import TabsComponent from './friendsPage/TabsComponent';

const FriendsPage = () => {
  return (
    <>
      {/* <ExtractLatLng mapUrl="https://maps.app.goo.gl/iHESGpPLDi7fM1fRA" /> */}
      <TabsComponent />
      <br />
      <FriendRequest />
      <br />
      <FriendYouHaveRequested />
      {/* <br />
            <NearbyFriends /> */}
      <br />
      {/* <PeopleYouMayKnow /> */}
    </>
  );
};

export default FriendsPage;
