import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
// import Table from "../../../components/Table";
import Button from '../../../components/ui/Button';
// import Card from "../../../components/ui/Card";
import ComingSoon from '../../../components/ui/ComingSoon';
import { useGetInterestsQuery } from '../../../redux/features/interests/interestApi';

const InterestsPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useGetInterestsQuery({
    page: paginate?.page,
    limit: paginate?.limit,
  });

  const content = {
    header: ['Post', 'Name', 'Email', 'Phone', 'Message', 'Actions'],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.post,
        },
        {
          row: item?.name,
        },

        {
          row: item?.email,
        },
        {
          row: item?.phone,
        },
        {
          row: item?.message,
        },

        {
          row: (
            <div className="flex items-center gap-3">
              <Button className="!bg-red-400">
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      {/* <Card title="Messages List">
        <Table
          content={content}
          pagination={data?.data?.paginate}
          onPaginate={setPaginate}
        />
      </Card> */}
      <ComingSoon />
    </div>
  );
};

export default InterestsPage;
