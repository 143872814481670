import React, { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useGetMyProfileQuery } from '../../redux/features/auth/authApi';
import { useGetBranchesByUserIdQuery } from '../../redux/features/subscriptions/subscriptionsApi';
import {
  useGetUserProfileQuery,
  useGetUserQuery,
} from '../../redux/features/users/usersApi';
import Loading from '../Loading';
import SocialMetaTags from '../SocialMetaTags';
import CardClassicView from '../ui/CardClassicView';
import CardView from '../ui/CardView';

const UserViewPage = () => {
  const [searchParams] = useSearchParams();
  const pramsBusinessType = searchParams.get('type');
  const paramsUserRole = searchParams.get('role');
  const locationC = useLocation();
  const location = locationC?.pathname;
  const pathSegments = location?.split('/');
  const paramsUserName = pathSegments[pathSegments.length - 2];
  const branchId = pathSegments[pathSegments.length - 1];
  const { data: profileData } = useGetMyProfileQuery();
  const { id } = useParams();
  const { data: userPublicData } = useGetUserProfileQuery(paramsUserName);

  const { data: branchesData, isLoading: branchesLoading } =
    useGetBranchesByUserIdQuery(paramsUserName);

  const {
    data: cardData,
    isLoading,
    refetch,
  } = useGetUserQuery([paramsUserName, branchId]);

  const phone = `${cardData?.data?.phone?.dialCode ?? ''} ${
    cardData?.data?.phone?.fullNumber ?? ''
  }`;

  const whatsapp = `${cardData?.data?.whatsapp?.dialCode ?? ''} ${
    cardData?.data?.whatsapp?.fullNumber ?? ''
  }`;

  const data = {
    actualName: `${cardData?.data?.firstName || ''} ${
      cardData?.data?.lastName || ''
    }`,
    businessName: cardData?.data?.card?.businessName,
    image: cardData?.data?.avatar,
    bannerImg: cardData?.data?.bannerBg,
    // position: `${
    //   (cardData?.data?.position || cardData?.data?.jobInformation?.position) ??
    //   ''
    // }`,
    slogan: cardData?.data?.card?.slogan,
    //education: cardData?.data?.education,
    // skills: cardData?.data?.skills,
    // address: cardData?.data?.address,
    email: cardData?.data?.email,
    phone: `${phone !== ' ' ? phone : ''}`,
    // additionalPhones: cardData?.data?.additionalPhones,
    whatsapp: `${whatsapp !== ' ' ? whatsapp : ''}`,
    // fax: cardData?.data?.user?.faxNumber,
    bio: cardData?.data?.user?.bio,
    social: cardData?.data?.socialMediaLinks,
    additionalSocialLinks: cardData?.data?.additionalSocialLinks,
    youtubeVideo: cardData?.data?.socialMediaLinks?.videoLink,
    youtubeChannel: cardData?.data?.socialMediaLinks?.youtube,

    bankAccounts: cardData?.data?.bankAccounts,
    store: cardData?.data?.store,
    location: {
      latitude: cardData?.data?.location?.lat,
      longitude: cardData?.data?.location?.long,
    },
    address: cardData?.data?.address,
  };

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div>
      <SocialMetaTags
        title={cardData?.data?.businessName}
        description={cardData?.data?.businessName}
        image={cardData?.data?.bannerBg}
        url={`${cardData?.data?.card?.url}`}
      />
      {isLoading ? (
        <Loading />
      ) : cardData?.data?.userMode === 'classic' ? (
        <CardClassicView
          // data={data}
          link={`/card/edit/${id}`}
          variant="user"
          pramsBusinessType={cardData?.data?.role}
          isPublic={true}
          userId={paramsUserName}
        />
      ) : (
        <CardView
          data={data}
          link={`/card/edit/${id}`}
          variant="user"
          pramsBusinessType={cardData?.data?.role}
          isPublic={true}
          userId={paramsUserName}
          branchesData={branchesData}
        />
      )}
    </div>
  );
};

export default UserViewPage;
