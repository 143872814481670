import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import {
  useAssignSubscriptionToUserMutation,
  useDeleteSubscriptionMutation,
  useGetAllPlansOfAdminQuery,
} from '../../../../redux/features/subscriptions/subscriptionsApi';
import {capitalizeFirstLetter} from "../../../../utils/dataFormater";

const SubscriptionsPage = () => {
  const [searchParams] = useSearchParams();
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const { data, isLoading, refetch } = useGetAllPlansOfAdminQuery();
  const [assignSubscriptionToUser, { isLoading: isAssigningSubscription }] =
    useAssignSubscriptionToUserMutation();

  const location = useLocation();
  const paramsUserId = searchParams.get('userId');
  const handleDeleteSubscription = async (id) => {
    try {
      const result = await deleteSubscription(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        refetch();
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this business type',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteSubscription(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const handleAssignSubscription = async (subScriptionId) => {
    const payLoad = {
      cardType: '',
      cardNumber: '',
      cvv: '',
      cardHolderName: '',
      subscriptionId: subScriptionId,
      address: '',
      expiryDate: '',
      userId: paramsUserId,
      isFreeForUser: true,
    };
    try {
      const result = await assignSubscriptionToUser(payLoad);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const addOnSubscriptions = data?.data.filter((item) => item.isAddsOn === true);
  console.log(addOnSubscriptions,"AddsON...............");
  const regularSubscriptions = data?.data.filter((item) => item.isAddsOn === false);
  console.log(regularSubscriptions,"Regular Subscriptions..................");


  return (
    <>
      <div className="space-y-4">
        <Card
            title="Subscriptions"
            content={
              <Button
                  link="/admin/subscriptions/create"
                  className="flex items-center gap-2 !bg-green-500 hover:!bg-green-600"
              >
                <FaPlus/> Add New Subscription
              </Button>
            }
        >
          {isLoading ? (
              <Loading/>
          ) : (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {regularSubscriptions.map((item) => (
                    <div
                        key={item.id}
                        className="flex flex-col gap-3 rounded border-2 border-[#1e1e2d] bg-white p-4 shadow-lg hover:shadow-xl transition-shadow"
                    >
                      <div className="flex justify-between bg-[#1e1e2d] rounded-lg p-4">
                        <h2 className=" text-lg font-bold capitalize text-white">
                          {item.duration} Days
                        </h2>
                        <h2 className=" text-lg font-bold text-white capitalize">
                          {item.planName}
                        </h2>
                        <h2 className=" text-lg capitalize font-semibold text-[#22C55E]">
                          {item.planPrice} {item.currency}
                        </h2>
                      </div>

                      <p className="text-md text-blue-950">
                        <strong>Type:</strong>{' '}
                        {item.isIndividual && item.isBusiness
                            ? 'Both'
                            : item.isBusiness
                                ? 'Business'
                                : 'Individual'}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Price:</strong> {item.planPrice} {item.currency}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Status:</strong> {item.isActive ? 'Active' : 'Trail'}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Duration:</strong> {item.duration} Days
                      </p>

                      <div className="mt-2 text-md text-blue-950">
                        <strong>Features:</strong>
                        <div className="grid grid-cols-2 gap-x-4 pl-4">
                          {item.features.map((feature) => (
                              <div key={feature} className="text-gray-700 capitalize">
                                {/*{feature?.featureName} - {feature.quantity}*/}
                                <p className="font-bold w-2/4 truncate">{capitalizeFirstLetter(feature.featureName)}:</p>
                                <p className="text-md text-gray-600 w-2/4">{feature.quantity}</p>
                              </div>
                          ))}
                        </div>
                      </div>


                      <div className="mt-auto flex items-center gap-3">
                        <Button
                            link={`/admin/subscriptions/edit/${item._id}`}
                            className="!bg-green-500 py-3"
                        >
                          <FaPenToSquare/>
                        </Button>
                        <Button
                            onClick={() => handleDelete(item._id)}
                            className="!bg-red-500 py-3"
                        >
                          <MdDelete/>
                        </Button>
                        {paramsUserId && (
                            <Button
                                // link={`/admin/subscriptions/assign/${item._id}?userId=${paramsUserId}`}
                                onClick={() => handleAssignSubscription(item._id)}
                                className="!bg-blue-500"
                            >
                              Assign
                            </Button>
                        )}
                      </div>
                    </div>
                ))}
              </div>
          )}
        </Card>

        <Card title="Adds-on">
          {isLoading ? (
              <Loading/>
          ) : (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {addOnSubscriptions.map((item) => (
                    <div
                        key={item.id}
                        className="flex flex-col gap-3 rounded border-2 border-[#1e1e2d] bg-white p-4 shadow-lg hover:shadow-xl transition-shadow"
                    >
                      <div className="flex justify-between bg-[#1e1e2d] rounded-lg shadow-md p-4">
                        <h2 className=" text-lg font-bold capitalize text-white">
                          {item.duration} Days
                        </h2>
                        <h2 className=" text-lg font-bold text-white capitalize">
                          {item.planName}
                        </h2>
                        <h2 className=" text-lg capitalize font-semibold text-[#22C55E]">
                          {item.planPrice} {item.currency}
                        </h2>
                      </div>

                      <p className="text-md text-blue-950">
                        <strong>Type:</strong>{' '}
                        {item.isIndividual && item.isBusiness
                            ? 'Both'
                            : item.isBusiness
                                ? 'Business'
                                : 'Individual'}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Price:</strong> {item.planPrice} {item.currency}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Status:</strong> {item.isActive ? 'Active' : 'Trail'}
                      </p>
                      <p className="text-md text-blue-950">
                        <strong>Duration:</strong> {item.duration} Days
                      </p>

                      <div className="mt-2 text-md text-blue-950">
                        <strong>Features:</strong>
                        <div className="grid grid-cols-2 gap-x-4 pl-4">
                          {item.features.map((feature) => (
                              <div key={feature} className="text-gray-700 capitalize">
                                {feature?.featureName} - {feature.quantity}
                              </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <Button
                            link={`/admin/subscriptions/edit/${item._id}`}
                            className="!bg-green-500 py-3"
                        >
                          <FaPenToSquare/>
                        </Button>
                        <Button
                            onClick={() => handleDelete(item._id)}
                            className="!bg-red-500 py-3"
                        >
                          <MdDelete/>
                        </Button>
                        {paramsUserId && (
                            <Button
                                // link={`/admin/subscriptions/assign/${item._id}?userId=${paramsUserId}`}
                                onClick={() => handleAssignSubscription(item._id)}
                                className="!bg-blue-500"
                            >
                              Assign
                            </Button>
                        )}
                      </div>
                    </div>
                ))}
              </div>
          )}
        </Card>
      </div>

    </>
  );
};

export default SubscriptionsPage;
