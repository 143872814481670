import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { GoPencil } from 'react-icons/go';
import { IoIosCloseCircle } from 'react-icons/io';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import {
  default as InputField,
  default as InputGroup,
} from '../../../../components/ui/InputField';
import TextAreaField from '../../../../components/ui/TextAreaField';
import { useEditCardMutation } from '../../../../redux/features/cards/cardsApi';

const ProfileBio = ({ data, viewMode }) => {
  const [viewType, setViewType] = useState('preview');
  const [values, setValues] = useState({
    ...data,
    businessName: data?.card?.businessName,
  });
  const [inputSkill, setInputSkill] = useState('');
  const [isConfirmedRemoved, setIsConfirmRemoved] = useState(false);

  const [editProfile, { isLoading }] = useEditCardMutation();

  useEffect(() => {
    if (isConfirmedRemoved) {
      setTimeout(() => {
        setIsConfirmRemoved(false);
      }, 3000);
    }
  }, [isConfirmedRemoved]);

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const onSkillChange = (key, value) => {
    setInputSkill(value);
  };

  const handleAddSkill = () => {
    if (!inputSkill.trim()) return;
    const newSkills = [...values?.skills, inputSkill];
    setInputSkill('');
    setValues((prev) => {
      return { ...prev, skills: newSkills };
    });
  };

  const handleRemoveSkill = (value) => {
    const updateSkills = values?.skills?.filter((skill) => skill !== value);
    setValues((prev) => {
      return { ...prev, skills: updateSkills };
    });
  };

  const handleSubmit = async () => {
    // values.userMode = viewMode;

    // add view mode in values
    const newValues = { ...values, userMode: viewMode };
    try {
      const API = editProfile({ id: data?._id, data: newValues });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setViewType('preview');
      }
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex w-full items-center justify-between">
        <h4 className="text-[12px] font-bold opacity-70 dark:text-white">
          BIO
        </h4>
        {viewType === 'preview' && (
          <Button
            onClick={() => setViewType('edit')}
            className="dark:bg-dark-100 flex h-[35px] !w-[80px] items-center justify-center gap-1 bg-white text-[14px] !text-black dark:text-white"
          >
            <GoPencil size={16} className="pb-0.5" />
            <span>Edit</span>
          </Button>
        )}
      </div>

      {viewType === 'preview' ? (
        <Card className="rounded-[20px]">
          <div className="space-y-3">
            {/* <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Full Name</h4>
              <p className="opacity-70">{data?.fullName}</p>
            </div> */}
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">First Name</h4>
              <p className="opacity-70">{data?.firstName}</p>
            </div>
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Last Name</h4>
              <p className="opacity-70">{data?.lastName}</p>
            </div>
            {/* <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Headline</h4>
              <p className="opacity-70"> {data?.headline}</p>
            </div> */}
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Company </h4>
              <p className="opacity-70"> {data?.card?.businessName}</p>
            </div>

            {data?.role === 'business' && (
              <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
                <h4 className="font-bold">Slogan </h4>
                <p className="opacity-70"> {data?.card?.slogan}</p>
              </div>
            )}
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Job Position </h4>
              <p className="opacity-70"> {data?.jobInformation?.position}</p>
            </div>
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Location</h4>
              <p className="opacity-70"> {data?.location?.city}</p>
            </div>
            {/* <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Education</h4>
              <p className="opacity-70"> {data?.education}</p>
            </div> */}
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Bio</h4>
              <p className="break-words opacity-70"> {data?.bio}</p>
            </div>

            {/* <div className="space-y-2 text-[14px]">
              <h4 className="font-bold">Skills</h4>
              <div className="flex items-center gap-2">
                {data?.skills?.map((s) => (
                  <div key={s}>
                    <button className="rounded-full bg-gray-200 px-3 py-1">
                      {s}
                    </button>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </Card>
      ) : (
        <div className="space-y-5">
          <Card className="space-y-4 rounded-xl">
            <InputField
              name="firstName"
              label="First Name"
              value={values?.firstName}
              placeholder=""
              onChange={onChange}
            />
            <InputField
              name="lastName"
              label="Last Name"
              value={values?.lastName}
              placeholder=""
              onChange={onChange}
            />
            {/* <InputField
              name="fullName"
              label="Full Name"
              placeholder=""
              value={values?.fullName}
              onChange={onChange}
            /> */}
            {/* <InputField
              name="headline"
              label="Headline"
              placeholder=""
              value={values?.headline}
              onChange={onChange}
            /> */}
            <InputField
              name="jobInformation"
              subKey="companyName"
              label="Company"
              placeholder=""
              value={values?.businessName}
              onChange={onChange}
            />
            {/*<InputField*/}
            {/*    name="slogan"*/}
            {/*    label="Slogan"*/}
            {/*    placeholder=""*/}
            {/*    value={values?.slogan}*/}
            {/*    onChange={onChange}*/}
            {/*/>*/}

            {data?.role === 'business' && (
              <InputGroup
                name="slogan"
                label="Slogan"
                placeholder=""
                value={values?.card?.slogan}
                onChange={onChange}
              />
            )}

            <InputField
              name="jobInformation"
              subKey="position"
              label="Job Position"
              placeholder=""
              value={values?.jobInformation?.position}
              onChange={onChange}
            />
            <InputField
              name="location"
              label="Location"
              subKey="city"
              placeholder=""
              value={values?.location?.city}
              onChange={onChange}
            />
            {/* <InputField
              name="education"
              label="Education"
              placeholder=""
              value={values?.education}
              onChange={onChange}
            /> */}
            <TextAreaField
              name="bio"
              label="Summary"
              placeholder=""
              value={values?.bio}
              onChange={onChange}
            />
            {/* 
            <div className="relative">
              <InputField
                name="skill"
                label="Skills"
                placeholder=""
                onChange={onSkillChange}
                value={inputSkill}
              />
              <button
                className="dark:bg-dark-100 absolute bottom-[2px] right-1 flex h-[30px] !w-[60px]  items-center justify-center gap-1 rounded-md border bg-white text-[14px] dark:border-dark"
                onClick={handleAddSkill}
              >
                <FiPlus size={16} /> <span>Add</span>
              </button>
            </div> */}
            <div className="flex items-center gap-2 pb-3">
              {values?.skills?.map((s) => (
                <div key={s}>
                  {isConfirmedRemoved ? (
                    <button
                      onClick={() => handleRemoveSkill(s)}
                      className="flex items-center gap-1 rounded-xl bg-red-500 px-2 py-0.5 text-[12px] text-white"
                    >
                      {s} <IoIosCloseCircle size={14} />
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsConfirmRemoved(true)}
                      className="rounded-xl bg-gray-200 px-3 py-0.5 text-[12px]"
                    >
                      {s}
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between gap-4">
              <button
                disabled={isLoading}
                className="flex !w-full cursor-pointer items-center justify-center gap-1 rounded-lg !bg-primary px-4 py-2 text-white"
                onClick={handleSubmit}
              >
                {isLoading && <Loading height="0vh" variant="button" />}
                Save
              </button>

              <button
                onClick={() => setViewType('preview')}
                className="dark:bg-dark-100 !w-full rounded-lg border bg-white px-4 py-2 !text-black dark:border-dark dark:text-white"
              >
                Cancel
              </button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ProfileBio;
