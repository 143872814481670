import React, { useState } from 'react';
import Card from '../../../components/ui/Card';
import CVForm from './CVForm/CVForm';
import { BsPerson } from 'react-icons/bs';
import {
  MdWork,
  MdSchool,
  MdOutlineTimer,
  MdOutlineStar,
} from 'react-icons/md';
import { FaBriefcase, FaBook } from 'react-icons/fa';
import { GiSkills } from 'react-icons/gi';

const CreateCv = () => {
  const [currentTab, setCurrentTab] = useState('Personal Information');
  return (
    <div className="flex w-full">
      <div className="max-h-fit w-fit rounded-bl-lg rounded-tl-lg bg-black-300 sm:min-w-72">
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Personal Information' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Personal Information')}
        >
          <BsPerson size={20} />{' '}
          <span className="hidden sm:block">Personal Information</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Professional Information' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Professional Information')}
        >
          <MdWork size={20} />{' '}
          <span className="hidden sm:block">Professional Information</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Education' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Education')}
        >
          <MdSchool size={20} />{' '}
          <span className="hidden sm:block">Education</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Work Experience' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Work Experience')}
        >
          <FaBriefcase size={20} />{' '}
          <span className="hidden sm:block">Work Experience</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Courses' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Courses')}
        >
          <FaBook size={20} /> <span className="hidden sm:block">Courses</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Time Distribution' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Time Distribution')}
        >
          <MdOutlineTimer size={20} />{' '}
          <span className="hidden sm:block">Time Distribution</span>
        </div>
        <div
          className={`flex cursor-pointer gap-x-2 p-2 ${
            currentTab === 'Personal Skills' ? 'text-white' : ''
          }`}
          onClick={() => setCurrentTab('Personal Skills')}
        >
          <GiSkills size={20} />{' '}
          <span className="hidden sm:block">Personal Skills</span>
        </div>
      </div>
      <Card className="container mx-auto flex w-full flex-col gap-x-11 p-12 sm:flex-row">
        <h1 className="mb-4 min-w-fit text-2xl font-bold">Fill Your Details</h1>
        <CVForm currentTab={currentTab} />
      </Card>
    </div>
  );
};

export default CreateCv;
