import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaArrowLeft as ArrowLeft, FaPen } from 'react-icons/fa';
import { FaMoneyBills } from 'react-icons/fa6';
import { GrCurrency } from 'react-icons/gr';
import { MdAccessTime } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import FeatureSelection from '../../../../components/ui/FeatureSelection';
import InputGroup from '../../../../components/ui/InputField';
import SelectOptions from '../../../../components/ui/SelectOptions';
import { countryCurrencyOptions } from '../../../../data/data';
import {
  useCreateSubscriptionMutation,
  useEditSubscriptionMutation,
  useGetAllFeaturesQuery,
} from '../../../../redux/features/subscriptions/subscriptionsApi';
import { formatFeaturesDataIntoOptions } from '../../../../utils/dataFormater';

const SubscriptionForm = ({ data = {} }) => {
  const [createSubscription] = useCreateSubscriptionMutation();
  const [editSubscription] = useEditSubscriptionMutation();
  const [customSubscriptionName, setCustomSubscriptionName] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState(
    Array.isArray(data?.features) && data.features.length
      ? data.features.map((feature) => ({
          featureId: feature.featureId || '',
          quantity: feature.quantity || '',
        }))
      : [{ featureId: '', quantity: '' }]
  );

  const handleFeaturesChange = (features) => {
    if (features && features.length) {
      setSelectedFeatures(features); // Update only if features exist
    } else {
    }
  };

  useEffect(() => {
    console.log(selectedFeatures, 'Selected Features Updated in Parent');
  }, [selectedFeatures]);

  const [values, setValues] = useState(data);
  const { data: featuresOptionsData, isLoading } = useGetAllFeaturesQuery(
    values.subscriptionCategory,
    {
      skip:
        !values.subscriptionCategory ||
        !values.subscriptionCategory === 'undefined',
    }
  );

  const navigate = useNavigate();

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate if feature selcted is have length of 0 then throe errro
    if (selectedFeatures[0].featureId === '') {
      return toast.error('Please select  feature');
    }
    for (let i = 0; i < selectedFeatures.length; i++) {
      const { featureId, quantity } = selectedFeatures[i];
      if (!featureId) {
        return toast.error('Please select at least one feature');
      }
      if (!quantity || parseInt(quantity, 10) <= 0) {
        return toast.error(
          'Please enter a valid quantity for all selected features'
        );
      }
    }
    const planName = data?._id
      ? // In edit mode
        ['basic', 'standard', 'premium'].includes(values?.planName)
        ? values?.planName
        : values?.planName
      : // In create mode
      values?.selectPlanName === 'custom'
      ? values?.customSubscriptionName
      : values?.selectPlanName;
    const payload = {
      ...values,
      currency: 'OMR',
      // planName:
      //   (data?._id && values?.selectPlanName !== 'business') ||
      //   'individual' ||
      //   'premium'
      //     ? values?.planName
      //     : !data?._id && values?.selectPlanName === 'custom'
      //     ? values?.customSubscriptionName
      //     : values?.selectPlanName,
      planName,
      featureId: values?.feature,
      isBusiness:
        values?.subscriptionCategory === 'business' ||
        values?.subscriptionCategory === 'both',
      isIndividual:
        values?.subscriptionCategory === 'individual' ||
        values?.subscriptionCategory === 'both',
      isActive: values?.planStatus === 'active' || false,
      isTrail: values?.planStatus === 'trail' || false,
      features: selectedFeatures,
    };
    // delete subscriptionCategory
    delete payload.subscriptionCategory;
    try {
      const API = data?._id
        ? editSubscription({ id: data?._id, data: payload })
        : createSubscription(payload);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/admin/subscriptions-plan');
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card
        title={
          <div className="flex items-center">
            <div className="z-1  mr-4 flex items-center justify-start">
              <ArrowLeft
                className="h-6 w-6 cursor-pointer text-gray-600"
                onClick={() => navigate(-1)}
              />
            </div>
            <span>{data?._id ? 'Edit Subscription' : 'Create New Subscription'}</span>
          </div>
        }
        content={
          <Button
            link="/admin/subscriptions-plan"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Subscriptions
          </Button>
        }
        className="space-y-3"
      >
        {data?._id ? ( // Check if it's edit mode (data._id exists)
          ['basic', 'standard', 'premium'].includes(values?.planName) ? (
            <SelectOptions
              icon={<FaPen />}
              name="planName"
              value={values?.planName}
              onChange={onChange}
              placeholder="Select Subscription name"
              isRequired={true}
              disable={data?._id}
              options={[
                { label: 'Basic', value: 'basic' },
                { label: 'Standard', value: 'standard' },
                { label: 'Premium', value: 'premium' },
                { label: 'Custom', value: 'custom' },
              ]}
            />
          ) : (
            <InputGroup
              icon={<FaMoneyBills />}
              name="planName"
              value={values?.planName}
              onChange={onChange}
              placeholder="Enter subscription Name"
              type="text"
            />
          )
        ) : // Create mode
        values?.selectPlanName === 'custom' ? (
          <InputGroup
            icon={<FaMoneyBills />}
            name="customSubscriptionName"
            value={values?.customSubscriptionName}
            onChange={onChange}
            placeholder="Enter subscription Name"
            type="text"
          />
        ) : (
          <SelectOptions
            icon={<FaPen />}
            name="selectPlanName"
            value={values?.selectPlanName}
            onChange={onChange}
            placeholder="Select Subscription name"
            isRequired={true}
            options={[
              { label: 'Basic', value: 'basic' },
              { label: 'Standard', value: 'standard' },
              { label: 'Premium', value: 'premium' },
              { label: 'Custom', value: 'custom' },
            ]}
          />
        )}

        <SelectOptions
          icon={<FaPen />}
          name="subscriptionCategory"
          value={values?.subscriptionCategory}
          onChange={onChange}
          placeholder="Select Subscription Category"
          isRequired={true}
          options={[
            { label: 'Business', value: 'business' },
            { label: 'Individual', value: 'individual' },
            { label: 'Both', value: 'both' },
          ]}
        />

        <div className="">
          <FeatureSelection
            features={formatFeaturesDataIntoOptions(featuresOptionsData?.data)}
            onChange={handleFeaturesChange}
            disabled={!values?.subscriptionCategory}
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
          />
        </div>

        {/*<SelectOptions*/}
        {/*  icon={<GrCurrency />}*/}
        {/*  placeholder="Select Currency..."*/}
        {/*  name="currency"*/}
        {/*  options={countryCurrencyOptions}*/}
        {/*  value={values?.currency}*/}
        {/*  onChange={onChange}*/}
        {/*  isRequired={true}*/}
        {/*/>*/}

        <InputGroup
            icon={<GrCurrency />}
            name="currency"
            value="OMR"
            placeholder="Currency"
            type="text"
            isRequired={true}
            disabled={true}
        />

        <InputGroup
          icon={<FaMoneyBills />}
          name="planPrice"
          value={values?.planPrice}
          onChange={onChange}
          placeholder="Plan Price"
          type="number"
          isRequired={true}
        />

        <SelectOptions
          icon={<MdAccessTime />}
          name="planStatus"
          value={values?.planStatus}
          onChange={onChange}
          placeholder="Select plan Status"
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Trail', value: 'trail' },
          ]}
          isRequired={true}
        />

        <InputGroup
          icon={<MdAccessTime />}
          name="duration"
          value={values?.duration}
          onChange={onChange}
          placeholder="Plan duration"
          type="number"
          isRequired={true}
        />
        <SelectOptions
            icon={<FaPen />}
            name="isAddsOn"
            value={values?.isAddsOn}
            onChange={onChange}
            placeholder="Select Subscription Type"
            isRequired={true}
            options={[
              { label: 'Adds-on', value: 'yes' },
              { label: 'Regular Subscriptions', value: 'no' },
            ]}
        />

        {/* {values?.planStatus === 'active' ? (
          <SelectOptions
            icon={<MdAccessTime />}
            name="planDuration"
            value={values?.planDuration}
            onChange={onChange}
            placeholder="Select trail duration"
            options={[
              { label: '1 week', value: '1 week' },
              { label: '2 weeks', value: '2 weeks' },
              { label: '3 weeks', value: '3 weeks' },
              { label: '4 weeks', value: '4 weeks' },
            ]}
          />
        ) : values?.planStatus === 'trail' ? (
          <InputGroup
            icon={<MdAccessTime />}
            name="planDuration"
            value={values?.planDuration}
            onChange={onChange}
            placeholder="Plan duration"
            type="number"
          />
        ) : null} */}

        <Button type="submit" className="mt-3 !bg-green-400">
          Save
        </Button>
      </Card>
    </form>
  );
};

export default SubscriptionForm;
