import { useState } from 'react';
import { FaArrowsAlt, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { FaClone } from 'react-icons/fa6';

export const CoursesInformation = ({
  allCertificationData,
  setAllCertificationData,
}) => {
  const [certificationInformationData, setCertificationInformationData] =
    useState({
      courseName: '',
      authority: '',
      courseDate: '',
      description: '',
    });

  const [showForm, setShowForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertificationInformationData({
      ...certificationInformationData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAllCertificationData((prevData) => [
      ...prevData,
      certificationInformationData,
    ]);
    setShowForm(false);
    setCertificationInformationData({
      courseName: '',
      authority: '',
      courseDate: '',
      description: '',
    });
  };

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="text-lg font-bold">Certifications</div>
        <button
          type="button"
          onClick={() => setShowForm(!showForm)}
          className="rounded bg-green-500 px-2 text-white hover:bg-green-600"
        >
          {showForm ? <span className="px-[2px]">-</span> : '+'}
        </button>
      </div>
      {showForm ? (
        <>
          <div>
            <label htmlFor="courseName">Courses Name</label>
            <input
              type="text"
              name="courseName"
              placeholder="Courses Name"
              value={certificationInformationData?.courseName}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="authority">Authority</label>
            <input
              type="text"
              name="authority"
              placeholder="Authority"
              value={certificationInformationData?.authority}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="courseDate">Course Date</label>
            <input
              type="date"
              name="courseDate"
              value={certificationInformationData?.courseDate}
              onChange={handleChange}
              className="mb-2 w-full rounded border p-2"
              required
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              placeholder="Describe your role and achievements"
              value={certificationInformationData?.description}
              onChange={handleChange}
              className="mb-4 w-full rounded border p-2"
              required
            />
          </div>
          <button
            className="w-full rounded bg-blue-500 p-2 text-white hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Add Course
          </button>
        </>
      ) : (
        <div>
          {allCertificationData.map((experienceData, index) => (
            <div
              key={index}
              className="mb-4 flex items-center justify-between rounded border p-4 shadow-md hover:shadow-lg"
            >
              <div className="flex-1">
                <h3 className="font-semibold text-blue-500">
                  {experienceData?.courseName}
                </h3>
                <p className="text-sm text-gray-600">
                  {` ${experienceData?.authority} - ${new Date(
                    experienceData?.courseDate
                  ).toLocaleString('default', {
                    month: 'short',
                    year: 'numeric',
                  })}`}
                </p>
              </div>
              <div className="ml-4 flex items-center space-x-4">
                <button
                  className="text-yellow-500 hover:text-yellow-600"
                  title="Edit"
                >
                  <FaEdit />
                </button>
                <button
                  className="text-gray-500 hover:text-gray-600"
                  title="Duplicate"
                >
                  <FaClone />
                </button>
                <button
                  className="text-red-500 hover:text-red-600"
                  title="Delete"
                >
                  <FaTrashAlt />
                </button>
                <button
                  className="cursor-move text-blue-500 hover:text-blue-600"
                  title="Drag"
                >
                  <FaArrowsAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
