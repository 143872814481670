import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaShoppingCart } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import LanguageSelector from '../../../LanguageSelector';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import {
  useGetStoreByUserTokenQuery,
  useGetStoreQuery,
} from '../../../redux/features/stores/storesApi';
import ProductSection from './ProductSection';
import ReviewSection from './ReviewSection';
import StoreSection from './StoreSection';

const StorePreviewPage = ({
  addToCart,
  cart,
  isPublic,
  quantities,
  removeFromCart,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const token = localStorage.getItem('token_link_dashboard');
  const { data: userData, refetch } = useGetMyProfileQuery(undefined, {
    skip: !token,
  });
  useEffect(() => {
    if (token && token !== 'undefined' && token !== null) {
      localStorage.setItem('buyerEmail', userData?.data?.email || '');
    }
  }, [userData]);

  const isPublicView = pathname.split('/')[2] === 'public-view';
  const {
    data: storeData,
    isLoading: isStoreLoading,
    error: publicStoreError,
  } = useGetStoreQuery(id, {
    skip: !isPublicView,
  });
  const {
    data: privateUserData,
    isLoading: isPrivateUserLoading,
    error: privateStoreError,
  } = useGetStoreByUserTokenQuery(id, {
    skip: isPublicView,
  });

  const data = isPublicView
    ? storeData?.data
    : privateUserData?.data?.results[0]?.store;
  const isLoading = isPublicView ? isStoreLoading : isPrivateUserLoading;
  const error = isPublicView ? publicStoreError : privateStoreError;

  const buyerEmail = localStorage.getItem('buyerEmail');
  const navigate = useNavigate();

  const handelViewOrder = () => {
    navigate('/login-as-guest');
  };

  if (error) {
    return (
      <h1 className="text-center text-4xl text-rose-600">
        {error?.data?.message}
      </h1>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="space-y-6">
          <div className="flex items-center justify-between bg-white py-4 shadow-sm">
            <div className="mx-auto flex w-full max-w-[1000px] items-center justify-between">
              <img
                className="h-[40px] w-[40px] border"
                src={data?.store_logo}
                alt=""
              />
              <div className="flex items-center gap-5">
                {isPublic && (
                  <Link
                    to={`/cart?storeId=${data?.user}`}
                    className="flex items-center text-lg"
                  >
                    <FaShoppingCart size={24} />
                    <span className="ml-2">
                      {t('cart')} ({cart?.length})
                    </span>
                  </Link>
                )}
                {buyerEmail && (
                  <button
                    onClick={handelViewOrder}
                    className="ms-10 flex items-center text-lg"
                  >
                    <span className="ml-2">{t('viewOrders')}</span>
                  </button>
                )}

                <LanguageSelector />
              </div>
            </div>
          </div>
          <div className="mx-auto w-full max-w-[1000px] space-y-6 pb-6">
            <StoreSection data={data} storeId={id} buyerEmail={buyerEmail} isPublic={isPublic} />
            <ReviewSection storeData={data} />
            <ProductSection
              storeData={data}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              quantities={quantities}
              isPublic={isPublic}
            />
            <div className="pt-6 text-center text-[12px] opacity-70">
              <p>
                © {t('allRightsReserved')}.{' '}
                {t('poweredBy', { provider: 'Linkincard' })}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StorePreviewPage;
