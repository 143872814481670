import React from 'react';
import data from '../../../data/posts.json';
import PostViewCard from './PostViewCard';

const AllPosts = () => {
  return (
    <div>
      <PostViewCard data={data} isPostView={true} />
    </div>
  );
};

export default AllPosts;
