// src/pages/ProductPreview.js

import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetProductQuery } from '../../../redux/features/products/productsApi';

const ProductPreview = ({ addToCart }) => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const { data: product, isLoading } = useGetProductQuery(productId, {
    skip: !productId,
  });

  const images = product?.data?.otherImgUrl?.length
    ? product?.data?.otherImgUrl
    : [
        'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi5YNRPU4910yPsW1yobc1J7of1kMM-pww1Qf5lkpKePvG1-3GeRFJPh0U9w0FLoeojueyp4HtPxcqWkGJOudVgEv3tpEnJQM9-Ia-eemENMJTFpTFm6WeZiiB2nBRDIwl9PeRGvsjEJTI/s1600/placeholder-image.jpg',
      ];

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddToCart = () => {
    if (product) {
      addToCart({ ...product, quantity });
      toast.success(`${product?.data?.title?.en} added to cart!`);
    }
  };

  const handleQuantityChange = (increment) => {
    setQuantity((prevQuantity) =>
      Math.max(1, prevQuantity + (increment ? 1 : -1))
    );
  };

  if (!product) {
    return <p>Loading product details...</p>;
  }

  return (
    <div className="mx-auto w-full max-w-[480px] space-y-6 p-6">
      <ToastContainer position="top-center" />

      <h2 className="text-2xl font-bold">{product?.data?.title?.en}</h2>

      <div className="mb-4 overflow-hidden rounded-xl shadow-lg">
        <div className="relative h-[300px] bg-gray-200">
          {images.length > 0 && (
            <img
              className="h-full w-full cursor-pointer object-cover"
              src={images[currentImageIndex]}
              alt="Product"
              onClick={openModal}
            />
          )}
          <button
            onClick={handlePrevImage}
            disabled={currentImageIndex === 0}
            className={`absolute left-2 top-1/2 -translate-y-1/2 transform ${
              currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
            }`}
          >
            <FaChevronLeft size={24} />
          </button>
          <button
            onClick={handleNextImage}
            disabled={currentImageIndex === images.length - 1}
            className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${
              currentImageIndex === images.length - 1
                ? 'cursor-not-allowed opacity-50'
                : ''
            }`}
          >
            <FaChevronRight size={24} />
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <img
              className="h-[80vh] w-[60vw] object-contain"
              src={images[currentImageIndex]}
              alt="Full-size Product"
            />
            <button
              onClick={closeModal}
              className="absolute right-2 top-2 text-2xl text-white"
            >
              <FaTimes />
            </button>
            <button
              onClick={handlePrevImage}
              disabled={currentImageIndex === 0}
              className={`absolute left-4 top-1/2 -translate-y-1/2 transform text-white ${
                currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
              }`}
            >
              <FaChevronLeft size={32} />
            </button>
            <button
              onClick={handleNextImage}
              disabled={currentImageIndex === images.length - 1}
              className={`absolute right-4 top-1/2 -translate-y-1/2 transform text-white ${
                currentImageIndex === images.length - 1
                  ? 'cursor-not-allowed opacity-50'
                  : ''
              }`}
            >
              <FaChevronRight size={32} />
            </button>
          </div>
        </div>
      )}

      <div className="space-y-4 rounded-lg bg-white p-6 shadow-lg">
        <p className="text-lg font-semibold text-gray-700">
          {product?.data.price} {product?.data?.store?.store_contact_information?.currency || 'EUR'}
        </p>
        <p className="text-gray-600">
          {product?.data?.description?.en || product?.data?.description?.ar}
        </p>

        {/* <div className="mt-4 flex items-center space-x-4">
          <button
            onClick={() => handleQuantityChange(false)}
            className="border px-2 py-1"
          >
            -
          </button>
          <span>{quantity}</span>
          <button
            onClick={() => handleQuantityChange(true)}
            className="border px-2 py-1"
          >
            +
          </button>
        </div>

        <button
          className="mt-4 w-full rounded bg-blue-600 px-4 py-2 text-white"
          onClick={handleAddToCart}
        >
          Add to Cart
        </button> */}
      </div>
    </div>
  );
};

export default ProductPreview;
