import { apiSlice } from '../api/apiSlice';

export const contactsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNearbyUsers: builder.query({
      query: ({ ...rest }) => ({
        url: `/users/nearby-users`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['nearbyUsers'],
    }),

    getPeopleYouMayKnow: builder.query({
      query: ({ ...rest }) => ({
        url: `/users/people-you-may-know`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['peopleYouMayKnow'],
    }),

    getUserPhotos: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}/photos`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getUserVideos: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}/videos`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getUserFriends: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}/friends`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getUserMutualFriends: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}/mutual-friends`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getMyFriends: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/my-friends/accepted`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getMyFriendRequests: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/contacts/friend/request/has/not/accepted`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['friendRequests'],
    }),

    getMySentFriendRequests: builder.query({
      query: ({ ...rest }) => ({
        url: `/contacts/friend/you/have/sent/request`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['mySentFriendRequest'],
    }),

    getUserFriendCount: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}/friends/count`,
        method: 'GET',
        params: { ...rest },
      }),
    }),

    makeFriendRequest: builder.mutation({
      query: (data) => ({
        url: `/users/friend-request`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'getSingleUser',
        'friendRequests',
        'mySentFriendRequest',
      ],
    }),

    // acceptFriendRequest: builder.mutation({
    //   query: (data) => ({
    //     url: `/users/accept-friend-request`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['friendRequests'],
    // }),

    // PUT accept request WITH ID
    acceptFriendRequest: builder.mutation({
      query: (id) => ({
        url: `/contacts/accept/friend/request/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['friendRequests'],
    }),

    // deleteFriendRequest: builder.mutation({
    //     query: (data) => ({
    //         url: `/users/my-friends/my-sent/requested`,
    //         method: "POST",
    //         body: data,
    //     }),
    //     invalidatesTags: ["getSingleUser", "friendRequests"],
    // }),

    deleteFriendRequest: builder.mutation({
      query: (data) => ({
        url: `/users/delete-friend-request`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['getSingleUser', 'friendRequests'],
    }),

    //? friends categories
    getFriendsCategories: builder.query({
      query: ({ ...rest }) => ({
        url: `/my-friends/categories`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['friendCategories'],
    }),

    getFriendsSingleCategories: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/my-friends/categories/${id}`,
        method: 'GET',
        params: { ...rest },
      }),
      // providesTags: ['friendCategories']
    }),

    getFriendsCategoriesOptions: builder.query({
      query: ({}) => ({
        url: `/my-friends/categories/options?lang=en&sort=asc?limit=5`,
        method: 'GET',
      }),
    }),

    createFriendsCategories: builder.mutation({
      query: (data) => ({
        url: `/my-friends/categories`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['friendCategories'],
    }),

    updateFriendsCategories: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/my-friends/categories/${id}`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: ['friendCategories'],
    }),

    deleteFriendsCategories: builder.mutation({
      query: (id) => ({
        url: `/my-friends/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friendCategories'],
    }),

    getFriendsWithCategoriesId: builder.query({
      query: ({ id, ...rest }) => ({
        url: `/contacts/getfriends/${id}`,
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: ['friendCategories'],
    }),
    //? friends relationships
    getSingleContactById: builder.query({
      query: (id) => ({
        url: `/contacts/${id}`,
        method: 'GET',
      }),
      providesTags: ['friendCategories'],
    }),

    getMyFriendsWithRelations: builder.query({
      query: ({ id }) => ({
        url: `/my-friends/relationships?friendCategory=${id}&sort=desc`,
        method: 'GET',
      }),
      providesTags: ['myFriendsWithRelations'],
    }),

    getOthersFriendsWithRelations: builder.query({
      query: ({ id }) => ({
        url: `/my-friends/relationships?friendCategory=others&sort=desc`,
        method: 'GET',
      }),
      providesTags: ['myOthersFriendsWithRelations'],
    }),

    makeFriendRelations: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/my-friends/relationships`,
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: [
        'friendCategories',
        'myFriendsWithRelations',
        'myOthersFriendsWithRelations',
      ],
    }),
  }),
});

export const {
  useGetMyFriendsQuery,
  useGetNearbyUsersQuery,
  useGetUserFriendsQuery,
  useGetUserPhotosQuery,
  useGetUserVideosQuery,

  useGetPeopleYouMayKnowQuery,
  useGetUserFriendCountQuery,
  useGetMyFriendRequestsQuery,
  useGetMySentFriendRequestsQuery,
  useMakeFriendRequestMutation,
  useGetUserMutualFriendsQuery,
  useAcceptFriendRequestMutation,
  useDeleteFriendRequestMutation,

  // friends categories
  useGetFriendsCategoriesQuery,
  useGetFriendsSingleCategoriesQuery,
  useGetFriendsCategoriesOptionsQuery,
  useCreateFriendsCategoriesMutation,
  useUpdateFriendsCategoriesMutation,
  useDeleteFriendsCategoriesMutation,

  useGetFriendsWithCategoriesIdQuery,
  useGetSingleContactByIdQuery,

  // friends relationships
  useMakeFriendRelationsMutation,
  useGetMyFriendsWithRelationsQuery,
  useGetOthersFriendsWithRelationsQuery,
} = contactsApi;
