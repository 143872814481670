import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import features from '../../../../constants/fetures';
import { useCreateCvMutation } from '../../../../redux/features/cv/cvApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../../../utils/helper';
import { EducationInformation } from './Education';
import { WorkExperienceInformation } from './WorkExperience';
import { CoursesInformation } from './Course';
import { PersonalInformation } from './PersonalInformation';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CVForm = ({ onSubmit, currentTab }) => {
  const [createCv, { isLoading }] = useCreateCvMutation();
  const navigate = useNavigate();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();
  const { id } = useParams();

  const [personalInformationData, setPersonalInformationData] = useState({
    name: '',
    jobTitle: '',
    summary: '',
    address: '',
    phone: '',
    email: '',
    imageUrl: '',
  });

  const [allWorkExperienceData, setAllWorkExperienceData] = useState([]);
  const [allEducationData, setAllEducationData] = useState([]);
  const [allCertificationData, setAllCertificationData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    jobTitle: '',
    address: '',
    summary: '',
    phone: '',
    email: '',
    education: '',
    experience: '',
    skills: [''],
    certifications: '',
    languages: [''],
    hobbies: [''],
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: personalInformationData?.name,
      jobTitle: personalInformationData?.jobTitle,
      address: personalInformationData?.address,
      summary: personalInformationData?.summary,
      phone: personalInformationData?.phone,
      email: personalInformationData?.email,
      education: allEducationData,
      experience: allWorkExperienceData,
      certifications: allCertificationData,
      imageUrl: personalInformationData?.imageUrl,
    });
  }, [
    personalInformationData,
    allEducationData,
    allWorkExperienceData,
    allCertificationData,
  ]);

  const [timeDistribution, setTimeDistribution] = useState({
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  });

  const [personalSkills, setPersonalSkills] = useState({
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  });

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const newValue = parseInt(value, 10);

    const otherValuesSum = Object.keys(timeDistribution)
      .filter((key) => key !== name)
      .reduce((sum, key) => sum + timeDistribution[key], 0);

    if (newValue + otherValuesSum > 100) {
      setTimeDistribution({
        ...timeDistribution,
        [name]: 100 - otherValuesSum,
      });
    } else {
      setTimeDistribution({ ...timeDistribution, [name]: newValue });
    }
  };

  const handleSkillChange = (e) => {
    const { name, value } = e.target;
    setPersonalSkills({ ...personalSkills, [name]: parseInt(value, 10) });
  };

  const renderDynamicFields = (label, field) => (
    <div className="space-y-2">
      <label className="font-bold">{label}</label>
      {formData[field].map((item, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            placeholder={label}
            value={item}
            onChange={(e) =>
              handleDynamicFieldChange(field, index, e.target.value)
            }
            className="w-full rounded border p-2"
          />
          {index === formData[field].length - 1 && (
            <button
              type="button"
              onClick={() => addField(field)}
              className="rounded bg-green-500 p-2 text-white"
            >
              +
            </button>
          )}
        </div>
      ))}
    </div>
  );

  const handleDynamicFieldChange = (field, index, value) => {
    const updatedFields = [...formData[field]];
    updatedFields[index] = value;
    setFormData({ ...formData, [field]: updatedFields });
  };

  const handleInputChange = (field, value) => {
    // Update the specific field with the new value
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const addField = (field) => {
    setFormData({ ...formData, [field]: [...formData[field], ''] });
  };

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          timeDistribution.working,
          timeDistribution.family,
          timeDistribution.coordination,
          timeDistribution.entertainment,
          timeDistribution.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsButtonDisabled(true);

    try {
      const totalPercentage = Object.values(timeDistribution).reduce(
        (total, value) => total + value,
        0
      );
      if (totalPercentage !== 100) {
        alert('The total percentage must equal 100%.');
        setIsButtonDisabled(false);
        return;
      }

      if (
        personalSkills?.communication === 0 ||
        personalSkills?.decisionMaking === 0 ||
        personalSkills?.leadership === 0 ||
        personalSkills?.selfMotivation === 0
      ) {
        alert('Fill your personal skills to create CV.');
        setIsButtonDisabled(false); // Re-enable the button on validation error
        return;
      }

      // Transform formData fields into the required payload format
      const payload = {
        name: formData?.name,
        jobTitle: formData?.jobTitle,
        address: formData?.address,
        summary: formData?.summary,
        phone: formData?.phone,
        email: formData?.email,
        experience: formData?.experience,
        education: formData?.education,
        // .filter((edu) => edu.trim() !== '') // Remove empty strings
        // .map((edu) => ({ education: edu })),
        skills: formData.skills
          .filter((skill) => skill.trim() !== '') // Remove empty strings
          .map((skill) => ({ skills: skill })),
        certifications: formData.certifications,
        languages: formData.languages
          .filter((lang) => lang.trim() !== '') // Remove empty strings
          .map((lang) => ({ languages: lang })),
        hobbies: formData.hobbies
          .filter((hobby) => hobby.trim() !== '') // Remove empty strings
          .map((hobby) => ({ hobbies: hobby })),
        working: timeDistribution.working,
        family: timeDistribution.family,
        coordination: timeDistribution.coordination,
        entertainment: timeDistribution.entertainment,
        relaxing: timeDistribution.relaxing,
        leadership: personalSkills.leadership,
        communication: personalSkills.communication,
        selfMotivation: personalSkills.selfMotivation,
        decisionMaking: personalSkills.decisionMaking,
        isDeleted: false,
        user: '64fbc4e7c2a3d4a910fecd12', // Replace with dynamic user ID if needed
        imageUrl: formData?.imageUrl,
        templateId: id,
      };

      // Call the onSubmit handler with the transformed payload
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.CV, featuresData?.data)
      );

      if (featureUsageResponse?.error) {
        toast.error(featureUsageResponse?.error?.data?.message);
        setTimeout(() => {
          navigate('/subscription/history');
        }, 1000);
        setIsButtonDisabled(false); // Re-enable the button on error
        return;
      }

      const result = await createCv(payload);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate(`/cv-view/${result?.data?.data?._id}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsButtonDisabled(false); // Re-enable the button on error
    }
  };

  return (
    <div className="flex w-full gap-x-[1.8rem]">
      <form onSubmit={handleSubmit} className="mt-4 w-full space-y-4">
        {/* Form Fields */}
        {currentTab === 'Personal Information' && (
          <>
            <PersonalInformation
              setPersonalInformationData={setPersonalInformationData}
              personalInformationData={personalInformationData}
            />
            {renderDynamicFields('Hobbies', 'hobbies')}
          </>
        )}

        {currentTab === 'Professional Information' && (
          <>
            {renderDynamicFields('Skills', 'skills')}
            {renderDynamicFields('Languages', 'languages')}
          </>
        )}
        {currentTab === 'Courses' && (
          <CoursesInformation
            allCertificationData={allCertificationData}
            setAllCertificationData={setAllCertificationData}
          />
        )}
        {currentTab === 'Work Experience' && (
          <WorkExperienceInformation
            allWorkExperienceData={allWorkExperienceData}
            setAllWorkExperienceData={setAllWorkExperienceData}
          />
        )}
        {currentTab === 'Education' && (
          <EducationInformation
            allEducationData={allEducationData}
            setAllEducationData={setAllEducationData}
          />
        )}

        {currentTab === 'Time Distribution' && (
          <div className="flex space-x-4">
            <div className="w-1/2 space-y-4">
              <div className="font-bold">
                Time Distribution (Total must equal 100%)
              </div>
              {[
                'working',
                'family',
                'coordination',
                'entertainment',
                'relaxing',
              ].map((category) => (
                <div key={category}>
                  <label>
                    {category.charAt(0).toUpperCase() + category.slice(1)}:{' '}
                    {timeDistribution[category]}%
                  </label>
                  <input
                    type="range"
                    name={category}
                    min="0"
                    max="100"
                    value={timeDistribution[category]}
                    onChange={handleTimeChange}
                    className="w-full"
                  />
                </div>
              ))}
            </div>

            <div className="w-1/2">
              <div className="ml-10 font-bold">Graphical Representation</div>

              <div className="relative mt-12 h-64 w-96">
                <Doughnut data={chartData} options={options} />
              </div>
            </div>
          </div>
        )}

        {currentTab === 'Personal Skills' && (
          <div className="space-y-4">
            <div className="font-bold">
              Personal Skills (Select percentage for each)
            </div>
            {[
              'leadership',
              'communication',
              'selfMotivation',
              'decisionMaking',
            ].map((skill) => (
              <div key={skill}>
                <label>
                  {skill.charAt(0).toUpperCase() + skill.slice(1)}:{' '}
                  {personalSkills[skill]}%
                </label>
                <input
                  type="range"
                  name={skill}
                  min="0"
                  max="100"
                  value={personalSkills[skill]}
                  onChange={handleSkillChange}
                  className="w-full"
                />
              </div>
            ))}
          </div>
        )}

        {/*<button*/}
        {/*  onSubmit={() => handleSubmit()}*/}
        {/*  // type="submit"*/}
        {/*  className="rounded bg-blue-500 p-2 text-white"*/}
        {/*>*/}
        {/*  Preview & Download*/}
        {/*</button>*/}
        <button
          type="submit"
          className={`rounded bg-blue-500 p-2 text-white ${
            isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''
          }`}
          disabled={isButtonDisabled}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default CVForm;
