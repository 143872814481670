import React, { useEffect, useState } from 'react';

const FeatureSelection = ({
  features = [],
  onChange,
  selectedFeatures,
  disabled = false,
  setSelectedFeatures,
}) => {
  const [localFeatures, setLocalFeatures] = useState(selectedFeatures);

  useEffect(() => {
    setLocalFeatures(selectedFeatures);
  }, [selectedFeatures]);

  useEffect(() => {
    if (selectedFeatures.length) {
      setLocalFeatures(selectedFeatures);
    }
  }, [selectedFeatures]);

  const handleFeatureChange = (index, key, value) => {
    const updatedFeatures = [...localFeatures];
    updatedFeatures[index] = {
      ...updatedFeatures[index],
      [key]: value,
    };

    setLocalFeatures(updatedFeatures);
    onChange(updatedFeatures);
  };

  const addFeatureRow = () => {
    const updatedFeatures = [...localFeatures, { featureId: '', quantity: '' }];
    setLocalFeatures(updatedFeatures);
    setSelectedFeatures(updatedFeatures);
  };

  const removeFeatureRow = (index) => {
    const updatedFeatures = localFeatures.filter((_, i) => i !== index);
    setLocalFeatures(updatedFeatures);
    setSelectedFeatures(updatedFeatures);
    onChange(updatedFeatures);
  };

  const getAvailableFeatures = (index) => {
    const selectedIds = localFeatures
      .filter((_, i) => i !== index)
      .map((item) => item.featureId);
    return features.filter((feature) => !selectedIds.includes(feature.value));
  };

  const hasAvailableFeatures = () => {
    const selectedIds = selectedFeatures.map((item) => item.featureId);
    return features.some((feature) => !selectedIds.includes(feature.value));
  };

  return (
    <div className=" space-y-4">
      {selectedFeatures.map((item, index) => (
        <div key={index} className="flex items-center space-x-4">
          <select
            className="h-[35px] w-[60%] rounded-lg border border-gray-300 px-3 text-sm font-medium"
            value={item.featureId}
            onChange={(e) =>
              handleFeatureChange(index, 'featureId', e.target.value)
            }
            disabled={disabled}
          >
            <option value="">Select Feature</option>
            {getAvailableFeatures(index).map((feature) => (
              <option key={feature.value} value={feature.value}>
                {feature.label}
              </option>
            ))}
          </select>

          <input
            type="number"
            min="1"
            className="h-[35px] w-[40%] rounded-lg border border-gray-300 px-3 text-sm font-medium"
            value={item.quantity}
            disabled={disabled}
            placeholder="Enter Quantity"
            onChange={(e) =>
              handleFeatureChange(index, 'quantity', e.target.value)
            }
          />
          {!disabled && (
            <button
              type="button"
              className="h-[35px] rounded-lg bg-red-500 px-4 text-white"
              onClick={() => removeFeatureRow(index)}
              disabled={selectedFeatures.length === 1}
            >
              Remove
            </button>
          )}
        </div>
      ))}
      {hasAvailableFeatures() && (
        <button
          type="button"
          className=" rounded-lg bg-green-500 px-4 py-1 text-white"
          onClick={addFeatureRow}
        >
          Add Feature
        </button>
      )}
    </div>
  );
};

export default FeatureSelection;
