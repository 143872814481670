import React from 'react';
import { Helmet } from 'react-helmet';

const SocialMetaTags = ({ title, description, image, url }) => {
  return (
    <Helmet>
      {/* Open Graph Meta Tags */}
      <meta charSet="utf-8" />

      <link rel="icon" href={image} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* <link rel="icon" type="image/jpeg" href={image} /> */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
    </Helmet>
  );
};

export default SocialMetaTags;
