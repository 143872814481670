import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import {
  useDeleteQrCategoryMutation,
  useGetQrCategoriesQuery,
} from '../../../redux/features/qrCategoryApi/qrCategoryApi';

const QrCategoriesList = () => {
  const navigate = useNavigate();
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useGetQrCategoriesQuery({
    page: paginate?.page,
    limit: paginate?.limit,
  });

  const [deleteQrCategory] = useDeleteQrCategoryMutation();

  const handleDeleteApi = async (id) => {
    try {
      const result = await deleteQrCategory(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this QR category?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const content = {
    header: ['Name', 'Sort Order', 'Actions'],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.name,
        },
        {
          row: item?.sortOrder,
        },
        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                link={`/qr-categories/edit/${item?._id}`}
                className="!block !bg-green-400 "
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <>
      <Card
        title="QR Categories"
        content={
          <button
            className="flex items-center gap-2 rounded bg-[#009ef7] px-4 py-2 text-white"
            onClick={() => navigate('/qr-categories/create')}
          >
            <FaPlus /> Create QR Category
          </button>
        }
      >
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </>
  );
};

export default QrCategoriesList;
