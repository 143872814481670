import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../redux/features/users/usersApi';
import Loading from '../Loading';

import CardClassicView from '../ui/CardClassicView';
import SocialMetaTags from '../SocialMetaTags';

const UserClassicViewPage = () => {
  const { id } = useParams();
  const locationC = useLocation();
  const location = locationC?.pathname;
  const pathSegments = location?.split('/');
  const paramsUserName = pathSegments[pathSegments.length - 2];
  const branchId = pathSegments[pathSegments.length - 1];
  const { data: cardData, isLoading } = useGetUserQuery([
    paramsUserName,
    branchId,
  ]);
  const phone = `${cardData?.data?.phone?.dialCode ?? ''} ${
    cardData?.data?.phone?.fullNumber ?? ''
  }`;

  const whatsapp = `${cardData?.data?.whatsapp?.dialCode ?? ''} ${
    cardData?.data?.whatsapp?.fullNumber ?? ''
  }`;

  const data = {
    name: `${cardData?.data?.firstName || ''} ${
      cardData?.data?.lastName || ''
    }`,
    image: cardData?.data?.avatar,
    bannerImg: cardData?.data?.bannerBg,
    position: `${
      (cardData?.data?.position || cardData?.data?.jobInformation?.position) ??
      ''
    }`,
    education: cardData?.data?.education,
    skills: cardData?.data?.skills,
    address: cardData?.data?.address,
    email: cardData?.data?.email,
    phone: `${phone !== ' ' ? phone : ''}`,
    additionalPhones: cardData?.data?.additionalPhones,
    whatsapp: `${whatsapp !== ' ' ? whatsapp : ''}`,
    fax: cardData?.data?.user?.faxNumber,
    bio: cardData?.data?.user?.bio,
    social: cardData?.data?.socialMediaLinks,
    additionalSocialLinks: cardData?.data?.additionalSocialLinks,
    youtubeVideo: cardData?.data?.socialMediaLinks?.videoLink,
    bankAccounts: cardData?.data?.bankAccounts,
    store: cardData?.data?.store,
  };
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <SocialMetaTags
            title={cardData?.data?.businessName}
            description={cardData?.data?.businessName}
            image={cardData?.data?.bannerBg}
            url={`${cardData?.data?.card?.url}`}
          />
          <CardClassicView
            data={data}
            link={`/card/edit/${id}`}
            variant="user"
          />
        </div>
      )}
    </div>
  );
};

export default UserClassicViewPage;
