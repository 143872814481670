import React from 'react';

const SkillProgress = ({
  contentColor,
  fontFamily,
  value,
  title,
  sideDesignBgColor,
}) => {
  return (
    <div className="mb-2">
      <div className="mb-2">
        <div className="flex justify-between py-[8px]">
          <span className={`text-lg ${contentColor} ${fontFamily}`}>
            {title}
          </span>
          <span className={`text-lg ${contentColor} ${fontFamily}`}>
            {value}%
          </span>
        </div>
        <div className="relative h-4 overflow-hidden rounded bg-gray-200">
          <div
            className="absolute left-0 top-0 h-full bg-blue-500"
            style={{
              width: `${value}%`,
              backgroundColor: `${sideDesignBgColor}`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SkillProgress;
