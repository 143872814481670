import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaCopy, FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { RiMessengerFill } from 'react-icons/ri';
import { SiMessenger } from 'react-icons/si';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DialogModal from '../../../components/ui/DialogModal';
import {
  useDeleteCardMutation,
  useGetCardsQuery,
} from '../../../redux/features/cards/cardsApi';
import SingleCard from './SingleCard';

const CardsPage = () => {
  const { data: cards, isLoading } = useGetCardsQuery();
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState('');

  const [deleteCard, { isLoading: isDeletingCard }] = useDeleteCardMutation();

  const handleDeleteCard = async (id) => {
    try {
      const result = await deleteCard(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('Wifi Deleted Failed');
    }
  };

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: (url) =>
        `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
    },
    {
      icon: <MdEmail />,
      color: '#000000',
      platform: 'email',
      url: (url) => `mailto:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaFacebookF />,
      color: '#1877F2',
      platform: 'facebook',
      url: (url) =>
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
    },
    {
      icon: <RiMessengerFill />,
      color: '#0078FF',
      platform: 'messenger',
      url: (url) => `fb-messenger://share/?link=${encodeURIComponent(url)}`,
    },
    {
      icon: <SiMessenger />,
      color: '#FFA500',
      platform: 'sms',
      url: (url) => `sms:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaTwitter />,
      color: '#1DA1F2',
      platform: 'twitter',
      url: (url) =>
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    },
  ];

  const openShareModal = (url) => {
    setUrlToCopy(url);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  function getBranchProperty(data, key) {
    const mainObject = cards?.data?.docs[0];
    return data?.isChildBranch ? data[key] : mainObject?.[key];
  }
  return (
    <div className="p-8">
      {isLoading ? (
        <Loading height="60vh" />
      ) : (
        <div className="">
          <Card className="flex items-center justify-between !p-4 !pb-2">
            <div>
              {cards?.data?.docs[0]?.userBusinessBranches?.length
                ? 'All Cards'
                : 'No card found'}
            </div>
          </Card>
          {cards?.data?.docs[0]?.userBusinessBranches?.length > 0 ? (
            <div className="mt-10 grid grid-cols-3 gap-4">
              {cards?.data?.docs[0]?.userBusinessBranches?.map((data) => (
                <SingleCard
                  key={data?._id}
                  data={{
                    name: `${getBranchProperty(data, 'firstName') || ''} ${
                      getBranchProperty(data, 'lastName') || ''
                    }`,
                    id: data?._id,
                    image: data?.qrImage,
                    job: data?.user?.headline,
                    username: getBranchProperty(data, 'username'),
                  }}
                  shareLink={() =>
                    openShareModal(
                      window.location.origin +
                        `/${data?.url?.split('/').slice(3, 7).join('/')}/${
                          data?.branchNumber
                        }`
                    )
                  }
                  editLink={
                    window.location.origin +
                    `/card/edit/${data?.url
                      ?.split('/')
                      .slice(4, 7)
                      .join('/')}/${data?.branchNumber}`
                  }
                  viewLink={
                    window.location.origin +
                    `/view/${data?.url?.split('/').slice(4, 7).join('/')}/${
                      data?.branchNumber
                    }`
                  }
                  // viewLink={`/view/${data?._id}`}
                  analyticLink={`/analytics/${data?._id}?cardType=company`}
                  // deleteFunc={() => handleDeleteCard(data?._id)}
                  // isDelete
                  // loading={isDeletingCard ? 'delete' : ''}
                />
              ))}
              <div className="flex h-[420px] w-full max-w-[400px] items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-4">
                <Button
                  link="/cards/create"
                  //link="/create/card"
                  className="flex flex-col items-center !bg-white !text-gray-400 transition duration-300 ease-in-out hover:!bg-gray-100 hover:!text-gray-600"
                >
                  <FaPlus size={52} />
                </Button>
              </div>
            </div>
          ) : (
            <div className="mt-10 grid grid-cols-3 gap-4">
              {/* <div className="col-span-3 flex items-center justify-center">
                Data not available!
              </div> */}
              <div className="flex h-[420px] w-full max-w-[400px] items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-4">
                <Button
                  link="/cards/create"
                  className="flex flex-col items-center !bg-white !text-gray-400 transition duration-300 ease-in-out hover:!bg-gray-100 hover:!text-gray-600"
                >
                  <FaPlus size={52} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {isOpenModal && (
        <DialogModal onClose={closeModal} title="Share My Profile" width="500">
          <div className="flex flex-col items-center gap-4 py-8">
            <div className="flex flex-wrap gap-4">
              {shareButtons.map((button) => (
                <button
                  key={button.platform}
                  onClick={() => window.open(button.url(urlToCopy), '_blank')}
                  className="rounded-full p-2 transition-transform hover:scale-110"
                  style={{ backgroundColor: button.color }}
                >
                  {React.cloneElement(button.icon, {
                    color: 'white',
                    size: 24,
                  })}
                </button>
              ))}
            </div>
            <input
              type="text"
              value={urlToCopy}
              readOnly
              className="w-full rounded-md border p-2 text-center"
              style={{ maxWidth: '400px', height: '80px' }}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(urlToCopy);
                toast.success('Link Copied!');
              }}
              className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
            >
              <FaCopy size={16} />
              Copy Link
            </button>
          </div>
          <div className="flex justify-end pt-3">
            <button
              className="rounded bg-dark px-3 py-2 text-[14px] text-white"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </DialogModal>
      )}
    </div>
  );
};

export default CardsPage;
