import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CustomizationPanel from './CustomizationPanel';
import { MdMapsHomeWork, MdOutlineMailOutline } from 'react-icons/md';
import { TbCertificate, TbListDetails } from 'react-icons/tb';
import { FaFilePdf, FaGraduationCap, FaRegEnvelope } from 'react-icons/fa6';
import { VscGraph } from 'react-icons/vsc';
import { SiCoursera } from 'react-icons/si';
import { IoBicycleSharp, IoLanguageSharp } from 'react-icons/io5';
import { Doughnut } from 'react-chartjs-2';
import SkillProgress from './SkillProgress';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);
import { FaRegFileAlt } from 'react-icons/fa';
import { BiMessage } from 'react-icons/bi';

const CVTemplate2 = ({ data }) => {
  const location = useLocation();
  const componentRef = useRef();
  const [formData, setFormData] = useState(data);
  React.useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const [headingColor, setHeadingColor] = useState('text-blue-400');
  const [contentColor, setContentColor] = useState('text-black');
  const [fontFamily, setFontFamily] = useState('font-sans');
  const [textTransform, setTextTransform] = useState('normal-case');
  const [sideDesignBgColor, setSideDesignBgColor] = useState('#3b82f6');
  const [secondarySideDesignBgColor, setSecondarySideDesignBgColor] =
    useState('#1c2434');

  const timeDistribution = formData?.timeDistribution || {
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  };

  const personalSkills = formData?.personalSkills || {
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  };

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          formData?.working,
          formData?.family,
          formData?.coordination,
          formData?.entertainment,
          formData?.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const generatePdf = async () => {
    const element = componentRef.current;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    });

    const imgData = canvas.toDataURL('image/png');

    if (!imgData.startsWith('data:image/png')) {
      console.error('Invalid PNG image data');
      return;
    }

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const a4Width = 210;
    const a4Height = 297;
    const dpi = 96;
    const pxPerMm = dpi / 25.4;
    const a4WidthPx = a4Width * pxPerMm;
    const a4HeightPx = a4Height * pxPerMm;

    // Calculate scaling factor
    const scale =
      Math.min(a4WidthPx / canvas.width, a4HeightPx / canvas.height) * 1.2;
    const scaledCanvasWidth = canvas.width * scale;
    const scaledCanvasHeight = canvas.height * scale;

    // Number of pages needed
    const pageHeightPx = a4HeightPx / scale;
    const totalPages = Math.ceil(canvas.height / pageHeightPx);

    for (let page = 0; page < totalPages; page++) {
      const pageCanvas = document.createElement('canvas');
      pageCanvas.width = canvas.width;
      pageCanvas.height = Math.min(
        pageHeightPx,
        canvas.height - page * pageHeightPx
      );

      const pageContext = pageCanvas.getContext('2d');
      pageContext.drawImage(
        canvas,
        0,
        page * pageHeightPx,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        canvas.width,
        pageCanvas.height
      );

      const pageImgData = pageCanvas.toDataURL('image/png');

      // Check if pageImgData is valid for this page
      if (!pageImgData.startsWith('data:image/png')) {
        console.error(`Invalid PNG data for page ${page}`);
        continue;
      }

      if (page > 0) {
        pdf.addPage();
      }

      // Add the image to the PDF page
      pdf.addImage(
        pageImgData,
        'PNG',
        0,
        0,
        a4Width,
        (pageCanvas.height / pxPerMm) * scale
      );
    }

    pdf.save('download.pdf');
  };

  return (
    <div className="flex gap-10 py-20 pl-8">
      <div className="relative  opacity-15 print:hidden">
        <h1 className="absolute mt-44 translate-x-56 translate-y-72 rotate-45 transform text-9xl text-gray-400">
          Preview
        </h1>
      </div>
      <div
        ref={componentRef}
        className="grid w-2/3 grid-cols-12 border bg-white shadow-lg print:w-full"
      >
        <div className="relative col-span-4 overflow-hidden">
          <section className="mt-0 flex flex-col items-center pl-8 pr-8 pt-8">
            <img
              src={formData?.imageUrl || '/dummyImg.jpg'}
              alt="user"
              className="h-36 w-36 rounded-full"
            />
          </section>

          <div className="absolute inset-0 left-[-21rem] top-[-4rem] flex items-center justify-center bg-transparent">
            <svg
              viewBox="0 0 300 300"
              xmlns="http://www.w3.org/2000/svg"
              className="h-full w-full"
              preserveAspectRatio="none"
            >
              <polygon
                points="0,15 250,77 250,154 0,214 42,202 42,77"
                style={{
                  fill: `${secondarySideDesignBgColor}`,
                  strokeWidth: '3',
                }}
              />
            </svg>
          </div>

          <div className="absolute inset-0 left-[-21rem] top-[-4rem] bg-transparent">
            <svg
              viewBox="0 0 300 300"
              xmlns="http://www.w3.org/2000/svg"
              className="h-full w-full"
              preserveAspectRatio="none"
            >
              <polygon
                points="0,33 276,97 276,194 0,254 42,242 42,77"
                style={{
                  fill: `${sideDesignBgColor}`,
                  strokeWidth: '3',
                }}
              />
            </svg>
            <div className="relative right-[2rem] top-[-100%] h-full w-full">
              <p className="absolute right-[20%] top-[33%] flex items-center gap-x-2 text-white">
                <FaRegEnvelope size={20} className="mt-1" />
                <span>{formData?.email}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-8">
          <div className="relative">
            <div className="absolute right-0 top-0">
              <svg
                width="150"
                height="150"
                viewBox="0 0 150 150"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon
                  points="0,0 150,0 150,150"
                  style={{ fill: `${sideDesignBgColor}`, strokeWidth: '3' }}
                />
              </svg>
            </div>
          </div>
          <section className="mt-8 pl-10 pr-10">
            <h1
              className={`text-3xl ${headingColor} ${fontFamily} ${textTransform}`}
            >
              {formData?.name || 'John Doe'}
            </h1>
            <p className={`text-lg ${contentColor} ${fontFamily}`}>
              {formData?.jobTitle || 'Job Title'}
            </p>
          </section>

          <section className="mt-8 pl-10 pr-10">
            <h2
              className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
            >
              Personal Information
            </h2>
            <p className={`text-lg ${contentColor} ${fontFamily}`}>
              Address: {formData?.address}
            </p>
            <p className={`text-lg ${contentColor} ${fontFamily}`}>
              Phone: {formData?.phone}
            </p>
            <p className={`text-lg ${contentColor} ${fontFamily}`}>
              Email: {formData?.email}
            </p>
          </section>

          {formData?.education?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Educational Background
              </h2>
              {formData.education.map((edu) => (
                <div
                  key={edu._id}
                  className={`text-lg ${contentColor} ${fontFamily}`}
                >
                  <p>
                    {edu.fieldOfStudy} - {edu.instituteName}
                  </p>
                  <p>
                    {new Date(edu.startDate).toLocaleDateString()} to{' '}
                    {new Date(edu.endDate).toLocaleDateString()}
                  </p>
                  <p>{edu.location}</p>
                  <p>{edu.description}</p>
                </div>
              ))}
            </section>
          )}

          {formData?.experience?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Work Experience
              </h2>
              {formData.experience.map((exp) => (
                <div
                  key={exp._id}
                  className={`text-lg ${contentColor} ${fontFamily}`}
                >
                  <p>
                    {exp.position} at {exp.companyName}
                  </p>
                  <p>
                    {new Date(exp.startDate).toLocaleDateString()} to{' '}
                    {new Date(exp.endDate).toLocaleDateString()}
                  </p>
                  <p>{exp.location}</p>
                  <p>{exp.description}</p>
                </div>
              ))}
            </section>
          )}

          {formData?.certifications?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Certifications
              </h2>
              {formData.certifications.map((cert) => (
                <div
                  key={cert._id}
                  className={`text-lg ${contentColor} ${fontFamily} mb-4`}
                >
                  <h3>{cert.courseName}</h3>
                  <p className="italic">{cert.authority}</p>
                  <p>{new Date(cert.courseDate).toLocaleDateString()}</p>
                  {cert.description && <p>{cert.description}</p>}
                </div>
              ))}
            </section>
          )}

          {formData?.skills?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Skills
              </h2>
              {formData.skills.map((skill) => (
                <div
                  key={skill._id}
                  className={`text-lg ${contentColor} ${fontFamily}`}
                >
                  {skill.skills}
                </div>
              ))}
            </section>
          )}

          {formData?.languages?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Languages
              </h2>
              {formData.languages.map((language) => (
                <div
                  key={language._id}
                  className={`text-lg ${contentColor} ${fontFamily}`}
                >
                  {language.languages}
                </div>
              ))}
            </section>
          )}

          {formData?.hobbies?.length > 0 && (
            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Hobbies
              </h2>
              {formData.hobbies.map((hobby) => (
                <div
                  key={hobby._id}
                  className={`text-lg ${contentColor} ${fontFamily}`}
                >
                  {hobby.hobbies}
                </div>
              ))}
            </section>
          )}

          <section className=" pl-10 pr-10">
            <div className="flex items-center justify-between border-b-2 border-gray-300">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
              >
                Languages
              </h2>
              <IoLanguageSharp
                className={`size-6 text-blue-500  ${headingColor} ${fontFamily} ${textTransform}`}
              />
            </div>
            <div className="mt-2">
              <ul className="">
                {formData?.languages?.map((language, index) => (
                  <li
                    key={index}
                    className={`text-lg ${contentColor} ${fontFamily} capitalize`}
                  >
                    {language?.languages}
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section className="mt-8 pl-10 pr-10">
            <h2
              className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
            >
              Personal Skills
            </h2>
            <div className="mt-4 flex flex-col gap-5">
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.leadership}
                title={'Leadership'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.communication}
                title={'Communication'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.selfMotivation}
                title={'Self Motivation'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.decisionMaking}
                title={'Decision Making'}
              />
            </div>
          </section>

          <div className="mt-12 w-full">
            <section className="mt-8 pl-10 pr-10">
              <div className="flex items-center justify-between border-b-2 border-gray-300">
                <h2
                  className={`text-2xl ${headingColor} ${fontFamily} ${textTransform}`}
                >
                  Time Distribution
                </h2>
                <TbCertificate
                  className={`size-6  text-blue-500  ${headingColor} ${fontFamily} ${textTransform}`}
                />
              </div>

              <div className="relative mt-4 h-72 w-96">
                <Doughnut data={chartData} options={options} />
              </div>
              {/*<div className="mr-16 mt-16">*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Working: {timeDistribution.working || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Family: {timeDistribution.family || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Co-ordination: {timeDistribution.coordination || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Entertainment: {timeDistribution.entertainment || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Relaxing: {timeDistribution.relaxing || 0}%</p>*/}
              {/*</div>*/}
            </section>
          </div>
        </div>
      </div>

      <CustomizationPanel
        setSideDesignBgColor={setSideDesignBgColor}
        setSecondarySideDesignBgColor={setSecondarySideDesignBgColor}
        setHeadingColor={setHeadingColor}
        setContentColor={setContentColor}
        setFontFamily={setFontFamily}
        setTextTransform={setTextTransform}
        fontFamily={fontFamily}
        textTransform={textTransform}
        generatePdf={generatePdf}
      />
    </div>
  );
};

export default CVTemplate2;
