import { useState } from 'react';
import InputFile from '../../../../components/ui/InputFile';

export const PersonalInformation = ({
  setPersonalInformationData,
  personalInformationData,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalInformationData({ ...personalInformationData, [name]: value });
  };

  const handleOtherValue = (key, value) => {
    setPersonalInformationData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="mx-auto line-clamp-1 flex min-w-[150px] max-w-[150px] flex-col flex-wrap content-center justify-center text-ellipsis">
        {personalInformationData?.imageUrl ? (
          <div className="flex">
            <div className="relative left-[4.7rem] flex h-[150px] w-[150px] align-middle">
              <img
                src={personalInformationData?.imageUrl}
                alt="Uploaded Image"
                className="h-auto w-[150px] rounded-full object-cover align-middle"
              />
            </div>
            <div className="relative inset-0 left-[-4.7rem] flex h-[150px] w-[150px] items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100">
              <InputFile
                name="imageUrl"
                onChange={handleOtherValue}
                cv={true}
              />
              <label
                htmlFor="dropzone-file"
                className="flex h-[150px] w-[150px] cursor-pointer items-center justify-center"
              ></label>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="relative left-[4.7rem] flex h-[150px] w-[150px] align-middle">
              <img
                src="/dummyImg.jpg"
                alt="user"
                className="mr-4 h-[150px] w-[150px] cursor-pointer rounded-full"
              />
            </div>
            <div className="relative inset-0 left-[-4.7rem] flex h-[150px] w-[150px] items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100">
              <InputFile
                name="imageUrl"
                onChange={handleOtherValue}
                cv={true}
              />
              <label
                htmlFor="dropzone-file"
                className="flex h-[150px] w-[150px] cursor-pointer items-center justify-center"
              ></label>
            </div>
          </div>
        )}
      </div>
      <div className="w-full text-center">
        <p className="my-0 text-ellipsis text-center text-lg uppercase">
          {personalInformationData?.name}
        </p>
        <p className="mt-0 text-center text-sm">
          {personalInformationData?.jobTitle}
        </p>
      </div>
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={personalInformationData?.name}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
      <input
        type="text"
        name="jobTitle"
        placeholder="Job Title"
        value={personalInformationData?.jobTitle}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
      <input
        type="text"
        name="summary"
        placeholder="Summary"
        value={personalInformationData?.summary}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
      <input
        type="text"
        name="address"
        placeholder="Address"
        value={personalInformationData?.address}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
      <input
        type="text"
        name="phone"
        placeholder="Phone"
        value={personalInformationData?.phone}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={personalInformationData?.email}
        onChange={handleChange}
        className="w-full rounded border p-2"
        required
      />
    </>
  );
};
