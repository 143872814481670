import { apiSlice } from "../api/apiSlice";

export const cardsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCards: builder.query({
      query: () => ({
        url: "/cards",
        method: "GET",
      }),
      providesTags: ["cards"],
    }),

    getCard: builder.query({
      query: (id) => ({
        url: `/cards/${id}`,
        method: "GET",
      }),
      providesTags: ["cards"],
    }),

    getCardQr: builder.query({
      query: (id) => ({
        url: `/cards/qr-re-generate/${id}`,
        method: "GET",
      }),
    }),

    createBusinessBranch: builder.mutation({
      query: (data) => ({
        url: "/users/create/businessBranch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cards"],
    }),

    editCard: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/dashboard/update-profile/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["cards"],
    }),

    deleteCard: builder.mutation({
      query: (id) => ({
        url: `/cards/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cards"],
    }),
  }),
});

export const {
  useGetCardsQuery,
  useGetCardQuery,
  useGetCardQrQuery,
  useCreateBusinessBranchMutation,
  useEditCardMutation,
  useDeleteCardMutation,
} = cardsApi;
