import React, { useEffect } from 'react';
import { FaArrowLeft as ArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import PlanComparisonPage from '../modules/subscription/PlanComparisonPage';
import {
  useGetAllUserFeaturesQuery,
  useGetCurrentPurchasedPlanQuery,
} from '../redux/features/subscriptions/subscriptionsApi';

const SubscriptionLayout = (card) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, error, isLoading, refetch } = useGetAllUserFeaturesQuery();
  const {
    data: currentPlanData,
    isLoading: currentPlanLoading,
    refetch: refetchCurrentPlan,
  } = useGetCurrentPurchasedPlanQuery();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    refetch();
    refetchCurrentPlan();
  }, [location.pathname]);

  return (
    <div className="flex min-h-screen flex-col bg-gray-100">
      <div className="mx-auto flex w-full  flex-col rounded-lg bg-white p-6 shadow-current">
        <div className="z-1 mb-10 flex items-center justify-between">
          <ArrowLeft
              className="h-6 w-6 cursor-pointer text-gray-600"
              onClick={handleBackClick}
          />
          <h1 className="text-[24px] text-[#22C55E]  font-bold">Socalii</h1>
          <div className="font-semibold text-[#334155]">Plans</div>
        </div>
        <h1 className="text-center text-[#1e1e2d] text-[35px] font-bold">
          Choose Your Plan
        </h1>
        <h1 className="text-center mt-2 text-[#1e1e2d] text-[15px] font-semibold">
          Choose the best plan that fits you best
        </h1>
        <div className="no-scrollbar flex-grow overflow-hidden overflow-y-auto">
          {isLoading ? (
              <Loading/>
          ) : (
              <PlanComparisonPage
                  data={data?.data}
                  subscriptionId={currentPlanData?.data?.subscriptionId}
                  subscribedPlanName={currentPlanData?.data?.planName}
              />
          )}
        </div>

        {/*<Button className="mb-4 mt-4 w-full rounded-lg bg-blue-600 py-3 font-semibold text-white">*/}
        {/*  Start Free Trial*/}
        {/*</Button>*/}

        {/*<p className="text-center text-sm text-gray-600">*/}
        {/*  Cancel anytime. Plan renews automatically*/}
        {/*</p>*/}
      </div>
    </div>
  );
};

export default SubscriptionLayout;
