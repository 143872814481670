import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetOrderListsQuery } from '../../../redux/features/products/productsApi';

const OrderPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1, // Start on page 1
    limit: 10,
  });
  const { data, isLoading, isError } = useGetOrderListsQuery({ ...paginate });
  console.log('this one');
  if (isError) {
    return (
      <div className="space-y-6">
        <Card title="Orders List">
          <p className="text-red-500">
            Failed to fetch orders. Please try again later.
          </p>
        </Card>
      </div>
    );
  }

  const content = {
    header: [
      'User Email',
      'User Phone',
      'User Address',
      'Tracking ID',
      'Order Date',
      'Actions',
    ],

    body: data?.data?.results?.map((item) => ({
      rows: [
        { row: item?.buyerEmail },
        { row: item?.buyerPhoneNumber },
        { row: item.buyerAdress },
        { row: item?.trackingOrderId },
        { row: new Date(item?.createdAt).toLocaleDateString() },
        {
          row: (
            <Button
              link={`/order/${item?._id}`}
              className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 "
            >
              <FaEye />
            </Button>
          ),
        },
      ],
    })),
    body:
      data?.data?.results?.map((item) => ({
        rows: [
          { row: item?.buyerEmail || 'N/A' },
          { row: item?.buyerPhoneNumber || 'N/A' },
          { row: item?.buyerAdress || 'N/A' }, // Correct this to match API spelling.
          { row: item?.trackingOrderId || 'N/A' },
          {
            row: item?.createdAt
              ? new Date(item?.createdAt).toLocaleDateString()
              : 'N/A',
          },
          {
            row: (
              <Button
                link={`/order/${item?._id}`}
                className="flex h-[40px] w-[60px] items-center justify-center !bg-blue-100"
              >
                <FaEye />
              </Button>
            ),
          },
        ],
      })) || [],
  };

  return (
    <div className="space-y-6">
      <Card title="Orders List">
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <Table
            content={content}
            pagination={{
              currentPage: data?.data?.currentPage,
              totalPage: data?.data?.totalPages,
              totalCount: data?.data?.totalOrders,
            }}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default OrderPage;
