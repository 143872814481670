import { apiSlice } from '../api/apiSlice';

export const subscriptionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query({
      query: () => ({
        url: '/subscriptions/plans',
        method: 'GET',
      }),
      providesTags: ['subscriptions'],
    }),

    getSubscription: builder.query({
      query: (id) => ({
        url: `/subscriptions/plans/${id}`,
        method: 'GET',
      }),
      providesTags: ['subscriptions'],
    }),

    createSubscription: builder.mutation({
      query: (data) => ({
        url: '/subscriptions/plans',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['subscriptions'],
    }),

    editSubscription: builder.mutation({
      query: ({ id, data }) => ({
        url: `/subscriptions/plans/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['subscriptions'],
    }),

    deleteSubscription: builder.mutation({
      query: (id) => ({
        url: `/subscriptions/plans/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['subscriptions', 'plans'],
    }),

    // features for admin side
    getAllFeatures: builder.query({
      query: (type) => ({
        url: `/subscriptions/plans/fetchFeature/${type}`,
        method: 'GET',
      }),
      providesTags: ['features'],
    }),

    // features for user side
    getAllUserFeatures: builder.query({
      query: () => ({
        url: `/subscriptions/plans/featurelist`,
        method: 'GET',
      }),
      providesTags: ['userFeatures'],
    }),

    // get plns of a feature
    getPlansByFeatureId: builder.query({
      query: (featureId) => ({
        url: `/subscriptions/plans/byfeatureid/${featureId}`,
        method: 'GET',
      }),
      providesTags: ['feature-plans'],
    }),

    // purchase plan
    purchasePlan: builder.mutation({
      query: (data) => ({
        url: '/subscriptions/plans/checkoutPayments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PurchasedFeatures', 'purchase-subscriptions'],
    }),

    // get history of all purchased plan
    getPurchaseHistory: builder.query({
      query: () => ({
        url: '/subscriptions/plans/payed/SubscriptionHistory',
        method: 'GET',
      }),
      providesTags: ['purchase-subscriptions'],
    }),

    // Amin get all plans
    getAllPlansOfAdmin: builder.query({
      query: () => ({
        url: '/subscriptions/plans/get/allSubscriptions',
        method: 'GET',
      }),
      providesTags: ['plans'],
    }),

    // patch for feature useage by feature id
    patchFeatureUsage: builder.mutation({
      query: (id) => ({
        url: `/subscriptions/plans/update/user/Usage/featureId/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['PurchasedFeatures'],
    }),

    // get all features
    getAllFeaturesOfApplication: builder.query({
      query: () => ({
        url: '/subscriptions/plans/fetch/Bussiness/Individual/',
        method: 'GET',
      }),
      providesTags: ['features'],
    }),

    // get features useage
    getFeatureUsage: builder.query({
      query: () => ({
        url: '/subscriptions/plans/userUsage/subscriptions',
        method: 'GET',
      }),
      providesTags: ['PurchasedFeatures'],
    }),

    // get active current purchased plan
    getCurrentPurchasedPlan: builder.query({
      query: () => ({
        url: '/subscriptions/plans/fetch/activeSubscription/user',
        method: 'GET',
      }),
      providesTags: ['currentPurchasedPlan'],
    }),

    // GET ALL FEATURES BY FEATURE MNAME
    getFeaturesByFeatureName: builder.query({
      query: (featureName) => ({
        url: `/subscriptions/plans/get/alldata/featureName/${featureName}`,
        method: 'GET',
      }),
      providesTags: ['features'],
    }),

    // dete feature by feature name and feature id
    deleteFeature: builder.mutation({
      query: ({ featureName, featureId }) => ({
        url: `/subscriptions/plans/remove/features/quantity/FeatureName/${featureName}/${featureId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['features', 'plans'],
    }),

    // upgrade plan
    upgradePlan: builder.mutation({
      query: (data) => ({
        url: '/subscriptions/plans/upgrade/checkoutPayments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'PurchasedFeatures',
        'purchase-subscriptions',
        'userFeatures',
      ],
    }),

    // get all branches of a user by suer id
    getBranchesByUserId: builder.query({
      query: (username) => ({
        url: `/users/get/allBranches/userName/${username}`,
        method: 'GET',
      }),
    }),

    // get all and not deleted features
    getAllFeaturesNotDeleted: builder.query({
      query: () => ({
        url: '/subscriptions/plans/all-data/not-deleted/by/user',
        method: 'GET',
      }),
      providesTags: ['features'],
    }),

    // aSSIGEN SUBSCRIPTION TO USER FROM ADMIN SIDE
    assignSubscriptionToUser: builder.mutation({
      query: (data) => ({
        url: '/subscriptions/plans/checkoutPayments-on-adminSide',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionQuery,
  useCreateSubscriptionMutation,
  useEditSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetAllFeaturesQuery,
  useGetAllUserFeaturesQuery,
  useGetPlansByFeatureIdQuery,
  usePurchasePlanMutation,
  useGetPurchaseHistoryQuery,
  useGetAllPlansOfAdminQuery,
  usePatchFeatureUsageMutation,
  useGetAllFeaturesOfApplicationQuery,
  useGetFeatureUsageQuery,
  useGetCurrentPurchasedPlanQuery,
  useGetFeaturesByFeatureNameQuery,
  useDeleteFeatureMutation,
  useUpgradePlanMutation,
  useGetBranchesByUserIdQuery,
  useGetAllFeaturesNotDeletedQuery,
  useAssignSubscriptionToUserMutation,
} = subscriptionsApi;

// hook fro useAssignSubscriptionToUserMutation
