import React from 'react';

const CustomizationPanel = ({
  setHeadingColor,
  setContentColor,
  setFontFamily,
  setTextTransform,
  fontFamily,
  textTransform,
  generatePdf,
  setSideDesignBgColor,
  setSecondarySideDesignBgColor,
}) => {
  return (
    <div className="w-1/4 space-y-4 border-2  border-black p-4 print:hidden">
      <div className="flex items-center justify-center">
        <button
          onClick={() => generatePdf()}
          className="rounded-full bg-blue-500 p-2 text-white shadow-md transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 print:hidden"
        >
          Download
        </button>
      </div>

      <h3 className="text-2xl font-semibold text-blue-950">Colors</h3>

      <div className="mb-6">
        <p className="mb-4">Select Heading Color</p>
        <div className="grid grid-cols-4 gap-2">
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-black"
            onClick={() => {
              setHeadingColor('text-black');
              setSideDesignBgColor('#1c2434');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-blue-500"
            onClick={() => {
              setHeadingColor('text-blue-500');
              setSideDesignBgColor('#3b82f6');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-red-500"
            onClick={() => {
              setHeadingColor('text-red-500');
              setSideDesignBgColor('#ef4444');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-green-500"
            onClick={() => {
              setHeadingColor('text-green-500');
              setSideDesignBgColor('#22c55e');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-gray-500"
            onClick={() => {
              setHeadingColor('text-gray-500');
              setSideDesignBgColor('#6b7280');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-orange-500"
            onClick={() => {
              setHeadingColor('text-orange-500');
              setSideDesignBgColor(' #f97316');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-purple-500"
            onClick={() => {
              setHeadingColor('text-purple-500');
              setSideDesignBgColor('#a855f7');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-pink-500"
            onClick={() => {
              setHeadingColor('text-pink-500');
              setSideDesignBgColor('#ec4899');
            }}
          />
        </div>
      </div>

      <div>
        <p className="mb-4">Select Content Color</p>
        <div className="grid grid-cols-4 gap-2">
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-black"
            onClick={() => {
              setContentColor('text-black');
              setSecondarySideDesignBgColor('#1c2434');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-blue-500"
            onClick={() => {
              setContentColor('text-blue-500');
              setSecondarySideDesignBgColor('#3b82f6');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-red-500"
            onClick={() => {
              setContentColor('text-red-500');
              setSecondarySideDesignBgColor('#ef4444');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-green-500"
            onClick={() => {
              setContentColor('text-green-500');
              setSecondarySideDesignBgColor('#22c55e');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-gray-500"
            onClick={() => {
              setContentColor('text-gray-500');
              setSecondarySideDesignBgColor('#6b7280');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-orange-500"
            onClick={() => {
              setContentColor('text-orange-500');
              setSecondarySideDesignBgColor('#f97316');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-purple-500"
            onClick={() => {
              setContentColor('text-purple-500');
              setSecondarySideDesignBgColor('#a855f7');
            }}
          />
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-pink-500"
            onClick={() => {
              setContentColor('text-pink-500');
              setSecondarySideDesignBgColor('#ec4899');
            }}
          />
        </div>
      </div>

      <h4 className="mt-4 text-2xl font-semibold text-blue-950">Font Style</h4>
      <div className="grid gap-2">
        <button
          className={`rounded border px-4 py-2 font-sans ${
            fontFamily === 'font-sans' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setFontFamily('font-sans')}
        >
          Sans-serif
        </button>
        <button
          className={`rounded border px-4 py-2 font-serif ${
            fontFamily === 'font-serif' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setFontFamily('font-serif')}
        >
          Serif
        </button>
        <button
          className={`rounded border px-4 py-2 font-mono ${
            fontFamily === 'font-mono' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setFontFamily('font-mono')}
        >
          Monospace
        </button>
      </div>

      <h4 className="mt-4 text-2xl font-semibold text-blue-950">
        Text Transformation
      </h4>
      <div className="grid gap-2">
        <button
          className={`rounded border px-4 py-2 ${
            textTransform === 'normal-case' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setTextTransform('normal-case')}
        >
          Normal
        </button>
        <button
          className={`rounded border px-4 py-2 uppercase ${
            textTransform === 'uppercase' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setTextTransform('uppercase')}
        >
          Uppercase
        </button>
        <button
          className={`rounded border px-4 py-2 lowercase ${
            textTransform === 'lowercase' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setTextTransform('lowercase')}
        >
          Lowercase
        </button>
        <button
          className={`rounded border px-4 py-2 capitalize ${
            textTransform === 'capitalize' ? 'bg-gray-300' : ''
          }`}
          onClick={() => setTextTransform('capitalize')}
        >
          Capitalize
        </button>
      </div>
    </div>
  );
};

export default CustomizationPanel;
