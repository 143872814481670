import React from 'react';
import CreateBusinessForm from './CreateBusinessForm';

const CreateBusiness = () => {
  return (
    <div>
      <CreateBusinessForm />
    </div>
  );
};

export default CreateBusiness;
