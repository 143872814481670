import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaPenClip } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../components/Table';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import {
  useGetAllFeaturesNotDeletedQuery,
  useGetFeatureUsageQuery,
} from '../../redux/features/subscriptions/subscriptionsApi';
import { capitalizeFirstLetter } from '../../utils/dataFormater';

function TotalPlans() {
  const location = useLocation();
  const { subscribedFeatures, newFeatures, expiryDateOfPreviousPlan } =
    location?.state?.plan;
  const { data, isLoading } = useGetFeatureUsageQuery();
  const { data: notDeleteFeatures, refetch } =
    useGetAllFeaturesNotDeletedQuery();
  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const navigate = useNavigate();
  const combinedFeatures = [
    ...subscribedFeatures.map((feature) => ({
      ...feature,
      isAlreadyPurchased: false,
    })),
    // ...newFeatures.map((feature) => ({
    //   ...feature,
    //   isAlreadyPurchased: true,
    // })),
  ];

  /**
   * Utility function to match two arrays and validate quantities.
   *
   * @param {Array} array1 - First array with featureName and quantity.
   * @param {Array} array2 - Second array with featureName and quantity.
   * @returns {Array} - Array of messages like 'Please delete ${featureName}'.
   */
  function validateFeatureQuantities(array1, array2) {
    const messages = [];

    array1.forEach((item1) => {
      const matchedItem = array2.find(
        (item2) =>
          item2.featureName.toLowerCase() === item1.featureName.toLowerCase()
      );

      if (matchedItem && item1.quantity > matchedItem.quantity) {
        messages.push(`Please delete ${item1.featureName}`);
      }
    });

    return messages;
  }
  const content = {
    header: ['Feature Name', 'Actions'],
    body: combinedFeatures.map((item) => ({
      rows: [
        { row: capitalizeFirstLetter(item.featureName) },
        {
          row: (
            <>
              {item.isAlreadyPurchased ? null : (
                <Button
                  link={`/downgrade-feature/${item.featureId}/?featureName=${
                    item.featureName
                  }&&quantityOfNewFeatures=${
                    newFeatures.find((f) => f.featureName === item.featureName)
                      .quantity || 0
                  }`}
                  className="flex h-[30px] w-[50px] items-center justify-center  !bg-blue-100 "
                >
                  <FaPenClip />
                </Button>
              )}
            </>
          ),
        },
      ],
    })),
  };

  // const handelDownGrade = () => {
  //   console.log('-------------messges---------------');
  //   console.log(
  //     validateFeatureQuantities(notDeleteFeatures?.result, newFeatures)
  //   );
  //   console.log('-------------messges---------------');
  //   if (validateFeatureQuantities(notDeleteFeatures?.result, newFeatures)) {
  //     return toast.error(
  //       validateFeatureQuantities(notDeleteFeatures?.result, newFeatures),
  //       'notDeleteFeatures'
  //     );
  //   }
  //   toast.success(
  //     `Your plan will be downgraded at ${expiryDateOfPreviousPlan.toDateString()}`
  //   );
  //   navigate('/subscription/features');
  // };

  const handelDownGrade = () => {
    const messages = validateFeatureQuantities(
      notDeleteFeatures?.result,
      newFeatures
    );

    if (messages.length > 0) {
      return toast.error(messages.join(', '), 'notDeleteFeatures');
    }

    toast.success(
      `Your plan will be downgraded at ${expiryDateOfPreviousPlan.toDateString()}`
    );
    navigate('/subscription/features');
  };

  return (
    <Card title="Remove Features">
      {/* <DebugView data={notDeleteFeatures?.result} /> */}
      ------------------------------------------------------------
      {/* <DebugView data={newFeatures} /> */}
      <Table content={content} pagination={null} />
      <Button onClick={handelDownGrade} className="mt-7">
        Downgrade Plan
      </Button>
    </Card>
  );
}

export default TotalPlans;
