import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillProduct } from 'react-icons/ai';
import { CiImageOn } from 'react-icons/ci';
import { FaSort, FaYoutube } from 'react-icons/fa';
import { GrCurrency } from 'react-icons/gr';
import { IoVideocamOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputField from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import MultiImageUpload from '../../../components/ui/MultiImageUpload';
import PreviewImg from '../../../components/ui/PreviewImg';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';
import {
  useCreateAdminProductsMutation,
  useGetAdminProductCategoriesQuery,
  useUpdateAdminProductMutation,
} from '../../../redux/features/adminShope/adminShopeApi';

const ProductForm = ({ data = {} }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState(data);
  const [createProduct, { isLoading }] = useCreateAdminProductsMutation();
  const [editProduct, { isLoading: isEditLoading }] =
    useUpdateAdminProductMutation();
  const { pathname } = useLocation();

  const [storeId, setStoreId] = useState(data?.store?._id);

  const { data: categoriesOptions, refetch: refetchCategoryOptions } =
    useGetAdminProductCategoriesQuery();

  useEffect(() => {
    if (categoriesOptions) {
      refetchCategoryOptions();
    }
  }, [categoriesOptions]);

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const haleCategoriesChange = (key, value) => {
    setValues((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleStoreChange = (key, value) => {
    // setValues((prev) => {
    //     return { ...prev, [key]: value };
    // });
    setStoreId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values?.categories) {
      return toast.error('Please select a category');
    }
    if (!values?.coverImgUrl) {
      return toast.error('Please upload a cover image');
    }

    try {
      const API = data?._id
        ? editProduct({ id: data?._id, data: { ...values, storeId } })
        : createProduct({ ...values, storeId });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/admin/products');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  function transformResultsToLabelId(data = {}) {
    return data?.results?.map((item) => ({
      label: item.title.en, // Assuming "en" is the desired language for the label
      value: item._id, // Using `_id` as the unique identifier
    }));
  }

  const categoriesOptionsData = transformResultsToLabelId(
    categoriesOptions?.data
  );

  useEffect(() => {
    if (!data?._id) {
      setValues((prev) => {
        return { ...prev, currency: 'OMR' };
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {/* {JSON.stringify(profileData?.data?.role)} */}
      <Card
        title={`${data?._id ? 'Edit' : 'Create New'} Product`}
        content={
          <Button
            link="/admin/products"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Products
          </Button>
        }
        className="space-y-4"
      >
        {/* <SelectOptions
          icon={<FaPen />}
          placeholder="Select Category..."
          options={categoriesOptionsData}
          name="categories"
          value={values?.categories?._id}
          onChange={(name, selectedId) => {
            const selectedCategory = categoriesOptionsData?.find(
              (option) => option.id === selectedId
            );
            haleCategoriesChange(name, selectedCategory);
          }}
        /> */}
        <SelectOptions
          placeholder="Select Category..."
          options={categoriesOptionsData}
          name="categories"
          value={values?.categories} // Ensure this is the correct state value
          onChange={onChange}
        />
        <InputField
          placeholder="Currency"
          name="currency"
          value={values?.currency}
          onChange={onChange}
          icons={<GrCurrency />}
          disabled={true}
        />

        <InputField
          name="title"
          subKey="en"
          placeholder="Product Name (ENGLISH)"
          icon={<AiFillProduct />}
          value={values?.title?.en}
          onChange={onChange}
          isRequired={true}
        />

        <InputField
          name="title"
          subKey="ar"
          placeholder="Product Name (ARABIC)"
          icon={<AiFillProduct />}
          value={values?.title?.ar}
          onChange={onChange}
        />

        <TextAreaField
          label="Description"
          name="description"
          subKey="en"
          placeholder="Product Description (ENGLISH)"
          value={values?.description?.en}
          onChange={onChange}
        />
        <TextAreaField
          label="Description"
          name="description"
          subKey="ar"
          placeholder="Product Description (ARABIC)"
          value={values?.description?.ar}
          onChange={onChange}
        />

        <InputField
          name="price"
          placeholder="Product Price"
          type="number"
          icon={<GrCurrency />}
          value={values?.price}
          onChange={onChange}
          isRequired={true}
        />
        <InputField
          name="youtubeVideoUrl"
          placeholder="Video Link"
          icon={<FaYoutube />}
          value={values?.youtubeVideoUrl}
          onChange={onChange}
        />
        <InputField
          name="sortOrder"
          type="number"
          placeholder="Sort Order"
          icon={<FaSort />}
          value={values?.sortOrder}
          onChange={onChange}
        />

        <Card>
          <PreviewImg src={values?.coverImgUrl} isBanner />
          <InputFile
            title="Cover Image"
            icon={<CiImageOn />}
            name="coverImgUrl"
            onChange={onChange}
            isBanner
          />
        </Card>
        <Card>
          <InputFile
            title="Video"
            icon={<IoVideocamOutline />}
            name="videoUrl"
            onChange={onChange}
            fileTypes="video/*"
          />
        </Card>
        <Card>
          {/* <PreviewImg src={values?.otherImgUrl} />
          <InputFile
            title="Others Image"
            icon={<CiImageOn />}
            name="otherImgUrl"
            onChange={onChange}
          /> */}
          <MultiImageUpload
            name="otherImgUrl"
            value={values.otherImgUrl}
            onChange={onChange}
          />
        </Card>

        <Button type="submit" className="!bg-green-400">
          {isEditLoading || isLoading ? (
            <Loading variant="button" height="20px" />
          ) : (
            'Save'
          )}
        </Button>
      </Card>
    </form>
  );
};

export default ProductForm;
