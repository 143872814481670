import React from 'react';

const Card = ({ children, className, title, content, contentStyle }) => {
  return (
    <div className="w-full rounded-lg bg-white pb-2 shadow dark:bg-black-100 dark:text-white">
      {(title || content) && (
        <div className="flex items-center justify-between border-b p-4 px-5 dark:border-black-300">
          {title && (
            <h3 className="text-[18px] font-bold text-black dark:text-white">
              {title}
            </h3>
          )}

          {content && <div className={`${contentStyle}`}>{content}</div>}
        </div>
      )}
      <div className={`${className} p-5`}>{children}</div>
    </div>
  );
};

export default Card;
