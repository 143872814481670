import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderByOrderIdQuery } from '../../../../redux/features/orders/ordersApi';
import SingleOrderView from './AdminOrdersPage';

const AdminOrderPreview = () => {
  const { id } = useParams();
  const { data = {} } = useGetOrderByOrderIdQuery(id);
  const { data: orderData, isLoading } = useGetPaidShopOrderListsQuery();
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  console.log(data, orderData);
  const content = {
    header: [
      'Item Image',
      'Item Name',
      'Item Quantity',
      'Item Price',
      'Item Total',
    ],
    body: data?.data?.orderLists.map((item) => ({
      rows: [
        {
          row: <img src={item.itemImage} alt="Order item" width={70} />,
        },
        { row: item.itemName },
        { row: item.itemQuantity },
        { row: item.itemPrice },
        { row: item.itemPrice * item.itemQuantity },
      ],
    })),
    footer: [
      {
        row: `Total Order Price: ${data?.data?.TotalOrderPrice}`,
      },
    ],
  };
  return (
    <div>
      <SingleOrderView
        content={content}
        paginate={paginate}
        setPaginate={setPaginate}
      />
    </div>
  );
};

export default AdminOrderPreview;
