import { capitalize } from 'lodash';
import React from 'react';
import toast from 'react-hot-toast';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import features from '../../../constants/fetures';
import {
  useDeleteFeatureMutation,
  useGetFeaturesByFeatureNameQuery,
} from '../../../redux/features/subscriptions/subscriptionsApi';
import {
  capitalizeFirstLetter,
  transformBranchData,
  transformCarData,
  transformContactData,
  transformPostData,
  transformStoreData,
} from '../../../utils/dataFormater';

const DowngradeFeatures = () => {
  const [searchParams] = useSearchParams();
  const paramsFeatureName = searchParams.get('featureName');
  const navigate = useNavigate();
  const paramsNewFeatureQuantity = Number(
    searchParams.get('quantityOfNewFeatures')
  );

  const {
    data: featureData,
    isLoading,
    refetch,
  } = useGetFeaturesByFeatureNameQuery(paramsFeatureName);
  const [
    deleteFeature,
    { isLoading: deleteFeatureLoading, isSuccess, isError },
  ] = useDeleteFeatureMutation();
  const handleRemoveFeature = async (id) => {
    try {
      const response = await deleteFeature({
        featureName: paramsFeatureName,
        featureId: id,
      });
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        window.location.reload();
      }
    } catch (error) {
      toast.error(error?.error?.data?.message);
    }
  };
  let data = [];

  if (paramsFeatureName === features.STORE) {
    data = transformStoreData(featureData?.data);
  } else if (
    paramsFeatureName === features.CARDS ||
    paramsFeatureName === features.EMPLOYEE
  ) {
    data = transformBranchData(featureData?.data);
  } else if (paramsFeatureName === features.POSTS) {
    data = transformPostData(featureData?.data);
  } else if (paramsFeatureName === features.CARS) {
    data = transformCarData(featureData?.data);
  } else if (paramsFeatureName === features.CONTACTS) {
    data = transformContactData(featureData?.data);
  }

  let headerArray = ['Name', 'email', 'Actions'];
  let body;

  if (
    paramsFeatureName === features.CARDS ||
    features.STORE ||
    features.EMPLOYEE
  ) {
    body = data.map((item) => ({
      rows: [
        { row: capitalizeFirstLetter(item?.name) },
        { row: item?.email },
        {
          row: (
            <Button
              onClick={() => handleRemoveFeature(item?.id)}
              className="flex h-[30px] w-[50px] items-center justify-center  !bg-rose-600 "
            >
              <RiDeleteBin6Line />
            </Button>
          ),
        },
      ],
    }));
  }
  if (paramsFeatureName === features.POSTS) {
    body = data.map((item) => ({
      rows: [
        {
          row: item.image ? (
            <img src={item.image} alt="post" className="h-10 w-10" />
          ) : (
            'N/A'
          ),
        },
        { row: item.content },
        {
          row: (
            <Button
              onClick={() => handleRemoveFeature(item.id)}
              className="flex h-[30px] w-[50px] items-center justify-center  !bg-rose-600 "
            >
              <RiDeleteBin6Line />
            </Button>
          ),
        },
      ],
    }));

    headerArray = ['Image', 'Content', 'Actions'];
  } else if (paramsFeatureName === features.CARS) {
    body = data.map((item) => ({
      rows: [
        {
          row: item.image ? (
            <img src={item?.image} alt="car" className="h-10 w-10" />
          ) : (
            'N/A'
          ),
        },
        { row: item?.name },
        {
          row: (
            <Button
              onClick={() => handleRemoveFeature(item?.id)}
              className="flex h-[30px] w-[50px] items-center justify-center  !bg-rose-600 "
            >
              <RiDeleteBin6Line />
            </Button>
          ),
        },
      ],
    }));

    headerArray = ['Image', 'Name', 'Actions'];
  }
  const content = {
    header: headerArray,
    body: body,
  };
  return (
    <Card
      title={`Remove ${capitalize(paramsFeatureName)}`}
      content={
        data?.length - paramsNewFeatureQuantity > 0
          ? `Remove at least ${
              data?.length - paramsNewFeatureQuantity
            }  ${capitalize(paramsFeatureName)}`
          : ''
      }
    >
      <Table content={content} pagination={null} />
      <Button
        className="mt-10"
        isDisabled={featureData?.data?.length > paramsNewFeatureQuantity}
        onClick={() => navigate(-1)}
      >
        Save
      </Button>
    </Card>
  );
};

export default DowngradeFeatures;
