import { format, formatDistanceToNow } from 'date-fns';
import React from 'react';
import { FaEye } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import {
  useGetCurrentPurchasedPlanQuery,
  useGetFeatureUsageQuery,
  useGetPurchaseHistoryQuery,
} from '../../redux/features/subscriptions/subscriptionsApi';
import { getLatestObject } from '../../utils/helper';
import SubscriptionActions from './SubscriptionActions';

const SubscriptionHistoryPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetPurchaseHistoryQuery();
  const currentSubscriptionData = getLatestObject(data?.data);
  const { data: currentPlanData, isLoading: currentPlanLoading } =
    useGetCurrentPurchasedPlanQuery();

  const { data: usageData, isLoading: useAgeLoading } =
    useGetFeatureUsageQuery();
  const content = {
    header: ['Date', 'Type', 'Address'],
    body: data?.data?.map((item) => ({
      rows: [
        { row: formatDistanceToNow(item.createdAt, { addSuffix: true }) },
        { row: item.planName },
        { row: item.address || 'N/A' },
      ],
    })),
  };

  const usageContent = {
    header: ['Subscription Name', 'Date of Purchased', 'Actions'],

    body: usageData?.data?.map((item) => ({
      rows: [
        { row: item.subscriptionName },
        {
          row: format(new Date(item?.createdAt || ''), 'MMMM dd, yyyy'),
        },
        {
          row: (
            <Button
              link={`/subscription-features-by-id/${item?._id}`}
              className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 "
            >
              <FaEye />
            </Button>
          ),
        },
        // { row: item.remainingQuantity },
      ],
    })),
  };
  return (
    <div className="flex flex-col gap-5">
      <Card title="Subscription History">
        <p className="mb-6 text-left text-gray-600">
          Manage billing information and receipts
        </p>
        <div className="flex space-x-6">
          <div className="w-1/2">
            {isLoading ? (
              <h1>Loading...</h1>
            ) : (
              <Table content={content} pagination={null} />
            )}
          </div>

          <SubscriptionActions currentPlanData={currentPlanData?.data} />
        </div>
      </Card>

      <Card title="Subscriptions Usage">
        {useAgeLoading ? (
          <h1>Loading...</h1>
        ) : (
          <Table content={usageContent} pagination={null} />
        )}
      </Card>
    </div>
  );
};

export default SubscriptionHistoryPage;
