import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import {
  FaCopy,
  FaFacebookF,
  FaPlus,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { RiMessengerFill } from 'react-icons/ri';
import { SiMessenger } from 'react-icons/si';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import DialogModal from '../../../components/ui/DialogModal';

import {
  useDeleteCarMutation,
  useGetCarsQuery,
} from '../../../redux/features/cars/carsSlice';
import SingleCard from '../cards/SingleCard';

const AllCarsPage = () => {
  const { data, isLoading } = useGetCarsQuery();
  const [deleteCar, { isLoading: isDeletingCar }] = useDeleteCarMutation();
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState('');

  const handleDeleteApi = async (id) => {
    try {
      const result = await deleteCar(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('Car delete failed!');
    }
  };

  const handleDeleteCar = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this car?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: (url) =>
        `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
    },
    {
      icon: <MdEmail />,
      color: '#000000',
      platform: 'email',
      url: (url) => `mailto:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaFacebookF />,
      color: '#1877F2',
      platform: 'facebook',
      url: (url) =>
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
    },
    {
      icon: <RiMessengerFill />,
      color: '#0078FF',
      platform: 'messenger',
      url: (url) => `fb-messenger://share/?link=${encodeURIComponent(url)}`,
    },
    {
      icon: <SiMessenger />,
      color: '#FFA500',
      platform: 'sms',
      url: (url) => `sms:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaTwitter />,
      color: '#1DA1F2',
      platform: 'twitter',
      url: (url) =>
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    },
  ];

  const openShareModal = (url) => {
    setUrlToCopy(url);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="p-8">
      {isLoading ? (
        <Loading height="60vh" />
      ) : (
        <div>
          {data?.data?.results?.length > 0 ? (
            <div className="grid grid-cols-3 gap-5 ">
              {data?.data?.results?.map((car) => (
                <SingleCard
                  key={car?._id}
                  data={{
                    name: car?.brand?.name,
                    id: car?._id,
                    image: car?.qrImage,
                    model: car?.model,
                    year: car?.year,
                    price: car?.price,
                    username: car?.card?.user?.email,
                  }}
                  shareLink={() =>
                    openShareModal(
                      window.location.origin + `/cars/public-view/${car?._id}`
                    )
                  }
                  analyticLink={`/analytics/${car?._id}?cardType=car`}
                  viewLink={`/cars/view/${car?._id}`}
                  editLink={`/cars/edit/${car?._id}`}
                  deleteFunc={() => handleDeleteCar(car?._id)}
                  isDelete
                  loading={isDeletingCar ? 'delete' : ''}
                />
              ))}
              <div className="flex h-[420px] w-full max-w-[400px] items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-4">
                <Button
                  link="/cars"
                  className="flex flex-col items-center !bg-white !text-gray-400 transition duration-300 ease-in-out hover:!bg-gray-100 hover:!text-gray-600"
                >
                  <FaPlus size={52} />
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex h-[60vh] flex-col items-start justify-start">
              <div className="mb-6 flex items-center justify-center">
                <span>Data not available!</span>
              </div>
              <div className="flex h-[420px] w-full max-w-[400px] items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-4">
                <Button
                  link="/cars"
                  className="flex flex-col items-center !bg-white !text-gray-400 transition duration-300 ease-in-out hover:!bg-gray-100 hover:!text-gray-600"
                >
                  <FaPlus size={52} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {isOpenModal && (
        <DialogModal onClose={closeModal} title="Share My Profile" width="500">
          <div className="flex flex-col items-center gap-4 py-8">
            <div className="flex flex-wrap gap-4">
              {shareButtons.map((button) => (
                <button
                  key={button.platform}
                  onClick={() => window.open(button.url(urlToCopy), '_blank')}
                  className="rounded-full p-2 transition-transform hover:scale-110"
                  style={{ backgroundColor: button.color }}
                >
                  {React.cloneElement(button.icon, {
                    color: 'white',
                    size: 24,
                  })}
                </button>
              ))}
            </div>
            <input
              type="text"
              value={urlToCopy}
              readOnly
              className="w-full rounded-md border p-2 text-center"
              style={{ maxWidth: '400px', height: '80px' }}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(urlToCopy);
                toast.success('Link copied!');
              }}
              className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
            >
              <FaCopy size={16} />
              Copy Link
            </button>
          </div>
          <div className="flex justify-end pt-3">
            <button
              className="rounded bg-dark px-3 py-2 text-[14px] text-white"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </DialogModal>
      )}
    </div>
  );
};

export default AllCarsPage;
