import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/ui/Card';
import demoImg from '../../../images/shared/no-image.jpg';
import { useGetCategoriesByUserIdQuery } from '../../../redux/features/categories/categoryApi';
import { useGetProductsByStoreIdAndUserIdQuery } from '../../../redux/features/products/productsApi';
const ProductSection = ({ storeData, addToCart, isPublic, removeFromCart }) => {
  const { t } = useTranslation();
  // const { data: productsData } = useGetProductsQuery();
  const { data: productsData } = useGetProductsByStoreIdAndUserIdQuery({
    storeId: storeData?._id,
    userId: storeData?.user,
  });

  //   const { data: categories } = useGetCategoryOptionsQuery({});
  const { data: categories } = useGetCategoriesByUserIdQuery(storeData?.user);

  const [selectCategory, setSelectCategory] = useState('all');
  const [quantities, setQuantities] = useState(() => {
    const savedQuantities = localStorage.getItem('quantities');
    return savedQuantities ? JSON.parse(savedQuantities) : {};
  });

  const filteredProducts =
    productsData?.data?.results?.filter(
      (product) => product?.store?._id === storeData?._id
    ) || [];

  const filteredCategories =
    categories?.data?.filter((category) =>
      filteredProducts.some(
        (product) => product.categories[0]?._id === category._id
      )
    ) || [];

  const filterByCategory =
    selectCategory === 'all'
      ? filteredProducts
      : filteredProducts.filter(
          (product) => product?.categories[0]?._id === selectCategory
        );

  useEffect(() => {
    localStorage.setItem('quantities', JSON.stringify(quantities));
  }, [quantities]);

  const handleQuantityChange = (productId, increment) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 0;
      const newQuantity = increment
        ? currentQuantity + 1
        : Math.max(0, currentQuantity - 1);

      const updatedQuantities = { ...prevQuantities, [productId]: newQuantity };
      const productToUpdate = filteredProducts.find(
        (product) => product._id === productId
      );

      if (productToUpdate) {
        if (newQuantity > 0) {
          addToCart({ ...productToUpdate, quantity: newQuantity });
        } else {
          // Remove from cart if quantity is zero
          removeFromCart(productToUpdate);
        }
      }

      return updatedQuantities;
    });
  };

  const handleAddToCart = (product) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [product._id]: 1,
    }));
    addToCart({ ...product, quantity: 1 });
    toast.success(`${product.title.en} added to cart!`);
  };

  return (
    <div className="space-y-5">
      <ToastContainer position="top-center" />

      <div
        style={{ background: `${storeData?.color}10` }}
        className="space-x-2 p-2"
      >
        <button
          style={{
            background: selectCategory === 'all' ? storeData?.color : 'white',
            color: selectCategory === 'all' ? 'white' : 'black',
          }}
          className="rounded border px-4 py-1 text-[12px]"
          onClick={() => setSelectCategory('all')}
        >
          All
        </button>
        {filteredCategories?.map((cat) => (
          <button
            key={cat?._id}
            style={{
              background:
                selectCategory === cat?._id ? storeData?.color : 'white',
              color: selectCategory === cat?._id ? 'white' : 'black',
            }}
            className="rounded border px-2 py-1 text-[12px]"
            onClick={() => setSelectCategory(cat?._id)}
          >
            {cat?.label}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-3 gap-3">
        {filterByCategory?.length > 0 ? (
          filterByCategory.map((data) => (
            <Card key={data?._id} className="grid h-full space-y-3 !pb-0">
              <div className="space-y-3">
                <img
                  className="mx-auto max-h-[150px]"
                  src={data?.coverImgUrl ?? demoImg}
                  alt={data?.title?.en}
                />
                <Link
                  to={`/product/view/${data?._id}`}
                  className="flex justify-center text-center font-medium text-black hover:underline"
                >
                  {t('previewProduct')}
                </Link>
                <div className="flex items-center justify-between">
                  <h4 className="font-bold text-black">{data?.title?.en}</h4>
                  <p className="bg-red-100 px-2 py-0.5 text-[14px] font-bold">
                    {data?.price}{' '}
                    {data?.store?.store_contact_information?.currency || 'EUR'}
                  </p>
                </div>
                <p className="text-[14px]">
                  {data?.description?.en || data?.description?.ar}
                </p>
              </div>
              {data?.youtubeVideoUrl && (
                <Link
                  to={data?.youtubeVideoUrl}
                  className="flex items-end justify-end text-red-500"
                >
                  <FaYoutube size={20} />
                </Link>
              )}
              {isPublic && (
                <>
                  {quantities[data?._id] > 0 ? (
                    <div className="mt-2 flex items-center justify-between">
                      <button
                        className="border px-2 py-1"
                        onClick={() => handleQuantityChange(data?._id, false)}
                      >
                        -
                      </button>
                      <span>{quantities[data?._id]}</span>
                      <button
                        className="border px-2 py-1"
                        onClick={() => handleQuantityChange(data?._id, true)}
                      >
                        +
                      </button>
                    </div>
                  ) : (
                    <button
                      className="mt-2 rounded px-4 py-2"
                      style={{
                        background: storeData?.color,
                        color: 'white',
                      }}
                      onClick={() => handleAddToCart(data)}
                    >
                      {t('addToCart')}
                    </button>
                  )}
                </>
              )}
            </Card>
          ))
        ) : (
          <p>No products available for this store.</p>
        )}
      </div>
    </div>
  );
};

export default ProductSection;
