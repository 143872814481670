import React, { useEffect, useState } from 'react';
// import { FaLocationArrow, FaPen } from "react-icons/fa";
// import Button from "../components/ui/Button";
import toast from 'react-hot-toast';
import {
  FaCalendar,
  FaCar,
  FaChair,
  FaCheckCircle,
  FaCog,
  FaDollarSign,
  FaDoorOpen,
  FaFileAlt,
  FaGasPump,
  FaLink,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaPalette,
  FaPen,
  FaRoad,
  FaRuler,
  FaTachometerAlt,
} from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import features from '../../constants/fetures';
import {
  useCreateCarMutation,
  useEditCarMutation,
  useGetBrandOptionsQuery,
  useGetCardsOptionsQuery,
  useGetSingleCarQuery,
} from '../../redux/features/cars/carsSlice';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../utils/helper';
import Loading from '../Loading';
import Button from '../ui/Button';
import Card from '../ui/Card';
import InputGroup from '../ui/InputField';
import MultiImageUpload from '../ui/MultiImageUpload';
import SelectOptions from '../ui/SelectOptions';

const CreateCarsPage = () => {
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: singleCarData, refetch: refetchSingleCar } =
    useGetSingleCarQuery(id, { skip: !id });
  const { data: brandOptionsData } = useGetBrandOptionsQuery();
  const { data: cardsOptionsData } = useGetCardsOptionsQuery();
  const [createCar, { isLoading }] = useCreateCarMutation();
  const [updateCar, { isLoading: isEditLoading }] = useEditCarMutation();

  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();

  const { pathname } = useLocation();

  const fuelOptions = [
    { value: 'petrol', label: 'Petrol' },
    { value: 'diesel', label: 'Diesel' },
    { value: 'electric', label: 'Electric' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'others', label: 'Others' },
  ];
  const transmissionOptions = [
    { value: 'manual', label: 'Manual' },
    { value: 'automatic', label: 'Automatic' },
    { value: 'others', label: 'Others' },
  ];
  const engineSizeOptions = [
    { value: '1000cc', label: '1000cc' },
    { value: '1500cc', label: '1500cc' },
    { value: '2000cc', label: '2000cc' },
    { value: '3000cc', label: '3000cc' },
    { value: 'others', label: 'Others' },
  ];
  const bodyTypeOptions = [
    { value: 'sedan', label: 'Sedan' },
    { value: 'suv', label: 'SUV' },
    { value: 'hatchback', label: 'Hatchback' },
    { value: 'coupe', label: 'Coupe' },
    { value: 'others', label: 'Others' },
  ];
  const doorOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: 'others', label: 'Others' },
  ];
  const seatOptions = [
    { value: '2', label: '2' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '7', label: '7' },
    { value: 'others', label: 'Others' },
  ];
  const conditionOptions = [
    { value: 'new', label: 'New' },
    { value: 'used', label: 'Used' },
    { value: 'modified', label: 'Modified' },

    { value: 'others', label: 'Others' },
  ];
  const driveTypeOptions = [
    { value: 'leftHand', label: 'Left Hand' },
    { value: 'rightHand', label: 'Right Hand' },
    { value: 'others', label: 'Others' },
  ];

  useEffect(() => {
    if (singleCarData?.success) {
      const { _id, ...restObj } = singleCarData?.data;
      for (let key in restObj) {
        setValues((prevState) => {
          if (key === 'brand' || key === 'card') {
            return {
              ...prevState,
              [key]: restObj[key]?._id,
            };
          } else {
            return {
              ...prevState,
              [key]: restObj[key],
            };
          }
        });
      }
    }
  }, [singleCarData]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSaveCar = async () => {
    const requiredFields = ['model', 'price', 'from', 'km', 'engineSize'];
    for (let field of requiredFields) {
      if (!values[field]) {
        toast.error(`${field} is required`);
        return;
      }
    }

    if (isSubmitting) return; // Prevent multiple invocations
    setIsSubmitting(true);
    try {
      let result;
      if (!id) {
        const featureUsageResponse = await patchFeatureUsage(
          getFeatureId(features.CARS, featuresData?.data)
        );
        if (featureUsageResponse?.error) {
          toast.error(featureUsageResponse?.error?.data?.message);
          setTimeout(() => {
            navigate('/subscription/history');
          }, 1000);
          return;
        }
        result = await createCar(values);
      } else {
        result = await updateCar({ id, data: values });
      }
      if (result?.data?.success) {
        if (id) {
          toast.success(result?.data?.message);
          refetchSingleCar();
          navigate('/all/cars');
        } else {
          toast.success(result?.data?.message);
          navigate('/all/cars');
        }
      }

      if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('Cars Create Failed');
    } finally {
      setIsSubmitting(false); // Unlock after processing
    }
  };

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  return (
    <>
      <Card
        title={!id ? 'Create New Car' : 'Edit Car'}
        content={
          <Button
            link="/all/cars"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            My Cars
          </Button>
        }
        className="space-y-3"
      >
        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
          <SelectOptions
            placeholder="Select Brands"
            name={'brand'}
            value={values?.brand}
            onChange={onChange}
            options={[
              ...(brandOptionsData?.data ?? []), // Safely spread the data or use an empty array
              { value: 'others', name: 'Others' },
            ]}
            icon={<FaCar />}
          />

          <InputGroup
            name="model"
            placeholder="Car Model"
            icon={<FaPen />}
            value={values.model}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="serie"
            placeholder="Serie"
            icon={<FaLocationArrow />}
            value={values.serie}
            onChange={onChange}
          />
          <InputGroup
            name="year"
            placeholder="Year"
            icon={<FaCalendar />}
            value={values.year}
            onChange={onChange}
          />
          <InputGroup
            name="color"
            placeholder="Color"
            icon={<FaPalette />}
            value={values.color}
            onChange={onChange}
          />
          <InputGroup
            name="price"
            placeholder="Price"
            icon={<FaDollarSign />}
            value={values.price}
            onChange={onChange}
            type="number"
            isRequired={true}
          />
          <InputGroup
            name="km"
            placeholder="Kilometers"
            icon={<FaTachometerAlt />}
            value={values.km}
            onChange={onChange}
            isRequired={true}
            type="number"
          />

          <SelectOptions
            placeholder="Select Fuel Type"
            name="fuelType"
            value={values.fuelType}
            onChange={onChange}
            options={fuelOptions}
            icon={<FaGasPump />}
          />

          <SelectOptions
            placeholder="Select Transmission"
            name="transmission"
            value={values.transmission}
            onChange={onChange}
            options={transmissionOptions}
            icon={<FaCog />}
          />
          <SelectOptions
            placeholder="Select Engine Size"
            name="engineSize"
            value={values.engineSize}
            onChange={onChange}
            options={engineSizeOptions}
            icon={<FaRuler />}
          />
          <SelectOptions
            placeholder="Select Body Type"
            name="bodyType"
            value={values.bodyType}
            onChange={onChange}
            options={bodyTypeOptions}
            icon={<FaCar />}
          />
          <SelectOptions
            placeholder="Select number of Doors"
            name="doors"
            value={values.doors}
            onChange={onChange}
            options={doorOptions}
            icon={<FaDoorOpen />}
          />
          <SelectOptions
            placeholder="Select Seats"
            name="seats"
            value={values.seats}
            onChange={onChange}
            options={seatOptions}
            icon={<FaChair />}
          />
          <SelectOptions
            placeholder="Select Condition"
            name="condition"
            value={values.condition}
            onChange={onChange}
            options={conditionOptions}
            icon={<FaCheckCircle />}
          />
          <SelectOptions
            placeholder="Select Drive Type"
            name="driveType"
            value={values.driveType}
            onChange={onChange}
            options={driveTypeOptions}
            icon={<FaRoad />}
          />
          <InputGroup
            name="from"
            placeholder="From"
            icon={<FaMapMarkerAlt />}
            value={values.from}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="url"
            placeholder="URL"
            icon={<FaLink />}
            value={values.url}
            onChange={onChange}
          />
          <InputGroup
            name="description"
            placeholder="Description"
            icon={<FaFileAlt />}
            value={values.description}
            onChange={onChange}
          />

          <MultiImageUpload
            name="galleryImages"
            value={values.galleryImages}
            onChange={onChange}
          />

          <SelectOptions
            placeholder="Select Card Options"
            name={'card'}
            value={values?.card}
            onChange={onChange}
            options={cardsOptionsData?.data}
            icon={<FaPen />}
          />
        </div>

        <Button
          className="mt-3 w-32 !bg-green-400"
          onClick={handleSaveCar}
          isDisabled={isLoading || isEditLoading}
        >
          {isEditLoading || isLoading ? (
            <Loading variant="button" height="20px" />
          ) : (
            `${!id ? 'Save' : 'Update'} Car`
          )}
        </Button>
      </Card>
    </>
  );
};

export default CreateCarsPage;
