import React from 'react';
import ProductForm from './ProductForm';

const CreateAdminProduct = () => {
  return (
    <div>
      <ProductForm />
    </div>
  );
};

export default CreateAdminProduct;
