import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import BusinessLayout from '../../../layout/BusinessLayout';
import { useGetEmployeeQuery } from '../../../redux/features/employees/employeesApi';
import EmployeeForm from './EmployeeForm';

const EmployeeEditPage = () => {
  const locationC = useLocation();
  const location = locationC?.pathname;
  const pathSegments = location?.split('/');
  const branchId = pathSegments[pathSegments.length - 1];
  const { id } = useParams();

  const { data, isLoading } = useGetEmployeeQuery(branchId);

  return (
    <BusinessLayout>
      {isLoading ? (
        <Loading height="70vh" />
      ) : (
        <EmployeeForm data={data?.data} />
      )}
    </BusinessLayout>
  );
};

export default EmployeeEditPage;
