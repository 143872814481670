import React from 'react';
import Table from '../../../../components/Table';
import Card from '../../../../components/ui/Card';
import { useGetAllFeaturesQuery } from '../../../../redux/features/subscriptions/subscriptionsApi';

const SubscriptionFeatures = () => {
  const { data, isLoading } = useGetAllFeaturesQuery('both');

  //   const [deleteSubscription] = useDeleteSubscriptionMutation();

  //   const handleDelete = async (id) => {
  //     try {
  //       const result = await deleteSubscription(id);
  //       if (result?.data?.success) {
  //         toast.success(result?.data?.message);
  //       } else {
  //         toast.error(result?.error?.data?.message);
  //       }
  //     } catch (error) {
  //       //   console.error(error);
  //     }
  //   };

  const content = {
    header: ['Feature Name', 'Individual or Business'],
    body: data?.data?.map((item) => ({
      rows: [
        {
          row: <span className="capitalize">{item?.name}</span>,
        },
        {
          row: (
            <span className="capitalize">
              {item?.isBusiness ? 'Business' : ''}
              {item?.isIndividual ? ' and Individual' : ''}
            </span>
          ),
        },
        // {
        //   row: (
        //     <div className="flex items-center gap-3">
        //       <Button
        //         link={`/admin/subscription-features/edit/${item?._id}`}
        //         className="!block !bg-green-400"
        //       >
        //         <FaPenToSquare />
        //       </Button>
        //       <Button
        //         onClick={() => handleDelete(item?._id)}
        //         className="!block !bg-red-400"
        //       >
        //         <FaTrash />
        //       </Button>
        //     </div>
        //   ),
        // },
      ],
    })),
  };

  return (
    <>
      <Card
        title="Features"
        // content={
        //   <Button
        //     link="/admin/subscription-features"
        //     className="flex items-center gap-2 !bg-green-500"
        //   >
        //     <FaPlus /> Add New Feature
        //   </Button>
        // }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default SubscriptionFeatures;
