const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const POST_SHARE_URL = 'http://admin.srv565545.hstgr.cloud/';
const environment = process.env.REACT_APP_APP_ENVIRONMENT;
const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
const orderTemplateId = process.env.REACT_APP_ORDER_EMAIL_TEMPLATE_ID;
const publicId = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
const SHOPE_URL = process.env.REACT_APP_SHOPE_URL;

export {
  BASE_URL,
  environment,
  MAP_API_KEY,
  orderTemplateId,
  POST_SHARE_URL,
  publicId,
  serviceId,
  SHOPE_URL,
};
