import React from 'react';
import Footer from '../../components/Footer/Footer';
import Hero from '../../components/home/Hero/Hero';
import Navbar from '../../components/Navbar/Navbar';
import QrStore from "../../components/home/QR/QR";
import About from "../../components/home/About/About";
import Wifi from "../../components/Wifi/Wifi";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
        <Wifi/>
        <About/>
        <QrStore/>

      <Footer />
    </div>
  );
};

export default Home;
