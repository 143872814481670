import { apiSlice } from "../api/apiSlice";

export const postsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: ({from, page, limit, userId }) => ({
        url: from === "home" ? "/posts/public" : `/posts/my-posts`,
        method: "GET",
        params: {page, limit, userId}
      }),
      providesTags: ["myPosts"],
    }),

    getPublicPosts: builder.query({
      query: () => ({
        url: "/posts/public",
        method: "GET",
      }),
      providesTags: ["myPosts"],
    }),

    getMyPosts: builder.query({
      query: (arg) => ({
        url: `/posts/my-posts?page=${arg?.page}&limit=${arg?.limit}`,
        method: "GET",
      }),
      providesTags: ["myPosts"],
    }),

    getSingleMyPosts: builder.query({
      query: (id) => ({
        url: `/posts/my-posts/${id}`,
        method: "GET",
      }),
      providesTags: ["myPosts"],
    }),

    getSinglePost: builder.query({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "GET",
      }),
      providesTags: ["myPosts"],
    }),

    createPost: builder.mutation({
      query: (data) => ({
        url: "/posts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["myPosts"],
    }),

    editPost: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/posts/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["myPosts"],
    }),

    deletePost: builder.mutation({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["myPosts"],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPublicPostsQuery,
  useGetSinglePostQuery,
  useGetMyPostsQuery,
  useGetSingleMyPostsQuery,
  useCreatePostMutation,
  useEditPostMutation,
  useDeletePostMutation,
} = postsApi;
