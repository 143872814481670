import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteFriendsCategoriesMutation,
  useGetFriendsCategoriesQuery,
} from '../../../redux/features/friends/friendsApi';
import Loading from '../../Loading';
import Table from '../../Table';
import Button from '../../ui/Button';
import Card from '../../ui/Card';

const FriendCategoriesList = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const [paginate, setPaginate] = useState({
    // page: 1,
    limit: 10,
  });

  const { data, isLoading } = useGetFriendsCategoriesQuery({
    // page: paginate?.page,
    limit: paginate?.limit,
  });

  const [deleteQrCategory, { isLoading: isFriendLoading }] =
    useDeleteFriendsCategoriesMutation();

  const handleDeleteApi = async (id) => {
    setDeleteId(id);
    try {
      const result = await deleteQrCategory(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    } finally {
      setDeleteId(null);
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this category?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const content = {
    header: ['Name', 'Actions'],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.name,
        },

        {
          row: item?.name !== 'Others' && (
            <div className="flex  items-center gap-3">
              <Button
                link={`/friends/categories/edit/${item?._id}`}
                className="!block !bg-green-400"
              >
                <FaPenToSquare />
              </Button>
              {item?.name !== 'Contact' && (
                <Button
                  onClick={() => handleDelete(item?._id)}
                  className="!bg-red-400"
                >
                  {isFriendLoading && item?._id === deleteId ? (
                    <Loading variant="button" height="14px" />
                  ) : (
                    <MdDelete />
                  )}
                </Button>
              )}
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <>
      <Card
        title="Friends Categories"
        content={
          <button
            className="flex items-center gap-2 rounded bg-[#009ef7] px-4 py-2 text-white"
            onClick={() => navigate('/friends/categories/create')}
          >
            <FaPlus /> Create Friends Category
          </button>
        }
      >
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </>
  );
};

export default FriendCategoriesList;
