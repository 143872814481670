import React, { forwardRef, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Modal from 'react-modal';
import { useUploadMutation } from '../../redux/features/common/commonApi';

const InputFile = forwardRef(
  (
    {
      name,
      label,
      className,
      title,
      icon,
      onChange,
      subKey,
      fileTypes = '.jpg, .png, .jpeg',
      isBanner = false,
      cv = false,
      category = false,
    },
    ref
  ) => {
    const [fileUpload] = useUploadMutation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const handleFileChange = async (e) => {
      const fileInput = e.target;
      const file = fileInput.files[0];
      if (file) {
        const fileType = file.type.split('/')[0];
        if (fileType !== 'image') {
          setSelectedFile(null);
          fileInput.value = null;

          // Directly upload non-image file
          const formData = new FormData();
          formData.append('file', file);
          let result = await fileUpload(formData);
          if (result?.data?.success) {
            onChange(name, result?.data?.data?.url, subKey);
          }
          return;
        }

        setSelectedFile(null);
        fileInput.value = null;
        setTimeout(() => {
          setSelectedFile(URL.createObjectURL(file));
          setIsModalOpen(true);
        }, 50);
      }
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
    }, []);

    const getCroppedImage = async () => {
      if (!selectedFile || !croppedArea) return;

      try {
        const image = await fetch(selectedFile).then((res) => res.blob());
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = URL.createObjectURL(image);
        img.onload = async () => {
          canvas.width = croppedArea.width;
          canvas.height = croppedArea.height;
          ctx.drawImage(
            img,
            croppedArea.x,
            croppedArea.y,
            croppedArea.width,
            croppedArea.height,
            0,
            0,
            croppedArea.width,
            croppedArea.height
          );

          canvas.toBlob(async (blob) => {
            const croppedFile = new File([blob], 'cropped_image.jpg', {
              type: 'image/jpeg',
            });
            const formData = new FormData();
            formData.append('file', croppedFile);
            let result = await fileUpload(formData);
            if (result?.data?.success) {
              onChange(name, result?.data?.data?.url, subKey);
            }
            setIsModalOpen(false);
          }, 'image/jpeg');
        };
      } catch (error) {
        console.error('Error cropping image:', error);
      }
    };

    return (
      <div>
        {title && <h3 className="pb-1">{title}</h3>}
        {!cv && !category ? (
          <div className="flex h-[35px] items-center rounded-lg border dark:border-black-300">
            {icon && (
              <label
                className="flex h-[35px] w-[50px] items-center justify-center rounded-l-lg bg-gray-100 dark:bg-black-300"
                htmlFor=""
              >
                {icon}
              </label>
            )}

            <div className="custom-file-input">
              <input
                id={name}
                name={name}
                type="file"
                ref={ref}
                onChange={handleFileChange}
                className="hidden"
                accept={fileTypes}
              />
              <button
                type="button"
                onClick={() => document.getElementById(name).click()}
                className={`${className} p-1 text-[13px] text-black`}
              >
                Choose File
              </button>
            </div>

            <span className="font-bold">{label}</span>
          </div>
        ) : (
          <div
            className={`absolute inset-0 flex h-[150px] w-[150px] items-center justify-center rounded-full  bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100 ${
              cv ? 'left-[60px] top-[180px] rounded-full' : ''
            }`}
          >
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
            <label
              htmlFor="dropzone-file"
              className="flex h-[150px] w-[150px] cursor-pointer items-center justify-center"
            ></label>
          </div>
        )}

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            overlay: { zIndex: 1000001 },
          }}
        >
          <h2>Crop Your Image</h2>
          <div
            style={{
              position: 'relative',
              height: 400,
              width: '50%',
              left: '600px',
              zIndex: 1000001,
            }}
          >
            <Cropper
              image={selectedFile}
              crop={crop}
              zoom={zoom}
              aspect={isBanner ? 16 / 9 : 1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape={isBanner ? 'rect' : 'rect'}
              // style={{ borderRadius: '50%' }}
            />
          </div>
          <div className="ms-[1000px]">
            <div style={{ margin: '20px 0' }}>
              <label>Zoom:</label>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
              />
            </div>
            <div className="flex gap-10">
              <button
                className="bg-green-500 p-2 text-white"
                onClick={getCroppedImage}
              >
                Crop and Upload
              </button>
              <button
                className="bg-red-500 p-2 text-white"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default InputFile;
