import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleCarQuery } from '../../redux/features/cars/carsSlice';
import Loading from '../Loading';
import CarView from './CarView';

const CarCardViewPage = ({ isPublic }) => {
  const { id } = useParams();
  const { data: cardData, isLoading } = useGetSingleCarQuery(id);

  const data = {
    name: cardData?.data?.brand?.name,
    model: cardData?.data?.model,
    address: cardData?.data?.from,
    price: cardData?.data?.price,
    position: cardData?.data?.km,
    phone: cardData?.data?.engineSize,
    coverImage: cardData?.data?.galleryImages,
    dealerProfile: cardData?.data?.createdBy,
    card: cardData?.data?.card,
    businessbranches: cardData?.data?.businessbranches,
    employee: cardData?.data?.employee,
  };
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <CarView
          data={data}
          link={`/cars/edit/${id}`}
          variant="car"
          isPublic={isPublic}
        />
      )}
    </div>
  );
};

export default CarCardViewPage;
