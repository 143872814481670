import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
        setSelectedLanguage(savedLanguage);
    }, [i18n]);

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        localStorage.setItem('language', lang);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-between w-36 rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-blue-950 text-white font-semibold text-sm hover:bg-blue-900 focus:outline-none"
                    onClick={toggleDropdown}
                >
                    {selectedLanguage === 'en' ? 'English' : 'العربي'}
                    <svg
                        className={`w-5 h-5 ml-2 transform transition-transform ${
                            isDropdownOpen ? 'rotate-180' : 'rotate-0'
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        ></path>
                    </svg>
                </button>
            </div>

            {isDropdownOpen && (
                <div className="absolute mt-2 w-36 rounded-md shadow-lg bg-white z-10">
                    <ul className="py-1 text-sm text-gray-700">
                        <li
                            onClick={() => handleChangeLanguage('en')}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                        >
                            English
                        </li>
                        <li
                            onClick={() => handleChangeLanguage('ar')}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                        >
                            العربي
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default LanguageSelector;
