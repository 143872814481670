import { apiSlice } from '../api/apiSlice';

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({
        page,
        limit,
        firstName,
        lastName,
        dialCode,
        phone,
        country,
        username,
        businessName,
        email,
        role,
      }) => ({
        url: `/users?page=${page}&limit=${limit}&firstName=${firstName}&lastName=${lastName}&dialCode=${dialCode}&phone=${phone}&country=${country}&username=${username}&businessName=${businessName}&email=${email}&role=${role}`,
        method: 'GET',
      }),
      providesTags: ['usersData'],
    }),

    getUser: builder.query({
      query: ([username, branchId]) => ({
        url: `/users/profile/username/${username}/branchId/${branchId}`,
        method: 'GET',
      }),
      providesTags: ['usersData', 'cards', 'getSingleUser'],
    }),

    createUser: builder.mutation({
      query: (data) => ({
        url: '/users',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['usersData'],
    }),

    userPointLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/point-location/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['usersData'],
    }),

    editUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/dashboard/update-profile/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['usersData'],
    }),

    changeRole: builder.mutation({
      query: ({ data }) => ({
        url: `/users/account/role-change`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['usersData'],
    }),

    changeQRLimit: builder.mutation({
      query: ({ id, data }) => ({
        url: `/permissions/limit-set/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['usersData'],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/account/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['usersData', 'adminStatsUsers'],
    }),

    getUserOptions: builder.query({
      query: () => ({
        url: `/users/options`,
        method: 'GET',
      }),
      providesTags: ['userSingleData'],
    }),

    updateUserViewMode: builder.mutation({
      query: (userMode) => ({
        url: `/users/user-mode`,
        method: 'PATCH',
        body: { userMode: userMode },
      }),
    }),

    // get profile of user by user id
    getUserProfile: builder.query({
      query: (id) => ({
        url: `/users/get/profile/userName/${id}`,
        method: 'GET',
      }),
    }),
  }),
});
// http://localhost:4000/api/v1.0/users/profile/userName/shakiabTesting11
export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetUserOptionsQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useChangeRoleMutation,
  useDeleteUserMutation,
  useChangeQRLimitMutation,
  useUserPointLocationMutation,
  useUpdateUserViewModeMutation,
  useGetUserProfileQuery,
} = usersApi;
// how to sue useGetUserQuery
// const { data: userData, isLoading } = useGetUserProfileQuery(id);
