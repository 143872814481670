import { apiSlice } from '../api/apiSlice';

export const adminShopeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ADMIN    products api
    getAdminProducts: builder.query({
      query: (arg) => ({
        url: `/products/get-products/admin-side?page=${arg?.page}&limit=${arg?.limit}`,
        method: 'GET',
      }),
      providesTags: ['adminProducts'],
    }),

    // create admin prodycts
    createAdminProducts: builder.mutation({
      query: (data) => ({
        url: '/products/create-product/adminSide',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['adminProducts'],
    }),

    // git single admin product
    getAdminProduct: builder.query({
      query: (id) => ({
        url: `/admin/adminSide/${id}`,
        method: 'GET',
      }),
      providesTags: ['adminProducts'],
    }),

    // update admin product
    updateAdminProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/products/update/products/adminSide/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['adminProducts'],
    }),

    // delete admin product
    deleteAdminProduct: builder.mutation({
      query: (id) => ({
        url: `/products/delete/product/adminSide/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['adminProducts'],
    }),

    // get admin product categouries
    getAdminProductCategories: builder.query({
      query: (arg) => ({
        url: `/categories/all-category/admin-side`,
        method: 'GET',
      }),
      providesTags: ['adminProductCategories'],
    }),

    // create admin product categouries
    createAdminProductCategories: builder.mutation({
      query: (data) => ({
        url: '/categories/create-category-Admin-side',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['adminProductCategories'],
    }),

    // git single admin product categouries
    getAdminProductCategory: builder.query({
      query: (id) => ({
        url: `/categories/admin-side/${id}`,
        method: 'GET',
      }),
      providesTags: ['adminProductCategories'],
    }),

    // update admin product categouries
    updateAdminProductCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/categories/update-category/admin-side/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['adminProductCategories'],
    }),

    // delete admin product categouries
    deleteAdminProductCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['adminProductCategories'],
    }),

    // get admin orders
    getAdminOrders: builder.query({
      query: (arg) => ({
        url: `/products/admin-side/order/List?page=${arg?.page}&limit=${arg?.limit}`,
        method: 'GET',
      }),
      providesTags: ['adminOrders'],
    }),

    // get single admin order
    getAdminOrder: builder.query({
      query: (id) => ({
        url: `/admin/orders/${id}`,
        method: 'GET',
      }),
      providesTags: ['adminOrders'],
    }),
  }),
});

export const {
  useGetAdminProductsQuery,
  useCreateAdminProductsMutation,
  //   useCreateAdminProducts,
  useGetAdminProductQuery,
  useUpdateAdminProductMutation,
  useDeleteAdminProductMutation,
  useGetAdminProductCategoriesQuery,
  useCreateAdminProductCategoriesMutation,
  useGetAdminProductCategoryQuery,
  useUpdateAdminProductCategoryMutation,
  useDeleteAdminProductCategoryMutation,
  useGetAdminOrdersQuery,
  useGetAdminOrderQuery,
} = adminShopeApi;

// how to use useGetAdminProductCategoriesQuery
// const { data, isLoading, isSuccess } = useGetAdminProductCategoriesQuery();
