import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between ml-20 px-12 mt-6 bg-white mr-20">
                <div className="flex-1">
                    <h1 className="text-4xl text-green-300 font-bold mb-4">
                        {t('aboutTitle1')}
                    </h1>
                    <h1 className="text-4xl font-bold text-black mb-4">
                        {t('aboutTitle2')}
                    </h1>
                    <p className="text-lg text-gray-600 mb-6">
                        {t('aboutDescription')}
                    </p>
                    <div className="flex space-x-4">
                        <button className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600">
                            {t('faqButton')}
                        </button>
                    </div>
                </div>

                <div className="flex-1">
                    <img
                        src="/profile-2.png"
                        alt="Digital Marketing Portfolio Image"
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-cover"
                    />
                </div>
            </div>
        </>
    );
};

export default About;
