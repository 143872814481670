import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { LuMapPin } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';
import CustomizationPanel from './CustomizationPanel';
import { MdOutlineLocalPhone } from 'react-icons/md';
import SkillProgress from './SkillProgress';
import { FaRegEnvelope, FaRegFileLines } from 'react-icons/fa6';
import { FaGraduationCap } from 'react-icons/fa';
import { FaBuildingCircleCheck } from 'react-icons/fa6';
import { GiSkills } from 'react-icons/gi';
import { FaAddressCard } from 'react-icons/fa';
import { PiCertificateBold } from 'react-icons/pi';
import { LuTimerReset } from 'react-icons/lu';
import { SiStagetimer } from 'react-icons/si';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const CVTemplate5 = ({ data }) => {
  const location = useLocation();
  const componentRef = useRef();
  const [formData, setFormData] = useState(data);
  React.useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const [headingColor, setHeadingColor] = useState('text-blue-400');
  const [contentColor, setContentColor] = useState('text-black');
  const [fontFamily, setFontFamily] = useState('font-sans');
  const [textTransform, setTextTransform] = useState('normal-case');
  const [sideDesignBgColor, setSideDesignBgColor] = useState('#3b82f6');
  const [secondarySideDesignBgColor, setSecondarySideDesignBgColor] =
    useState('#1c2434');

  const timeDistribution = formData?.timeDistribution || {
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  };

  const personalSkills = formData?.personalSkills || {
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  };

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          formData?.working,
          formData?.family,
          formData?.coordination,
          formData?.entertainment,
          formData?.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const generatePdf = async () => {
    const element = componentRef.current;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    });

    const imgData = canvas.toDataURL('image/png');

    if (!imgData.startsWith('data:image/png')) {
      console.error('Invalid PNG image data');
      return;
    }

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const a4Width = 210;
    const a4Height = 297;
    const dpi = 96;
    const pxPerMm = dpi / 25.4;
    const a4WidthPx = a4Width * pxPerMm;
    const a4HeightPx = a4Height * pxPerMm;

    // Calculate scaling factor
    const scale =
      Math.min(a4WidthPx / canvas.width, a4HeightPx / canvas.height) * 1;
    const scaledCanvasWidth = canvas.width * scale;
    const scaledCanvasHeight = canvas.height * scale;

    // Number of pages needed
    const pageHeightPx = a4HeightPx / scale;
    const totalPages = Math.ceil(canvas.height / pageHeightPx);

    for (let page = 0; page < totalPages; page++) {
      const pageCanvas = document.createElement('canvas');
      pageCanvas.width = canvas.width;
      pageCanvas.height = Math.min(
        pageHeightPx,
        canvas.height - page * pageHeightPx
      );

      const pageContext = pageCanvas.getContext('2d');
      pageContext.drawImage(
        canvas,
        0,
        page * pageHeightPx,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        canvas.width,
        pageCanvas.height
      );

      const pageImgData = pageCanvas.toDataURL('image/png');

      // Check if pageImgData is valid for this page
      if (!pageImgData.startsWith('data:image/png')) {
        console.error(`Invalid PNG data for page ${page}`);
        continue;
      }

      if (page > 0) {
        pdf.addPage();
      }

      // Add the image to the PDF page
      pdf.addImage(
        pageImgData,
        'PNG',
        0,
        0,
        a4Width,
        (pageCanvas.height / pxPerMm) * scale
      );
    }

    pdf.save('download.pdf');
  };

  return (
    <div className="flex gap-10 py-20 pl-8">
      {/*<div className="relative  print:hidden opacity-15">*/}
      {/*    <h1 className="absolute text-9xl text-gray-400 rotate-45 mt-72 translate-y-72 transform translate-x-56"*/}
      {/*    >*/}
      {/*        Preview*/}
      {/*    </h1>*/}
      {/*</div>*/}

      <div
        className="min-h-screen w-[69%] bg-gray-200 py-10"
        ref={componentRef}
      >
        <div className="flex  ">
          <div className="w-2/5">
            <section className=" pl-10 pr-2">
              <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                <span className="text-2xl">{<FaRegFileLines />}</span>
                <h2
                  className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform} `}
                >
                  Summary
                </h2>
              </div>
              <p className={`mt-2 text-lg ${contentColor} ${fontFamily}`}>
                {formData?.summary || '123 Example St, City'}
              </p>
            </section>

            <section className="mt-8 pl-10 pr-2">
              {formData?.education?.length > 0 && (
                <div className="mt-8">
                  <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                    <span className="text-2xl">{<FaGraduationCap />}</span>

                    <h2
                      className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform} `}
                    >
                      Educational Background
                    </h2>
                  </div>
                  {formData.education.map((edu) => (
                    <div
                      key={edu._id}
                      className={`mt-4 text-lg ${contentColor} ${fontFamily}`}
                    >
                      <p>
                        {edu.fieldOfStudy} - {edu.instituteName}
                      </p>
                      <p>
                        {new Date(edu.startDate).toLocaleDateString()} to{' '}
                        {new Date(edu.endDate).toLocaleDateString()}
                      </p>
                      <p>{edu.location}</p>
                      <p>{edu.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </section>

            <section className="mt-8 pl-10 pr-2">
              {formData?.experience?.length > 0 && (
                <div className="mt-8">
                  <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                    <span className="text-2xl">
                      {<FaBuildingCircleCheck />}
                    </span>
                    <h2
                      className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform}`}
                    >
                      Work Experience
                    </h2>
                  </div>
                  {formData?.experience?.map((exp) => (
                    <div
                      key={exp._id}
                      className={`mt-4 text-lg ${contentColor} ${fontFamily}`}
                    >
                      <p>
                        {exp.position} at {exp.companyName}
                      </p>
                      <p>
                        {new Date(exp.startDate).toLocaleDateString()} to{' '}
                        {new Date(exp.endDate).toLocaleDateString()}
                      </p>
                      <p>{exp.location}</p>
                      <p>{exp.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </section>

            <section className="mt-8 pl-10 pr-2">
              {formData?.skills?.length > 0 && (
                <div className="mt-8">
                  <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                    <span className="text-2xl">{<GiSkills />}</span>
                    <h2
                      className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform}`}
                    >
                      Skills
                    </h2>
                  </div>
                  <ul className={contentColor}>
                    {formData.skills.map((skill) => (
                      <li key={skill._id} className={`mt-2 ${fontFamily}`}>
                        {skill.skills}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </section>
          </div>

          <div className="w-1/5  ">
            <div className="flex h-[30%] items-center justify-center bg-gray-300 text-center">
              <img
                src={formData?.imageUrl || '/dummyImg.jpg'}
                alt="Profile"
                className="h-36 w-36 rounded-full  border-2 border-blue-950"
                style={{ backgroundColor: `${sideDesignBgColor}` }}
              />
            </div>
            <div
              className="h-[70%] bg-green-700 text-center"
              style={{ flex: '7', backgroundColor: `${sideDesignBgColor}` }}
            >
              <h1
                className={`mt-0 pt-6 text-3xl font-semibold ${contentColor} ${fontFamily} ${textTransform}`}
              >
                {formData?.name || 'Your Name'}
              </h1>
              <div className=" items-center">
                <p
                  className={`mt-4 text-xl text-white ${contentColor} ${fontFamily} ${textTransform}`}
                >
                  {formData?.jobTitle || 'Job Title'}
                </p>
              </div>

              <div className="relative flex flex-row items-center justify-center gap-2 rounded-md bg-black bg-opacity-50 p-2 pr-4 text-center">
                <FaRegEnvelope size={20} className="mb-1 mt-1 text-white" />
                <p
                  className={`text-sm text-white ${contentColor} ${fontFamily}`}
                >
                  {formData?.email || 'john.doe@example.com'}
                </p>
              </div>

              {formData?.languages?.length > 0 && (
                <div className="mt-6">
                  <h2
                    className={`text-2xl font-bold ${contentColor} ${fontFamily} ${textTransform} border-b-2 border-gray-400 text-center`}
                  >
                    Languages
                  </h2>
                  <ul className={contentColor}>
                    {formData.languages.map((language) => (
                      <li
                        key={language._id}
                        className={`mt-2 text-white ${fontFamily}`}
                      >
                        {language.languages}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {formData?.hobbies?.length > 0 && (
                <div className="mt-8">
                  <h2
                    className={`text-2xl font-bold ${contentColor} ${fontFamily} ${textTransform} border-b-2  border-gray-400 text-center`}
                  >
                    Hobbies
                  </h2>
                  <ul className={contentColor}>
                    {formData.hobbies.map((hobby) => (
                      <li
                        key={hobby._id}
                        className={`mt-2 text-white ${fontFamily}`}
                      >
                        {hobby.hobbies}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/*<div className="border-l-2 border-gray-400 mx-2 mt-16"></div>*/}

          <div className="w-2/5">
            <section className=" pl-2 pr-10 ">
              <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                <span className="text-2xl">{<FaAddressCard />}</span>
                <h2
                  className={`text-2xl font-bold  ${headingColor} ${fontFamily} ${textTransform}`}
                >
                  Personal Information
                </h2>
              </div>
              <p className={`mt-2 ${fontFamily} ${contentColor}`}>
                Address: {formData?.address}
              </p>
              <p className={`mt-2 ${fontFamily} ${contentColor}`}>
                Phone: {formData?.phone}
              </p>
            </section>

            <section className="mt-8 pl-2 pr-10">
              {formData?.certifications?.length > 0 && (
                <section className="mt-8">
                  <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                    <span className="text-2xl">{<PiCertificateBold />}</span>
                    <h2
                      className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform} `}
                    >
                      Certifications
                    </h2>
                  </div>
                  {formData.certifications.map((cert) => (
                    <div
                      key={cert._id}
                      className={`text-lg ${contentColor} ${fontFamily} mt-4`}
                    >
                      <h3>{cert.courseName}</h3>
                      <p className="italic">{cert.authority}</p>
                      <p>{new Date(cert.courseDate).toLocaleDateString()}</p>
                      {cert.description && <p>{cert.description}</p>}
                    </div>
                  ))}
                </section>
              )}
            </section>

            <section className="mt-8 pl-2 pr-10">
              <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                <span className="text-2xl">{<LuTimerReset />}</span>
                <h2
                  className={` text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform} `}
                >
                  Time Distribution
                </h2>
              </div>
              <div className="mt-4 h-72">
                <Doughnut data={chartData} options={options} />
              </div>
            </section>

            <section className="mt-8 pl-2 pr-10">
              <div className="flex items-center gap-2 border-b-2 border-gray-400 pb-2">
                <span className="text-2xl">{<SiStagetimer />}</span>
                <h2
                  className={`text-2xl font-bold ${headingColor} ${fontFamily} ${textTransform} `}
                >
                  Personal Skills
                </h2>
              </div>
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.leadership}
                title={'Leadership'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.communication}
                title={'Communication'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.selfMotivation}
                title={'Self Motivation'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.decisionMaking}
                title={'Decision Making'}
              />
            </section>
          </div>
        </div>
      </div>

      <CustomizationPanel
        setSideDesignBgColor={setSideDesignBgColor}
        setSecondarySideDesignBgColor={setSecondarySideDesignBgColor}
        setHeadingColor={setHeadingColor}
        setContentColor={setContentColor}
        setFontFamily={setFontFamily}
        setTextTransform={setTextTransform}
        fontFamily={fontFamily}
        textTransform={textTransform}
        generatePdf={generatePdf}
      />
    </div>
  );
};

export default CVTemplate5;
