import React from 'react';
// import ExternalContacts from "../../business/statics/ExternalContacts";
import { useGetFriendsWithCategoriesIdQuery } from '../../../redux/features/friends/friendsApi';
import LatestContacts from '../../business/statics/LatestContacts';
import RecentPosts from '../../business/statics/RecentPosts';
import StaticsCards from '../../store/statics/StaticsCards';

const StaticsPage = () => {
  const { data, isLoading, refetch } = useGetFriendsWithCategoriesIdQuery({
    id: 'all',
  });
  return (
    <div className="space-y-6">
      <StaticsCards />
      {/* <LatestContacts /> */}
      <LatestContacts data={data} isLoading={isLoading} refetch={refetch} />
      {/* <ExternalContacts /> */}
      <RecentPosts />
    </div>
  );
};

export default StaticsPage;
