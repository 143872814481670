import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetSingleBusinessTypeQuery } from '../../../redux/features/businessTypes/businessTypes';
import CreateBusinessForm from './CreateBusinessForm';

const EditBusiness = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleBusinessTypeQuery(id);
  return (
    <div>
      {isLoading ? <Loading /> : <CreateBusinessForm data={data?.data} />}
    </div>
  );
};

export default EditBusiness;
