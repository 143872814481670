import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { RiMessengerFill } from 'react-icons/ri';
import { SiMessenger } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import CustomReactPlayer from '../../../components/ui/CustomReactPlayer';
import DialogModal from '../../../components/ui/DialogModal';
import InputField from '../../../components/ui/InputField';
import TextAreaField from '../../../components/ui/TextAreaField';
import UploadImageWithButton from '../../../components/ui/UploadImageWithButton';
import UploadVideoWithButton from '../../../components/ui/UploadVideoWithButton';
import { POST_SHARE_URL } from '../../../constants/constants';
import features from '../../../constants/fetures';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import { useCreateInterestMutation } from '../../../redux/features/interests/interestApi';
import {
  useCreatePostMutation,
  useDeletePostMutation,
  useGetPostsQuery,
} from '../../../redux/features/posts/PostsApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../../utils/helper';
import PostViewCard from './PostViewCard';

const Posts = ({ isPostView, singlePostData, callingPage }) => {
  const [values, setValues] = useState({});
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const { data: profileData } = useGetMyProfileQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [isOpenInterestModal, setIsOpenInterestModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const {
    data,
    refetch,
    isSuccess: isPostGettingSuccess,
  } = useGetPostsQuery({ from: callingPage }, { skip: isPostView });
  // } = useGetPostsQuery({ from: pathname }, { skip: isPostView });

  const [createPost, { isLoading }] = useCreatePostMutation();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();

  const [
    createInterest,
    { isLoading: isLoadingInterest, isSuccess: isInterestPostSuccess },
  ] = useCreateInterestMutation();
  const [deletePost] = useDeletePostMutation();

  const handlePost = async () => {
    if (!values?.content?.trim() && !selectedImageFile && !selectedVideoFile) {
      toast.error('Please add content, an image, or a video before posting.');
      return;
    }

    const formData = {
      content: values?.content,
      images: [selectedImageFile],
      videos: [selectedVideoFile],
      isCollectInterest: isChecked,
    };

    try {
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.POSTS, featuresData?.data)
      );
      if (featureUsageResponse?.error) {
        toast.error(featureUsageResponse?.error?.data?.message);
        setTimeout(() => {
          navigate('/subscription/history');
        }, 1000);
        return;
      }

      const result = await createPost(formData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setSelectedImageFile(null);
        setSelectedVideoFile(null);
        setValues({});
        setIsChecked(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  //   console.log({values});
  const handleSubmitInterest = async () => {
    if (!isPostView) return;
    try {
      const result = await createInterest({
        postId: singlePostData[0]?._id,
        ...values,
      });

      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setValues({});
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDeleteApi = async (id) => {
    try {
      const result = await deletePost(id);
      if (result) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDeletePost = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this post?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  //   modal functionality
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onOpenInterestModal = () => {
    setIsOpenInterestModal(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
    setIsOpenInterestModal(false);
  };

  let postShareUrl = POST_SHARE_URL + 'posts/view/' + selectedPost?._id;

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://wa.me/?text=${encodeURIComponent(postShareUrl)}`,
    },
    {
      icon: <MdEmail />,
      color: '#000000',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(postShareUrl)}`,
    },
    {
      icon: <FaFacebookF />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        postShareUrl
      )}`,
    },
    {
      icon: <RiMessengerFill />,
      color: '#0078FF',
      platform: 'messenger',
      url: `fb-messenger://share/?link=${encodeURIComponent(postShareUrl)}`,
    },
    {
      icon: <SiMessenger />,
      color: '#FFA500',
      platform: 'sms',
      url: `sms:?body=${encodeURIComponent(postShareUrl)}`,
    },
    {
      icon: <FaTwitter />,
      color: '#1DA1F2',
      platform: 'twitter',
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        postShareUrl
      )}`,
    },
  ];

  const handleShare = (url) => {
    // window.open(url, '_blank');
    // console.log(url);
    window.open(url);
  };

  const handleShareOpen = (post) => {
    setSelectedPost(post);
    onOpenModal();
  };

  const handleInterestOpen = (post) => {
    setSelectedPost(post);
    onOpenInterestModal();
  };

  const handleRemoveFile = (type) => {
    if (type === 'image') {
      setSelectedImageFile(null);
    } else if (type === 'video') {
      setSelectedVideoFile(null);
    }
  };

  return (
    <div className="mx-auto w-full max-w-[800px] space-y-5">
      {isPostView ? null : (
        <Card className="space-y-3">
          <div className="flex gap-2">
            {/* {JSON.stringify(profileData?.data?.avatar, null, 2)} */}
            <img
              className="h-[40px] w-[40px] rounded-full"
              src={profileData?.data?.bannerBg}
              alt=""
            />
            <div className="w-full">
              <TextAreaField
                placeholder="What is your mind"
                //value={values?.content ? values.content : ''}
                value={values?.content || ''}
                name={'content'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="">
            {(selectedImageFile || selectedVideoFile) && <p>Preview:</p>}
            {selectedImageFile && (
              <div className="relative">
                <img className="h-56" src={selectedImageFile} alt="" />
                <button
                  className="absolute right-2 top-2 rounded-full bg-gray-100 p-2 text-black"
                  onClick={() => handleRemoveFile('image')}
                >
                  <ImCross />
                </button>
              </div>
            )}
            {selectedVideoFile && (
              <div className="relative">
                <CustomReactPlayer url={selectedVideoFile} />
                <button
                  className="absolute right-2 top-2 rounded-full bg-gray-100 p-2 text-black "
                  onClick={() => handleRemoveFile('video')}
                >
                  <ImCross />
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <UploadImageWithButton
                onChange={(value) => setSelectedImageFile(value)}
              />
              <UploadVideoWithButton
                onChange={(value) => setSelectedVideoFile(value)}
              />

              <label className="flex cursor-pointer items-center space-x-2">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  className="form-checkbox h-4 w-4 rounded text-blue-600 "
                />
                <span>Collect interest</span>
              </label>
            </div>

            {/*<Button*/}
            {/*    className="!bg-black px-4 py-2 !text-gray-200"*/}
            {/*    onClick={handlePost}*/}
            {/*    //isDisabled={!values?.content || values.content.trim() === ''}*/}
            {/*>*/}
            {/*  {isLoading ? (*/}
            {/*      <Loading variant="button" height="14px"></Loading>*/}
            {/*  ) : (*/}
            {/*      'Post'*/}
            {/*  )}*/}
            {/*</Button>*/}

            <Button
              className="!bg-black px-4 py-2 !text-gray-200"
              onClick={handlePost}
              disabled={
                !values?.content?.trim() &&
                !selectedImageFile &&
                !selectedVideoFile
              }
            >
              {isLoading ? (
                <Loading variant="button" height="14px"></Loading>
              ) : (
                'Post'
              )}
            </Button>
          </div>
        </Card>
      )}
      {/* <Button
        link={'/posts/all'}
        className="ml-auto w-32 !bg-black px-4 py-2 !text-gray-200"
      >
        View All Posts
      </Button> */}
      <PostViewCard
        handleShareOpen={handleShareOpen}
        handleInterestOpen={handleInterestOpen}
        handleDeletePost={handleDeletePost}
        data={data}
        singlePostData={singlePostData}
        isPostView={isPostView}
        userImage={profileData?.data?.bannerBg}
      />

      {isOpenModal && (
        <DialogModal onClose={onClose} title="Share Your Post" width="500">
          <div className="flex flex-wrap gap-4 py-8">
            {shareButtons.map((button) => (
              <button
                key={button.platform}
                onClick={() => handleShare(button.url)}
                className="rounded-full p-2 transition-transform hover:scale-110"
                style={{ backgroundColor: button.color }}
              >
                {React.cloneElement(button.icon, { color: 'white', size: 24 })}
              </button>
            ))}
          </div>
          <div className="flex justify-end pt-3">
            <button
              className="rounded bg-dark px-3 py-2 text-[14px] text-white"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </DialogModal>
      )}

      {isOpenInterestModal && (
        <DialogModal onClose={onClose} title="Add Your Interest" width="800">
          <div className="flex flex-col flex-wrap gap-4 py-8">
            <p className="text-sm">
              Add your contact info to send your interest to user about the
              post.
            </p>
            {!isInterestPostSuccess ? (
              <>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Name</p>
                  <div className="w-[90%] lg:w-[80%]">
                    <InputField
                      value={values?.name}
                      onChange={onChange}
                      style={{ width: '100%' }}
                      name={'name'}
                      className={'!w-full'}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Phone</p>
                  <div className="w-[90%] lg:w-[80%]">
                    <InputField
                      value={values?.phone}
                      onChange={onChange}
                      style={{ width: '100%' }}
                      name={'phone'}
                      className={'!w-full'}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Email</p>
                  <div className="w-[90%] lg:w-[80%]">
                    <InputField
                      value={values?.email}
                      onChange={onChange}
                      style={{ width: '100%' }}
                      name={'email'}
                      className={'!w-full'}
                    />
                  </div>
                </div>
                <div className="flex items-start justify-between">
                  <p className="font-semibold">Message</p>
                  <div className="w-[90%] lg:w-[80%]">
                    <TextAreaField
                      value={values?.message}
                      onChange={onChange}
                      style={{ width: '100%' }}
                      name={'message'}
                      className={'!w-full'}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-5 rounded-md bg-lime-200 p-5">
                <p>
                  Thank you for your interest. Sudaturk Trading & Consulting
                  Services will get back to you soon about your interest.
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-end gap-4 pt-3">
            {!isInterestPostSuccess ? (
              <button
                className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                onClick={handleSubmitInterest}
              >
                {isLoadingInterest ? (
                  <Loading variant="button" height="14px"></Loading>
                ) : (
                  'Send Your Message'
                )}
              </button>
            ) : null}
            <button
              className="rounded bg-dark px-3 py-2 text-[14px] text-white"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </DialogModal>
      )}
    </div>
  );
};

export default Posts;
