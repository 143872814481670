import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import features from '../../../constants/fetures';
import { useGetQrCategoryOptionsQuery } from '../../../redux/features/qrCategoryApi/qrCategoryApi';
import { useCreateQrCodeMutation } from '../../../redux/features/qrCodes/qrCodesApi';
import { useGetQrSubCategoryOptionsQuery } from '../../../redux/features/qrSubCategories/qrSubCategoryApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../../utils/helper';

const QrCodeForm = () => {
  const [values, setValues] = useState({ content: 'test' });
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [createCode, { isLoading }] = useCreateQrCodeMutation();
  const { data: qrCategoriesData } = useGetQrCategoryOptionsQuery();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();
  const { data: qrSubCategoriesData } = useGetQrSubCategoryOptionsQuery(
    { QRCategoryId: selectedCategory },
    { skip: !selectedCategory }
  );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
    if (key === 'QRCategoryId') {
      setSelectedCategory(value);
    }
  };

  const handleCreateWifi = async () => {
    if (
      !values?.name
      //   !values?.url ||
      // !values?.QRCategoryId ||
      // !values?.QRSubCategoryId
    )
      return toast.error('Name is required');

    try {
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.QRCODE, featuresData?.data)
      );
      if (featureUsageResponse?.error) {
        toast.error(featureUsageResponse?.error?.data?.message);
        setTimeout(() => {
          navigate('/subscription/history');
        }, 1000);
        return;
      }

      const result = await createCode(values);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/qr-codes');
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.log(error);
      toast.error('Failed to create Qr Code');
    }
  };

  return (
    <>
      <Card title="Crete QR Code">
        <div className="flex-col space-y-5">
          <InputGroup
            name={'name'}
            value={values?.name}
            onChange={onChange}
            placeholder="Name"
          />
          <InputGroup
            name={'url'}
            value={values?.url}
            onChange={onChange}
            placeholder="Url"
          />

          {/* <SelectOptions
            placeholder="QR Category"
            name={'QRCategoryId'}
            value={values?.QRCategoryId}
            onChange={onChange}
            options={qrCategoriesData?.data}
          />
          <SelectOptions
            placeholder="QR Sub Category"
            name={'QRSubCategoryId'}
            value={values?.QRSubCategoryId}
            onChange={onChange}
            options={qrSubCategoriesData?.data}
          /> */}
        </div>

        <Button className={'mt-5 w-32 bg-green-600'} onClick={handleCreateWifi}>
          {isLoading ? <Loading variant="button" height="14px" /> : 'Save'}
        </Button>
      </Card>
    </>
  );
};

export default QrCodeForm;
