import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';

import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from '../../../redux/features/products/productsApi';

const ProductsPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetProductsQuery(paginate);
  const [deleteProduct, { isLoading: delIsLoading }] =
    useDeleteProductMutation();

  const handleDeleteApi = async (id) => {
    try {
      const result = await deleteProduct(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const content = {
    header: [
      'Order Num.',
      'Image',
      'Category',
      'Title',
      'Description',
      'Price',
      'Promo Video Link',
      'Actions',
    ],

    body: data?.data?.results?.map((item, index) => ({
      rows: [
        {
          row: index + 1,
        },

        {
          row: (
            <div>
              <img
                className="h-[40px] w-[40px]"
                src={item?.coverImgUrl}
                alt=""
              />
            </div>
          ),
        },
        {
          row: item?.categories[0]?.title?.en,
        },
        {
          row: item?.title?.en,
        },
        {
          row: item?.description?.en,
        },
        {
          row: item?.price,
        },
        {
          row: item?.youtubeVideoUrl && (
            <Link
              target="_blank"
              to={item?.youtubeVideoUrl}
              className="hover:text-blue-400"
            >
              YouTube link
            </Link>
          ),
        },
        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                link={`/products/edit/${item?._id}`}
                className="!block !bg-green-400"
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                {delIsLoading ? (
                  <Loading variant="button" height="20px" />
                ) : (
                  <MdDelete />
                )}
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      <Card
        title="Products"
        content={
          <Button
            link="/products/create"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            <FaPlus /> Add New Product
          </Button>
        }
      >
        {isLoading ? (
          <Loading height="50vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default ProductsPage;
