import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputField from '../../../components/ui/InputField';
import {
  useCreateBusinessTypeMutation,
  useUpdateBusinessTypeMutation,
} from '../../../redux/features/businessTypes/businessTypes';

const CreateBusinessForm = ({ data }) => {
  const [values, setValues] = React.useState(data || {});
  const [createBusinessType, { isLoading: createBusinessTypeLoading }] =
    useCreateBusinessTypeMutation();
  const [updateBusinessType, { isLoading: updateBusinessTypeLoading }] =
    useUpdateBusinessTypeMutation();

  const navigate = useNavigate();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handelSubmit = async () => {
    if (values?._id) {
      try {
        let result = await updateBusinessType({
          id: values?._id,
          businessType: values?.businessType,
        });
        if (result?.data?.success) {
          toast.success(result?.data?.message);
          setValues({});
          navigate('/admin/business-types');
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      try {
        let result = await createBusinessType(values);
        if (result?.data?.success) {
          toast.success(result?.data?.message);
          setValues({});
          navigate('/admin/business-types');
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <Card>
      <InputField
        label="Business Type"
        placeholder="Enter Business Type"
        value={values?.businessType}
        name="businessType"
        onChange={onChange}
      />
      <Button onClick={handelSubmit} className="mt-5">
        {values?._id ? 'Update' : 'Create'}
      </Button>
    </Card>
  );
};

export default CreateBusinessForm;
