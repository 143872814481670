import React from "react";
import { useTranslation } from "react-i18next";

const QrStore = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between ml-20 px-12 py-6 mr-20 mt-6 bg-gray-100">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <img
                        src="/linkincard-store.png"
                        alt={t('qrStoreTitle2')}
                        className="w-full sm:w-[80%] h-[300px] sm:h-[400px] md:h-[500px] object-cover"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8">
                    <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">
                        {t('qrStoreTitle1')} <br />
                        <span className="font-black text-gray-900">
                            {t('qrStoreTitle2')}
                        </span>
                    </h2>
                    <p className="text-gray-600 leading-relaxed mb-4">
                        {t('qrStoreDescription')}
                    </p>
                    <ul className="list-disc ml-5 text-gray-600 mb-6">
                        <li>{t('qrStoreFeature1')}</li>
                        <li>{t('qrStoreFeature2')}</li>
                        <li>{t('qrStoreFeature3')}</li>
                        <li>{t('qrStoreFeature4')}</li>
                        <li>{t('qrStoreFeature5')}</li>
                    </ul>
                    <button className="bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-gray-700">
                        {t('qrStoreButton')}
                    </button>
                </div>
            </div>
        </>
    );
};

export default QrStore;
