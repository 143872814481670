import { BASE_URL } from '../constants/constants';

function formatCategories(dataArray) {
  return dataArray?.map((item) => ({
    label: item.name,
    value: item._id, // changed `id` to `value` for consistency
  }));
}

const formatDataForAnalyticsTable = (data = []) => {
  return data?.map((item = {}) => ({
    rows: [
      { row: item.ip }, // IP
      { row: item.city || 'Netrakona' }, // City (default or dynamic if available)
      { row: item.totalVisits.toString() }, // Total Visits
      { row: new Date(item.firstVisit).toLocaleString() }, // First Visit
      { row: new Date(item.lastVisits).toLocaleString() }, // Last Visit
    ],
  }));
};

const exportToCSV = (data, filename = 'data.csv') => {
  if (!data || data.length === 0) return;

  const csvRows = [];

  // Generate headers from the first object's keys, converting them to uppercase
  const headers = Object.keys(data[0]).map((key) => key.toUpperCase());
  csvRows.push(headers.join(','));

  // Generate CSV rows from data
  data.forEach((item) => {
    const row = headers.map((header) => {
      const key = header.toLowerCase(); // Match original key in lowercase
      const value = item[key];
      return typeof value === 'string' ? value : value?.props?.children || '';
    });
    csvRows.push(row.join(','));
  });

  // Convert array to CSV string
  const csvString = csvRows.join('\n');

  // Create a download link and trigger it
  const blob = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const stringifyData = (data) => {
  if (process.env.REACT_APP_APP_ENVIRONMENT === 'local') {
    return JSON.stringify(data, null, 2); // Pretty-print JSON in dev
  }
  return null; // Do not return or display anything in production
};

const extractCoordinates = async (url) => {
  try {
    const response = await fetch(
      `${BASE_URL}/get-coordinates?url=${encodeURIComponent(url)}`
    );
    const data = await response.json();

    if (data?.latitude && data?.longitude) {
      return {
        latitude: data?.latitude,
        longitude: data?.longitude,
      };
    } else {
      return data?.error || 'Coordinates not found';
    }
  } catch (err) {
    return err;
  }
};

function isValidGoogleMapsUrl(address) {
  if (typeof address !== 'string') {
    return false;
  }

  // Regular expression to match Google Maps URLs
  const googleMapsUrlPattern = /^https:\/\/maps\.app\.goo\.gl\/[a-zA-Z0-9]+$/;

  return googleMapsUrlPattern.test(address);
}

/**
 * Extracts the base URL from a given URL string.
 * @param {string} url - The full URL string.
 * @returns {string} - The base URL.
 */
function getBaseUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.host}`;
  } catch (error) {
    console.error('Invalid URL:', error.message);
    return null;
  }
}

// utils/getFeatureId.js

/**
 * Utility function to get the ID of a feature by its name.
 * @param {string} featureName - The name of the feature to find.
 * @param {Array} data - The array of feature objects.
 * @returns {string|null} - The ID of the feature if found, otherwise null.
 */
function getFeatureId(featureName = '', data = []) {
  const feature = data.find(
    (item) => item.name.toLowerCase() === featureName.toLowerCase()
  );
  return feature ? feature._id : null;
}

/**
 * Utility function to find the object with the latest createdAt time.
 * @param {Array} data - Array of objects with a 'createdAt' property.
 * @returns {Object} The object with the latest createdAt time.
 * @throws Will throw an error if input is invalid.
 */
function getLatestObject(data = []) {
  if (!Array.isArray(data)) {
    throw new Error('Input must be an array.');
  }
  if (data.length === 0) {
    console.warn('Input array is empty. Returning null.');
    return null; // Return null if array is empty
  }
  if (!data.every((item) => typeof item === 'object' && item.createdAt)) {
    throw new Error(
      "Every array element must be an object with a 'createdAt' property."
    );
  }

  return data.reduce((latest, current) => {
    return new Date(current.createdAt) > new Date(latest.createdAt)
      ? current
      : latest;
  });
}

export {
  exportToCSV,
  extractCoordinates,
  formatCategories,
  formatDataForAnalyticsTable,
  getBaseUrl,
  getFeatureId,
  getLatestObject,
  isValidGoogleMapsUrl,
  stringifyData,
};
