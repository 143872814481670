const businessTypes = [
  { label: 'Medical', value: 'medical' },
  { label: 'Defense', value: 'defense' },
  { label: 'Education', value: 'education' },
  { label: 'Vehicle', value: 'vehicle' },
  {
    label: 'Government Officials',
    value: 'governmentOfficials',
  },
  { label: 'Insurance', value: 'insurance' },
];

export default businessTypes;
