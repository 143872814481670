import { apiSlice } from '../api/apiSlice';

export const businessTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all bussinss types
    getBusinessTypes: builder.query({
      query: (arg) => ({
        url: `/users/get/bussinesstype`,
        method: 'GET',
        params: { ...arg },
      }),
      providesTags: ['businessTypes'],
    }),

    // create new business type
    createBusinessType: builder.mutation({
      query: (data) => ({
        url: '/users/create/bussinesstype',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['businessTypes'],
    }),

    // get single bussiness by its id
    getSingleBusinessType: builder.query({
      query: (id) => ({
        url: `/users/get/single/bussinesstype/${id}`,
        method: 'GET',
      }),
      providesTags: ['businessTypes'],
    }),

    // PUT update business type
    updateBusinessType: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/update/bussiness-type/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['businessTypes'],
    }),

    // delete spsific business type by id
    deleteBusinessType: builder.mutation({
      query: (id) => ({
        url: `/users/remove/businessType/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['businessTypes'],
    }),
  }),
});

export const {
  useGetBusinessTypesQuery,
  useCreateBusinessTypeMutation,
  useGetSingleBusinessTypeQuery,
  useUpdateBusinessTypeMutation,
  useDeleteBusinessTypeMutation,
} = businessTypesApi;

// how to use useDeleteBusinessTypeMutation
// const { mutate: deleteBusinessType } = useDeleteBusinessTypeMutation();
