import React from 'react';

const PreviewImg = ({ src = '', isBanner }) => {
  return (
    <>
      {src && (
        <img
          className={`${
            isBanner
              ? 'mb-2 h-[80px] w-[180px] rounded border object-cover'
              : 'mb-2 h-[80px] w-[80px] rounded-full border'
          } `}
          src={src}
          alt="preview"
        />
      )}
    </>
  );
};

export default PreviewImg;
