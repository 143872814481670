import React from 'react';
import { FaArrowLeft as ArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import { useGetPlansByFeatureIdQuery } from '../../redux/features/subscriptions/subscriptionsApi';
import { capitalizeFirstLetter } from '../../utils/dataFormater';

const PlansPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsFeatureName = searchParams.get('featureName');
  const featureId = useParams('featureId');
  const { data, isLoading } = useGetPlansByFeatureIdQuery(featureId?.featureId);

  const tableContent = {
    header: [
      'Plan Name',
      'Quantity',
      'Price',
      'Duration',
      // 'Active or Trail',
      'Action',
    ],
    body: data?.data?.map((plan) => ({
      rows: [
        { row: plan.planName },
        { row: plan.quantity },
        { row: plan.planPrice },
        { row: plan.duration },
        // { row: plan.isActive ? 'Active' : 'Trial' },
        {
          row: plan.isPurchased ? (
            <Button className="rounded-md bg-gray-500 px-4 py-2 text-white">
              Current Plan
            </Button>
          ) : (
            <Button
              className="rounded-md bg-blue-600 px-4 py-2 text-white"
              onClick={() =>
                navigate(`/plans/billing/`, { state: { plan: plan } })
              }
            >
              Upgrade Plan
            </Button>
          ),
        },
      ],
    })),
  };
  // how to get state from plans/billing page

  return (
    <div className="p-6">
      <div className="z-1 mb-10 flex items-center justify-start">
        <ArrowLeft
          className="h-6 w-6 cursor-pointer text-gray-600"
          onClick={() => navigate(-1)}
        />
      </div>
      <Card
        title={`Plans for ${capitalizeFirstLetter(searchParamsFeatureName)}`}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Table content={tableContent} pagination={null} />
        )}
      </Card>
    </div>
  );
};

export default PlansPage;
