import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import CustomizationPanel from './CustomizationPanel';
import SkillProgress from './SkillProgress';
import { FaRegEnvelope } from 'react-icons/fa6';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const CVTemplate3 = ({ data }) => {
  // get cv by id
  const location = useLocation();
  const componentRef = useRef();
  const [formData, setFormData] = useState(data);
  React.useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const [headingColor, setHeadingColor] = useState('text-blue-400');
  const [contentColor, setContentColor] = useState('text-black');
  const [fontFamily, setFontFamily] = useState('font-sans');
  const [textTransform, setTextTransform] = useState('normal-case');
  const [sideDesignBgColor, setSideDesignBgColor] = useState('#3b82f6');
  const [secondarySideDesignBgColor, setSecondarySideDesignBgColor] =
    useState('#1c2434');

  const timeDistribution = formData?.timeDistribution || {
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  };

  const personalSkills = formData?.personalSkills || {
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  };

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          formData?.working,
          formData?.family,
          formData?.coordination,
          formData?.entertainment,
          formData?.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const generatePdf = async () => {
    const element = componentRef.current;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    });

    const imgData = canvas.toDataURL('image/png');

    if (!imgData.startsWith('data:image/png')) {
      console.error('Invalid PNG image data');
      return;
    }

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const a4Width = 210;
    const a4Height = 297;
    const dpi = 96;
    const pxPerMm = dpi / 25.4;
    const a4WidthPx = a4Width * pxPerMm;
    const a4HeightPx = a4Height * pxPerMm;

    // Calculate scaling factor
    const scale =
      Math.min(a4WidthPx / canvas.width, a4HeightPx / canvas.height) * 1;
    const scaledCanvasWidth = canvas.width * scale;
    const scaledCanvasHeight = canvas.height * scale;

    // Number of pages needed
    const pageHeightPx = a4HeightPx / scale;
    const totalPages = Math.ceil(canvas.height / pageHeightPx);

    for (let page = 0; page < totalPages; page++) {
      const pageCanvas = document.createElement('canvas');
      pageCanvas.width = canvas.width;
      pageCanvas.height = Math.min(
        pageHeightPx,
        canvas.height - page * pageHeightPx
      );

      const pageContext = pageCanvas.getContext('2d');
      pageContext.drawImage(
        canvas,
        0,
        page * pageHeightPx,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        canvas.width,
        pageCanvas.height
      );

      const pageImgData = pageCanvas.toDataURL('image/png');

      // Check if pageImgData is valid for this page
      if (!pageImgData.startsWith('data:image/png')) {
        console.error(`Invalid PNG data for page ${page}`);
        continue;
      }

      if (page > 0) {
        pdf.addPage();
      }

      // Add the image to the PDF page
      pdf.addImage(
        pageImgData,
        'PNG',
        0,
        0,
        a4Width,
        (pageCanvas.height / pxPerMm) * scale
      );
    }

    pdf.save('download.pdf');
  };

  return (
    <div className="flex gap-10 py-20 pl-8">
      <div className="min-h-screen w-[69%] bg-gray-100" ref={componentRef}>
        <div className="mx-auto grid max-w-5xl grid-cols-3 overflow-hidden rounded-lg bg-white shadow-lg">
          {/* Left Section */}
          <div
            className={`col-span-1 p-6 text-white`}
            style={{ backgroundColor: `${sideDesignBgColor}` }}
          >
            <div className="text-center">
              <img
                src={formData?.imageUrl || '/dummyImg.jpg'}
                alt="Profile"
                className="mx-auto h-32 w-32 rounded-full border-2 border-white"
              />
              <h1
                className={`mt-4 text-2xl font-bold ${fontFamily} ${textTransform}`}
              >
                {formData?.name || 'Your Name'}
              </h1>
              <p className={`mt-1 text-sm ${fontFamily}`}>
                {formData?.jobTitle || 'Your Job Title'}
              </p>
              <p
                className={`mt-2 flex gap-x-2 text-sm ${fontFamily} justify-center`}
              >
                <FaRegEnvelope size={20} className="mt-1" />
                <p>{formData?.email || 'Your Email'}</p>
              </p>
            </div>
            {/* Personal Info */}
            <div className="mt-8">
              <h2 className="text-lg font-semibold">Personal Information</h2>
              <p className={`mt-2 ${fontFamily}`}>
                Address: {formData?.address}
              </p>
              <p className={`mt-2 ${fontFamily}`}>Phone: {formData?.phone}</p>
            </div>
          </div>

          {/* Right Section */}
          <div className="col-span-2 p-6">
            {/* Summary */}
            <div>
              <h2 className={`text-2xl font-bold ${headingColor}`}>Summary</h2>
              <p className={`mt-4 text-gray-700 ${contentColor} ${fontFamily}`}>
                {formData?.summary || 'Add a summary about yourself'}
              </p>
            </div>

            {/* Education */}
            {formData?.education?.length > 0 && (
              <div className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>
                  Educational Background
                </h2>
                {formData.education.map((edu) => (
                  <div
                    key={edu._id}
                    className={`mt-4 text-lg ${contentColor} ${fontFamily}`}
                  >
                    <p>
                      {edu.fieldOfStudy} - {edu.instituteName}
                    </p>
                    <p>
                      {new Date(edu.startDate).toLocaleDateString()} to{' '}
                      {new Date(edu.endDate).toLocaleDateString()}
                    </p>
                    <p>{edu.location}</p>
                    <p>{edu.description}</p>
                  </div>
                ))}
              </div>
            )}

            {/* Work Experience */}
            {formData?.experience?.length > 0 && (
              <div className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>
                  Work Experience
                </h2>
                {formData?.experience?.map((exp) => (
                  <div
                    key={exp._id}
                    className={`mt-4 text-lg ${contentColor} ${fontFamily}`}
                  >
                    <p>
                      {exp.position} at {exp.companyName}
                    </p>
                    <p>
                      {new Date(exp.startDate).toLocaleDateString()} to{' '}
                      {new Date(exp.endDate).toLocaleDateString()}
                    </p>
                    <p>{exp.location}</p>
                    <p>{exp.description}</p>
                  </div>
                ))}
              </div>
            )}

            {/* Certifications */}
            {formData?.certifications?.length > 0 && (
              <section className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>
                  Certifications
                </h2>
                {formData.certifications.map((cert) => (
                  <div
                    key={cert._id}
                    className={`text-lg ${contentColor} ${fontFamily} mt-4`}
                  >
                    <h3>{cert.courseName}</h3>
                    <p className="italic">{cert.authority}</p>
                    <p>{new Date(cert.courseDate).toLocaleDateString()}</p>
                    {cert.description && <p>{cert.description}</p>}
                  </div>
                ))}
              </section>
            )}

            {/* Skills */}
            {formData?.skills?.length > 0 && (
              <div className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>Skills</h2>
                <ul className={contentColor}>
                  {formData.skills.map((skill) => (
                    <li key={skill._id} className={`mt-2 ${fontFamily}`}>
                      {skill.skills}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Languages */}
            {formData?.languages?.length > 0 && (
              <div className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>
                  Languages
                </h2>
                <ul className={contentColor}>
                  {formData.languages.map((language) => (
                    <li key={language._id} className={`mt-2 ${fontFamily}`}>
                      {language.languages}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Hobbies */}
            {formData?.hobbies?.length > 0 && (
              <div className="mt-8">
                <h2 className={`text-2xl font-bold ${headingColor}`}>
                  Hobbies
                </h2>
                <ul className={contentColor}>
                  {formData.hobbies.map((hobby) => (
                    <li key={hobby._id} className={`mt-2 ${fontFamily}`}>
                      {hobby.hobbies}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Personal Skills */}
            <div className="mt-8">
              <h2 className={`text-2xl font-bold ${headingColor}`}>
                Personal Skills
              </h2>
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.leadership}
                title={'Leadership'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.communication}
                title={'Communication'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.selfMotivation}
                title={'Self Motivation'}
              />
              <SkillProgress
                sideDesignBgColor={sideDesignBgColor}
                contentColor={contentColor}
                fontFamily={fontFamily}
                value={formData?.decisionMaking}
                title={'Decision Making'}
              />
            </div>

            {/* Time Distribution */}
            <div className="mt-8">
              <h2
                className={`border-b-2 border-green-900 text-2xl font-bold ${headingColor}`}
              >
                Time Distribution
              </h2>
              <div className="mt-4 h-72">
                <Doughnut data={chartData} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomizationPanel
        setSideDesignBgColor={setSideDesignBgColor}
        setSecondarySideDesignBgColor={setSecondarySideDesignBgColor}
        setHeadingColor={setHeadingColor}
        setContentColor={setContentColor}
        setFontFamily={setFontFamily}
        setTextTransform={setTextTransform}
        fontFamily={fontFamily}
        textTransform={textTransform}
        generatePdf={generatePdf}
      />
    </div>
  );
};

export default CVTemplate3;
