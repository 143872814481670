import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import {
  useDeleteBusinessTypeMutation,
  useGetBusinessTypesQuery,
} from '../../../redux/features/businessTypes/businessTypes';
const BusinessPage = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetBusinessTypesQuery();
  const [deleteBusinessType] = useDeleteBusinessTypeMutation();

  const handleDeleteCar = async (id) => {
    try {
      const result = await deleteBusinessType(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error('Failed to delete');
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this business type',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteCar(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const content = {
    header: ['Business Name', 'Actions'],

    body: data?.data?.map((item) => ({
      rows: [
        {
          row: item?.businessType,
        },
        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                link={`/admin/business-types/edit/${item?._id}`}
                className="!block !bg-green-400 "
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <Card
      title="Business Types"
      content={
        <button
          className="flex items-center gap-2 rounded bg-[#009ef7] px-4 py-2 text-white"
          onClick={() => navigate('/admin/business-types/create')}
        >
          <FaPlus /> Create Business
        </button>
      }
    >
      {/* {JSON.stringify(data.data[0], null, 2)} */}
      <Table content={content} />
    </Card>
  );
};

export default BusinessPage;
