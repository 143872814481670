import React, { useState } from 'react';
import Table from '../../components/Table';
import Card from '../../components/ui/Card';
import {
  useGetPaidShopOrderListsQuery,
  useGetShopOrderListsQuery,
} from '../../redux/features/products/productsApi';
import Loading from '../Loading';
import { FaEye } from 'react-icons/fa6';
import Button from '../ui/Button';
import { useGetMyProfileQuery } from '../../redux/features/auth/authApi';

const OrderPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetPaidShopOrderListsQuery({ ...paginate });
  const { data: myProfile } = useGetMyProfileQuery();
  const content = {
    header: [
      'User Email',
      'User Phone',
      'User Address',
      'Tracking ID',
      'Order Date',
      // 'Actions',
    ],

    body: data?.data?.payments?.map((item) => ({
      rows: [
        { row: myProfile?.data?.email },
        {
          row: `${myProfile?.data?.phone?.dialCode} ${myProfile?.data?.phone?.fullNumber}`,
        },
        { row: myProfile?.data?.location?.city },
        { row: item?.invoice },
        { row: new Date(item?.createdAt).toLocaleDateString() },
        // {
        //   row: (
        //     <Button
        //       link={`/order/${item?.invoice}`}
        //       className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 "
        //     >
        //       <FaEye />
        //     </Button>
        //   ),
        // },
      ],
    })),
  };
  return (
    <div className="space-y-6">
      <Card title="Orders List">
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <Table
            content={content}
            pagination={{
              currentPage: data?.data?.currentPage,
              totalPage: data?.data?.totalPages,
              totalCount: data?.data?.totalOrders,
            }}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default OrderPage;
