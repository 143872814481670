import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CustomizationPanel from './CustomizationPanel';
import { FaRegFileLines } from 'react-icons/fa6';
import { MdMapsHomeWork } from 'react-icons/md';
import { FaGraduationCap } from 'react-icons/fa6';
import { TbListDetails } from 'react-icons/tb';
import { VscGraph } from 'react-icons/vsc';
import { TbCertificate } from 'react-icons/tb';
import { SiCoursera } from 'react-icons/si';
import { IoLanguageSharp } from 'react-icons/io5';
import { IoBicycleSharp } from 'react-icons/io5';

const CVTemplate = () => {
  const location = useLocation();
  const formData = location.state?.formData || {};

  const [headingColor, setHeadingColor] = useState('text-blue-400');
  const [contentColor, setContentColor] = useState('text-black');
  const [fontFamily, setFontFamily] = useState('font-sans');
  const [textTransform, setTextTransform] = useState('normal-case');

  return (
    <div className="flex gap-10 py-20 pl-10">
      <div class="min-h-screen bg-gray-100 py-10">
        <div class="mx-auto grid max-w-5xl grid-cols-3 overflow-hidden rounded-lg bg-white shadow-lg">
          <div class="col-span-1 bg-green-900 p-6 text-white">
            <div class="text-center">
              <img
                src="/profile-image-placeholder.png"
                alt="Profile"
                class="mx-auto h-32 w-32 rounded-full"
              />
              <h1 class="mt-4 text-2xl font-bold">Kashif Zaman</h1>
              <p class="mt-1 text-sm">kkkkkkk@gmail.com</p>
            </div>
            <div class="mt-8">
              <h2 class="text-lg font-semibold">Languages</h2>
              <div class="mt-4">
                <p class="flex justify-between">
                  English <span>100%</span>
                </p>
                <div class="h-2 w-full rounded bg-gray-300">
                  <div
                    class="h-2 rounded bg-yellow-500"
                    style={{ width: '100%' }}
                  ></div>
                </div>
              </div>
              <div class="mt-4">
                <p class="flex justify-between">
                  French <span>80%</span>
                </p>
                <div class="h-2 w-full rounded bg-gray-300">
                  <div
                    class="h-2 rounded bg-yellow-500"
                    style={{ width: '80%' }}
                  ></div>
                </div>
              </div>
              <div class="mt-4">
                <p class="flex justify-between">
                  Arabic <span>70%</span>
                </p>
                <div class="h-2 w-full rounded bg-gray-300">
                  <div
                    class="h-2 rounded bg-yellow-500"
                    style={{ width: '70%' }}
                  ></div>
                </div>
              </div>
            </div>
            <div class="mt-8">
              <h2 class="text-lg font-semibold">Hobbies</h2>
              <ul class="mt-4 space-y-2">
                <li class="flex items-center space-x-2">
                  <span class="material-icons">flight</span> <p>Travel</p>
                </li>
                <li class="flex items-center space-x-2">
                  <span class="material-icons">sports_basketball</span>{' '}
                  <p>Basketball</p>
                </li>
                <li class="flex items-center space-x-2">
                  <span class="material-icons">book</span> <p>Reading</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-span-2 p-6">
            <div>
              <h2 class="border-b-2 border-green-900 text-2xl font-bold">
                Summary
              </h2>
              <p class="mt-4 text-gray-700">
                Ex. Pivotal Sales Executive offering over 20 years of experience
                in implementing successful sales strategies and motivating
                highly-organized teams to consistently achieve top-line
                results...
              </p>
            </div>
            <div class="mt-8">
              <h2 class="border-b-2 border-green-900 text-2xl font-bold">
                Work Experience
              </h2>
              <div class="mt-4 space-y-6">
                <div>
                  <h3 class="text-lg font-semibold">Current Position</h3>
                  <p class="text-gray-700">
                    Company Name - Feb 2020 to Present <br />
                    Write your Accomplishments or Responsibilities...
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Previous Position</h3>
                  <p class="text-gray-700">
                    Company Name - Feb 2016 to Feb 2020 <br />
                    Write your Accomplishments or Responsibilities...
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-8">
              <h2 class="border-b-2 border-green-900 text-2xl font-bold">
                Personal Skills
              </h2>
              <div class="mt-4 space-y-4">
                <div>
                  <p class="flex justify-between">
                    Leadership <span>90%</span>
                  </p>
                  <div class="h-2 w-full rounded bg-gray-300">
                    <div
                      class="h-2 rounded bg-green-500"
                      style={{ width: '90%' }}
                    ></div>
                  </div>
                </div>
                <div>
                  <p class="flex justify-between">
                    Communication <span>70%</span>
                  </p>
                  <div class="h-2 w-full rounded bg-gray-300">
                    <div
                      class="h-2 rounded bg-green-500"
                      style={{ width: '70%' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomizationPanel
        setHeadingColor={setHeadingColor}
        setContentColor={setContentColor}
        setFontFamily={setFontFamily}
        setTextTransform={setTextTransform}
        fontFamily={fontFamily}
        textTransform={textTransform}
      />
    </div>
  );
};

export default CVTemplate;
